import { RESET_STATE, STORE_LOGIN } from "../constants/action-types";
import { SHOW_LOADER, SHOW_TAB } from "../constants/constants";

export function storeLogin(payload: any) {
  return {
    type: STORE_LOGIN,
    payload,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function setLoader(isLoading: boolean) {
  return {
    type: SHOW_LOADER,
    isLoading,
  };
}

export function setSelectedTab(tab: string) {
  return {
    type: SHOW_TAB,
    tab,
  };
}