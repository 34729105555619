import {
  ActionButton,
  Flex,
  Item,
  Key,
  Menu,
  MenuTrigger,
  Text,
  View,
} from "@adobe/react-spectrum";
import "./Header.css";
import AdobeLogo from "../../assets/adobeLogo.svg";
import QueueSelection from "../Dashboard/QueueSelection";
import AgentStatusChange from "../Dashboard/AgentStatusChange";
import {
  API_CHANGE_AGENT_STATE,
  APP_VERSION,
  HELPSUPPORT,
  SETTINGS,
} from "../../constants/constants";
import { SETTING_OPTIONS } from "../Dashboard/constants";
import { useSelector } from "react-redux";
import storeRTK from "../../store/storeRTK";
import { setLoader } from "../../actions/common";
import { sendMessage } from "../../services";
import { setCurrentSessionTab } from "../../actions/session";
import { session } from "../../handlers/session";

const HeaderComponent = () => {
  const { agent, call, callback, chat } = useSelector((state: any) => state);

  const menuOptions =
    agent?.aicConfig?.wfm_scheduling_url_enable === "true"
      ? SETTING_OPTIONS
      : SETTING_OPTIONS.filter((item) => item.id !== 3);

  const onTabChange = (id: any) => {
    // sendMessage({
    //   type: "notif",
    //   body: {
    //     id,
    //   },
    //   chan: API_TAB_CHANGE,
    // });
    //todo - enable call and chat
    storeRTK.dispatch(setCurrentSessionTab({ id: id }));
    // storeRTK.dispatch(setCurrentSessionTab({ id: "case" }));
  };

  const handleLogout = (key: any) => {
    if (key == "1") {
      storeRTK.dispatch(setLoader(true));
      const offlineState = agent?.states?.allowedStates?.find(
        (s: any) => s?.displayName === "Offline"
      );
      if (offlineState && offlineState.id) {
        sendMessage({
          body: { agentState: offlineState.id },
          chan: API_CHANGE_AGENT_STATE,
          type: "req",
        });
      }
      session.logout();
      storeRTK.dispatch(setLoader(false));
    }
    if (key == "2") {
      onTabChange(SETTINGS);
    }
    if (key == "3") {
      const wfmUrl = agent?.aicConfig?.wfm_scheduling_url;
      if (wfmUrl) {
        openWfmWindow(wfmUrl);
      }
    }
    if (key == "4") {
      onTabChange(HELPSUPPORT);
    }
  };

  const openWfmWindow = (url: any) => {
    // Define the features for the new window
    const features =
      "fullscreen=1,toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=no";
    // Open a new window with the specified features
    const newWindow = window.open(url, "_blank", features);
    // Add an event listener to prevent user input in the address bar
    // newWindow.addEventListener('load', function() {
    //     newWindow.document.addEventListener('DOMContentLoaded', function() {
    //         var addressBar = newWindow.document.querySelector('input[type="url"]');
    //         if (addressBar) {
    //             addressBar.disabled = true;
    //         }
    //     });
    // });
  };

  return (
    <Flex
      UNSAFE_className="headerWrapper"
      justifyContent={"space-between"}
      alignItems={"center"}
      data-testid="headerMain"
    >
      <Flex alignItems="center">
        <img src={AdobeLogo} className="adobeLogo" alt="Adobe Logo" />
        <Text UNSAFE_className="aic-logo">AIC</Text>
      </Flex>
      {agent.firstName && (
        <Flex>
          <Flex UNSAFE_className="versionInfo" alignItems={"center"}>
            <Flex>
              <QueueSelection />
            </Flex>
            <Flex UNSAFE_className="agentStatusChangePicker">
              <AgentStatusChange />
            </Flex>
            <Flex alignItems={"center"}>
              <View
                marginEnd={6}
                marginBottom={4}
                UNSAFE_style={{ fontSize: "0.75rem" }}
              >
                <Text>{APP_VERSION ? `v${APP_VERSION}` : ""}</Text>
              </View>
              <View UNSAFE_className={"settingsButtonWrapper"}>
                <MenuTrigger>
                  <ActionButton UNSAFE_className={"settingsButton"}>
                    {agent.firstName
                      ? (
                          agent.firstName[0] +
                          (agent.lastName ? agent.lastName[0] : "")
                        ).toUpperCase()
                      : "NA"}
                  </ActionButton>
                  <Menu
                    onAction={(key: Key) => handleLogout(key)}
                    disabledKeys={
                      (call.id && call.self?.id) ||
                      (callback.id && callback.self?.id) ||
                      chat.chats.length
                    }
                  >
                    {/**right now we have only logout menu item here */}
                    {menuOptions.map((item: any) => (
                      <Item key={item.id}>
                        <Text UNSAFE_style={{ fontSize: "0.75rem" }}>
                          {item.name}
                        </Text>
                      </Item>
                    ))}
                  </Menu>
                </MenuTrigger>
              </View>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderComponent;
