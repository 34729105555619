import { Flex, View, Text, Heading, Avatar } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import "./style.css";
import { API_CALL_DIAL } from "../../../constants/constants";
import Emitter from "../../../emitter";
import { formatTime, getCallIcon } from "../../../utils";

const RecentCalls = () => {
  const { history } = useSelector((state: RootState) => state);
  const { conversations } = history || {};

  const dialCall = (phoneNumber: string) => {
    Emitter.emit(API_CALL_DIAL, { phoneNumber });
  };

  return (
    <View width={"40%"} paddingX={20} height={"100%"}>
      <Flex direction={"column"}>
        <View>
          <Heading level={2}>
            <Text UNSAFE_className="recent-call-heading">Recent Calls</Text>
          </Heading>
        </View>
        {conversations?.length > 0 &&
          conversations.slice(0, 5).map((callerList: any, index: number) => {
            const { connectedTime, endTime } = callerList?.self; // Ensure these are numbers
            const { formattedConnectedTime, formattedDifference } = formatTime(
              connectedTime,
              endTime
            );

            return (
              <View
                UNSAFE_style={
                  index !== 0 ? { borderTop: "1px solid #D5D5D5" } : {}
                }
                key={callerList.id} // Use a unique key for each item
              >
                <Flex UNSAFE_className="call-recent-list">
                  <Flex>
                    <View>
                      <Avatar
                        src="https://i.imgur.com/kJOwAdv.png"
                        alt="avatar with custom size"
                        size={25}
                      />
                    </View>
                  </Flex>
                  <Flex
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                  >
                    <View>
                      <Flex
                        direction="column"
                        alignItems="start"
                        UNSAFE_className="call-history-list"
                      >
                        <Text UNSAFE_className="call-history-title">
                          {callerList.customer.attributes.Customer_ANI ||
                            callerList.customer.displayName ||
                            callerList.customer?.phoneNumber ||
                            "-"}
                        </Text>
                        <Text
                          UNSAFE_style={{
                            textAlign: "start",
                            color: "#D7373F",
                          }}
                        >
                          <Flex
                            UNSAFE_className="call-history-subtitle"
                            alignItems={"center"}
                            justifyContent={"center"}
                            marginTop={4}
                          >
                            <img
                              src={getCallIcon(callerList)}
                              className="call-history-icon"
                              alt="Missed call icon"
                            />
                            <Text
                              marginX={4}
                              UNSAFE_style={{ whiteSpace: "nowrap" }}
                            >
                              {formattedConnectedTime}
                            </Text>
                          </Flex>
                        </Text>
                      </Flex>
                    </View>
                    <View>
                      <Flex direction="column">
                        <Text UNSAFE_className="recent-call-time-diff">
                          {formattedDifference}
                        </Text>
                      </Flex>
                    </View>
                    <Flex
                      direction="column"
                      alignSelf={"center"}
                      UNSAFE_className="recent-call-btn"
                    >
                      <button
                        onClick={() =>
                          dialCall(callerList.customer.displayName)
                        }
                      >
                        Call
                      </button>
                    </Flex>
                  </Flex>
                </Flex>
              </View>
            );
          })}
      </Flex>
    </View>
  );
};

export default RecentCalls;
