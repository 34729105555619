import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "../reducers";
import { storeLogin } from "../actions/common";
import { session } from "../controller/session";
import { BG_INITIATE_LOGIN, STATE_LOGIN } from "../constants/events";
import { initiateLogin } from "../actions/session";
import storeRTK from "./storeRTK";

// Define an action that you will dispatch to update the state with the loaded persisted state
const UPDATE_PERSISTED_STATE = "UPDATE_PERSISTED_STATE";

// Enhance rootReducer to handle updating the persisted state
const enhancedRootReducer = (state: RootState) => {
  // Update the state with the loaded persisted state
  storeRTK.dispatch(storeLogin(state));

  // If service worker restarts, start ccp start interval which also checks for vpn connectivity
  if (state.session.loggedIn) {
    console.log(
      "!!!!!!!!!!! SERVICE WORKER MIGHT HAVE RESTARTED !!!!!!!!!!!!!!"
    );
    session.onloggedIn({
      type: "notif",
      chan: STATE_LOGIN,
      body: state.agent || {},
      success: true,
    });
  } else if (state.session.isLoginProcessing) {
    store.dispatch(initiateLogin(false));
    // sendMessage({
    //     type: 'notif',
    //     chan: BG_INITIATE_LOGIN,
    //     body: false
    // });
  }
};

// Save state to LocalStorage
export const saveStateToLocalStorage = (state: any) => {
  try {
    // chrome.storage.local.set({ 'reduxState': state }).then(() => {
    //     //console.log("Value is set");
    // });
    localStorage.setItem("reduxState", JSON.stringify(state));

    //localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    console.error("Error saving state to LocalStorage:", error);
  }
};

// Load state from LocalStorage
export const loadStateFromLocalStorage = async () => {
  try {
    const data = localStorage.getItem("reduxState");
    if (data !== null) {
      const serializedState = await JSON.parse(data || "");
      console.log("Loaded store from localstorage", serializedState);
      //return serializedState.reduxState;
      return serializedState;
    }
  } catch (error) {
    console.error("Error loading state from LocalStorage:", error);
    return undefined;
  }
};

// Load persisted state from LocalStorage

loadStateFromLocalStorage().then((serializedState) => {
  if (serializedState !== undefined) {
    // Dispatch an action to update the store with the persisted state
    enhancedRootReducer(serializedState);
  }
});
// Configure Redux store with persisted state
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
});

// Subscribe to store changes and save state to LocalStorage
// store.subscribe(() => {
//     const state = store.getState();
//     saveStateToLocalStorage(state);
// });

// Export store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
