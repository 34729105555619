import { getAicServerUrl } from "../config";

/**
 * Returns a shallow clone of the object provided
 * @param {T} obj
 * @returns{T} Cloned Object
 */
export function shallowClone<T>(obj: T): T {
  return Object.assign({}, obj);
}

/**
 * Used to generate UUID's for Session Handling
 * @returns Randomly Generated UUID Session
 */
export function makeUUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (a, b) {
      return (
        (b = Math.random() * 16), (a == "y" ? (b & 3) | 8 : b | 0).toString(16)
      );
    }
  );
}

export async function checkVpnConn() {
  const CURRENT_DOMAIN = await getAicServerUrl();
  return new Promise((resolve, reject) => {
    fetch(`${CURRENT_DOMAIN}/v1/ready`)
      .then((response) => response.text())
      .then((status) => {
        resolve("ok");
      })
      .catch((err) => {
        reject("failure");
      });
  });
}
export function checkVpnConnNTimes(
  maxRetry: number,
  callback: (error: any, status: boolean) => void,
  err?: any
) {
  if (maxRetry <= 0) {
    maxRetry = 0;
    callback(err, false);
  } else {
    if (navigator.onLine) {
      checkVpnConn()
        .then((res) => callback(null, true))
        .catch((err) => {
          setTimeout(
            () => checkVpnConnNTimes(maxRetry - 1, callback, err),
            1000
          );
        });
    } else {
      setTimeout(() => checkVpnConnNTimes(maxRetry - 1, callback, err), 3000);
    }
  }
}

/**
 *
 * @param {Object} param0 Fetch options
 * @returns{Promise<Response>}
 */
export function customFetch({
  url,
  method,
  queryParams,
  username,
  password,
  successStatus,
  json,
  isJsonResponse,
}: any) {
  const headers: any = {};

  if (method == "GET") headers["Accept"] = "*/*";

  if (username && password)
    headers["Authorization"] = `Basic ${btoa(`${username}:${password}`)}`;

  if (json) {
    headers["Content-Type"] = "application/json";
  }

  if (queryParams) {
    const uSearchParams = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams[key]) uSearchParams.set(key, queryParams[key]);
    }
    url += `?${uSearchParams.toString()}`;
  }

  return fetch(url, {
    method,
    mode: "cors",
    credentials: "include",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers,
    body:
      method == "POST" || method == "PUT" || method == "PATCH"
        ? JSON.stringify(json, undefined, 2)
        : undefined,
  })
    .then((response) => {
      if ((successStatus && response.status != successStatus) || !response.ok) {
        throw response;
      }
      return response;
    })
    .then((response) => {
      if (
        response.headers.get("content-type") == "application/json" ||
        isJsonResponse
      )
        return response.json();
      return response;
    });
}

/**
 * Runs fetch with retrying subsequently on future failed requests.
 * @param {Promise} task
 */
export function fetchWithRetry(
  apiFunc: any,
  apiParams: any,
  maxRetries?: number
) {
  const retryOpts = {
    maxRetries: maxRetries || 2,
    timeouts: [1000, 1500, 3000],
  };

  return new Promise(function (resolve, reject) {
    let currentAttempt = 0;
    function runApi() {
      currentAttempt++;
      apiFunc(apiParams)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          if (currentAttempt < retryOpts.maxRetries) {
            setTimeout(runApi, retryOpts.timeouts[currentAttempt - 1]);
          } else {
            reject(err);
          }
        });
    }

    runApi();
  });
}

export function formatPhoneNumber(phoneNumber: string) {
  // Check if phoneNumber is null, undefined, or not a string
  // if (typeof phoneNumber !== 'string') {
  //     return null;
  // }

  // Trim any leading and trailing whitespace
  phoneNumber = phoneNumber.trim();

  // Check if phoneNumber starts with '+'
  if (phoneNumber.startsWith("+")) {
    return phoneNumber;
  }

  // Add '+' prefix if it's not present
  return "+" + phoneNumber;
}

// Function to merge two arrays with unique elements based on a key
type AnyObject = { [key: string]: any };

// Function to merge two arrays with unique elements based on a specific key
export function mergeUnique<T extends AnyObject>(
  arr1: T[],
  arr2: T[],
  key: keyof T
): T[] {
  const unique = new Map(arr1.map((item) => [item[key], item]));

  arr2.forEach((item) => {
    if (!unique.has(item[key])) {
      unique.set(item[key], item);
    }
  });

  return Array.from(unique.values());
}

export const checkInternet = async () => {
  const CURRENT_DOMAIN = await getAicServerUrl();
  return fetch(`${CURRENT_DOMAIN}/v1/ready`)
    .then((response) => {
      if (response.ok) {
        return true;
      } else {
        throw new Error("Server response was not ok.");
      }
    })
    .catch(() => false);
};

// Rename the keys
export function renameKeys(
  obj: any,
  replaceThis: string,
  replacewith: string
): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => renameKeys(item, replaceThis, replacewith));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key === replaceThis ? replacewith : key;
      acc[newKey] = renameKeys(obj[key], replaceThis, replacewith);
      return acc;
    }, {} as any); // Add type annotation here
  } else {
    return obj;
  }
}

export const formatTimeForCall = (callTime: number) => {
  const hours = Math.floor(callTime / 3600000)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((callTime % 3600000) / 60000)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((callTime % 60000) / 1000)
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};
