import React from 'react';
import { APP_VERSION } from '../../constants/constants';
import './Footer.css';

/**
 * Compnent Footer which will be loaded onlt on eahc page.
 */
export default function FooterComponent() {
    const year = new Date().getFullYear();
    return (
        <footer>
            <div className={'footerWrapper'}>
            <span>
                {APP_VERSION ? `v${APP_VERSION}` : ''}
            </span>
            <span data-testid="footerMain">
                Copyright © {year} Adobe Systems
            </span>
            </div>
        </footer>
    );
}
