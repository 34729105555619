import {
    BUSINESS_ATTRIBUTE_SET,
    FEATURE_SET,
    FEATURE_RESET,
    LOGOUT,
} from '../constants/action-types';

const initialState = {
    conference: false,
    businessAttributes: { sections: [], defaultVisibleSections: [] },
    agent_to_agent: false,
    allowOnlyTransferReadyUsers: false,
};

/**
 * set Features
 * @param {*} state
 * @param {*} action
 */
export default ((state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_ATTRIBUTE_SET:
            return {
                ...state,
                businessAttributes: {
                    sections: action.sections,
                    defaultVisibleSections: action.defaultVisibleSections,
                },
            };
        case FEATURE_SET:
            return {
                ...state,
                agent_to_agent: action.agent_to_agent || state.agent_to_agent,
                allowOnlyTransferReadyUsers:
                    action.allowOnlyTransferReadyUsers ||
                    state.allowOnlyTransferReadyUsers,
                conference: action.conference || state.conference,
            };
        case FEATURE_RESET:
        case LOGOUT:
            return {
                ...initialState,
            };
        default: return state;
    }
});
