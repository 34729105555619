import { connectSession } from "../handlers/session";
import { updateCallAndSend } from "../handlers/call";
import { changeAgentStateToAvailable } from "../handlers/agent";
import {
  API_CALL_ANSWER,
  API_CALL_DIAL,
  API_CALL_HOLD,
  API_CALL_MUTE,
  API_CALL_PATCH_ATTRIBUTE,
  API_CALL_PLAY,
  API_CALL_REJECT,
  API_CALL_SEND_DTMF,
  CONTACT_EVENT_STATE_MAP,
  LOG_PREFIX,
} from "../../constants/constants";
import { sendErrorNotification } from "../../utils";
import Emitter from "../../emitter";
import { API_CALL_HANGUP, API_CALL_TRANSFER } from "../constants";
const lp = LOG_PREFIX + "[api.call.ts]: ";

function getAgentContactById(isCall: boolean, id?: any) {
  let contacts;
  if (!!isCall) {
    contacts = connectSession.agent.getContacts(connect.ContactType.VOICE);
    if (contacts.length) return contacts[0];
  } else {
    contacts = connectSession.agent.getContacts();
    if (contacts.length) {
      const contact = contacts.find((ct: any) => ct.contactId === id);
      return contact;
    }
  }
  return null;
}

export function acceptCallContact(ev: any) {
  const logPrefix =
    lp + "acceptCallContact contactId: " + ev.body.conversationId;
  try {
    getAgentContactById(true).accept({
      success: function () {
        console.log(logPrefix + "Accepted via Streams with contact id: ");
      },
      failure: function (error: any) {
        const errorMessage = JSON.parse(error);
        if (errorMessage && errorMessage?.message) {
          console.log(
            logPrefix + "Failed to establish connection via Streams",
            `${errorMessage?.message}`
          );
          sendErrorNotification(
            "Failed to establish connection via Streams",
            `${errorMessage?.message}`
          );
        }
        console.log(
          logPrefix + "Failed to establish connection via Streams with error",
          error
        );

        updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
          contactId: ev.body.conversationId,
        });
      },
    });
  } catch (e) {
    console.log(logPrefix + "Error in accepting call with error", e);
    updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
      contactId: ev.body.conversationId,
    });
  }
}

export function disconnectCallContactOnRefreshError(ev: any) {
  try {
    // Streams API call to Drop a Connected Contact
    const callContact = getAgentContactById(true);
    if (callContact) {
      getAgentContactById(true)
        .getAgentConnection()
        .destroy({
          success: function () {
            console.log("Contact disconnected via Streams");
            clearCurrentCallContact();
          },
          failure: function (err: any) {
            clearCurrentCallContact();
            console.log("Failed to disconnect the contact via Streams", err);
            updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
              contactId: ev.body.conversationId,
            });
          },
        });
    } else {
      clearCurrentCallContact();
      updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
        contactId: ev.body.conversationId,
      });
    }
  } catch (error) {
    console.log("Error in disconnect call contact on CCP, removing from AIC");
    updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
      contactId: ev.body.conversationId,
    });
  }
}

export function disconnectCallContact(ev: any) {
  const logPrefix =
    lp + "disconnectCallContact contactId: " + ev.body.conversationId;
  try {
    // Streams API call to Drop a Connected Contact
    const callContact = getAgentContactById(true);
    if (callContact) {
      getAgentContactById(true)
        .getAgentConnection()
        .destroy({
          success: function () {
            console.log(logPrefix + "Contact disconnected via Streams");
          },
          failure: function (err: any) {
            clearCurrentCallContact();
            console.log(
              logPrefix + "Failed to disconnect the contact via Streams",
              err
            );
            updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
              contactId: ev.body.conversationId,
            });
          },
        });
    } else {
      console.log(logPrefix + "No call contact found with contactId");
      clearCurrentCallContact();
      updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
        contactId: ev.body.conversationId,
      });
    }
  } catch (error) {
    console.log(
      `${logPrefix} Error in disconnect call contact on CCP, removing from AIC`,
      error
    );
    updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
      contactId: ev.body.conversationId,
    });
  }
}

export function updateCall(ev: any) {
  const logPrefix = lp + "updateCall contactId: " + ev.body.conversationId || ev.body.contactId;
  const callConnection = getAgentContactById(true).getActiveInitialConnection();
  if (ev.body?.held === true) {
    callConnection.hold({
      success: function () {
        console.log(logPrefix + "Successfully hold call");
      },
      failure: function (error: any) {
        console.log(logPrefix + "Error during hold call", error);
      },
    });
  } else if (ev.body?.held === false) {
    callConnection.resume({
      success: function () {
        console.log("Successfully resumed call");
      },
      failure: function (error: any) {
        console.log(logPrefix + "Error during resume call", error);
      },
    });
  }
}

export function rejectCallContact(ev: any) {
  const logPrefix =
    lp + "rejectCallContact contactId: " + ev.body.conversationId;
  changeAgentStateToAvailable();
  getAgentContactById(true).reject({
    success: function () {
      console.log(logPrefix + "Rejected Incoming call via Streams");
      clearCurrentCallContact();
    },
    failure: function (err: any) {
      console.log(logPrefix + "Failed to Reject call via Streams", err);
      clearCurrentCallContact();
      updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
        contactId: ev?.body?.conversationId,
      });
    },
  });
}
export function clearCurrentCallContact() {
  const contact = getAgentContactById(true);
  if (contact) {
    try {
      contact.clear();
    } catch (e) {}
  }
}

export function initOutBoundCall(ev: any) {
  const logPrefix = lp + "initOutBoundCall " + ev.phoneNumber;
  var endpoint = connect.Endpoint.byPhoneNumber(ev.phoneNumber);
  connectSession.agent.connect(endpoint, {
    success: function (res: any) {
      console.log(
        logPrefix + "Outbound call successfull to the endpoint",
        endpoint,
        res
      );
    },
    failure: function (error: any) {
      const errorMessage = JSON.parse(error);
      if (errorMessage && errorMessage?.message) {
        console.log(
          logPrefix + "Failed to connect outbound call",
          `${errorMessage?.message}`
        );
        sendErrorNotification(
          logPrefix + "Failed to connect outbound call",
          `${errorMessage?.message}`
        );
      }
      console.log(logPrefix + "Failed to connect outbound call: ", error);
    },
  });
}

export function muteUnmute(ev: any) {
  if (ev.body?.mute === true) {
    connectSession.agent.mute();
  } else if (ev.body?.mute === false) {
    connectSession.agent.unmute();
  }
}

export function transferCallToQuickConnects(ev: any) {
  if (!ev?.body?.transferEndpoint) return;

  let transferEndpoint = {
    agentLogin: null,
    endpointId: ev.body.transferEndpoint.arn,
    endpointARN: ev.body.transferEndpoint.arn,
    phoneNumber: null,
    name: ev.body.transferEndpoint.name,
    queue: null,
    type: ev.body.transferEndpoint.quick_connect_type.toLowerCase(),
  } as unknown as connect.EndpointType.QUEUE;
  let callConnection = getAgentContactById(true);
  const logPrefix =
    lp + "transferCallToQuickConnects contactId: " + ev.body.conversationId;
  callConnection.addConnection(transferEndpoint, {
    success: function (data: any) {
      console.log(logPrefix + "call transfer success", data);
      callConnection.getAgentConnection().destroy({
        success: function () {
          console.log(
            logPrefix + "Agent disconnected via Streams after transfer"
          );
        },
        failure: function (err: any) {
          console.log(
            logPrefix +
              "Failed to disconnect the first contact via Streams after transfer",
            err
          );
        },
      });
    },
    failure: function (error: any) {
      console.log(logPrefix + "call transfer failed", error);
    },
  });
}

export function senfDTMF(event: any) {
  const logPrefix = lp + "senfDTMF contactId: " + event.body.conversationId;
  let callConnection = getAgentContactById(true).getAgentConnection();
  callConnection.sendDigits(event.body?.digits?.toString(), {
    success: function (resp: any) {
      console.log(logPrefix + "DTMF sent successfully", resp);
    },
    failure: function (error: any) {
      console.log(logPrefix + "Error in sending DTMF", error);
    },
  });
}

export const registerApiCallEvents = () => {
  Emitter.on(API_CALL_HANGUP, disconnectCallContact);
  Emitter.on(API_CALL_DIAL, initOutBoundCall);
  Emitter.on(API_CALL_MUTE, muteUnmute);
  Emitter.on(API_CALL_HOLD, updateCall);
  Emitter.on(API_CALL_PLAY, updateCall);
  Emitter.on(API_CALL_ANSWER, acceptCallContact);
  Emitter.on(API_CALL_REJECT, rejectCallContact);
  Emitter.on(API_CALL_SEND_DTMF, senfDTMF);
  Emitter.on(API_CALL_TRANSFER, transferCallToQuickConnects);
};
