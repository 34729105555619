/* global chrome */
import Emitter from '../emitter';
import { CONTENT_SCRIPT_NAME } from '../constants/contentScript';
import {
    BG_TABS_ADD,
    SESSION_STATE,
    BG_PERF_LOADUI,
    CCP_PING,
    BG_MEDIA_PERM_CHECK,
} from '../constants/constants';

let backgroundPort = null,
    maxRetries = process.env.REACT_APP_MAX_BG_RETRIES;

let pingInterval = null;

/**
 * Emits a message sent by the background
 * page to the respective type.
 * @param {Object} message Message sent by the background page. of the format:
 * {
 *      chan,
 *      success,
 *      type,
 *      body
 * }
 */

// function _emittingListener(message) {
//     if (maxRetries < process.env.REACT_APP_MAX_BG_RETRIES) {
//         maxRetries = process.env.REACT_APP_MAX_BG_RETRIES;
//     }
//     Emitter.emit(message.chan, message);
// }

/**
 * This function is used to send a message using an event emitter.
 * It emits the message on the channel specified in the message object.
 * After emitting the message, if a callback function is provided, it is called.
 *
 * @param {any} message - The message to be sent. It should be an object containing at least a 'channel' property.
 * @param {() => void} [cb] - An optional callback function that will be called after the message is emitted.
 */
export function sendMessage(message: any, cb?: () => void) {
    console.log("HELLO FROM sendMessage", message);
    
    // Emit the message on the specified channel
    Emitter.emit(message.channel, message);
  
    // If a callback function was provided, call it
    if (cb) {
      cb();
    }
}

export function sendMessageTest(event: string, message: any, responseEvent: string) {
    // Emit the message
    Emitter.emit(event, message);
  
    // Listen for a response
    Emitter.on(responseEvent, (response: any) => {
      console.log(`Received response to ${event}:`, response);
    });
  }
  
  // Function to handle a message
  export function handleMessageTest(event: string, handler: (message: any) => any) {
    Emitter.on(event, (message: any) => {
      // Handle the message
      const response = handler(message);
  
      // Emit a response event
      Emitter.emit(`${event}_RESPONSE`, response);
    });
  }

/**
 *
 * @param {key, value pair} object
 */
// export function setInChromeStore(object) {
//     chrome.storage.local.set(object, () => {});
// }
// export function getFromChromeStore(key, callback) {
//     chrome.storage.local.get([key], (result) => {
//         callback(result[key]);
//     });
// }

/**
 * Send page load time to background
 */
function sendPerfData() {
    setTimeout(() => {
        sendMessage({
            type: 'req',
            chan: BG_PERF_LOADUI,
            success: true,
            body: {
                timing: {
                    duration: (window.performance.getEntries()[0] || {})
                        .duration,
                    startTime: window.performance.timing.requestStart,
                    endTime: window.performance.timing.loadEventEnd,
                },
            },
        });
    }, 100);
}

const retryConnect = (cb) => {
        setTimeout(() => cb(true), 100);
}

/**
 * Connects to the background page and
 * initiates the emitter events
 */
export const connectBackground = (reconnect) => {
    window.addEventListener('load', sendPerfData, false);
    if (backgroundPort == null) {
        try {
            console.log("Calling background connect is it reconnect = " + reconnect);
            backgroundPort = chrome.runtime.connect({
                name: CONTENT_SCRIPT_NAME,
            });
        } catch (error) {
            console.log("Error in  background connect");
            retryConnect(connectBackground);
            return;
        }
        backgroundPort.onDisconnect.addListener(() => {
            console.log("This port is disconnected from BG, retrying to connect");
            backgroundPort = null;
            retryConnect(connectBackground);
        });
        backgroundPort.onMessage.addListener(_emittingListener);

        backgroundPort.postMessage({
            type: 'req',
            chan: BG_TABS_ADD,
        });
        if(!reconnect) {
            backgroundPort.postMessage({
                type: 'req',
                chan: SESSION_STATE,
            });
        } else {
            // setTimeout(() =>
            //     backgroundPort.postMessage({
            //     type: 'req',
            //     chan: SESSION_STATE,
            // }), 100);
        }
        if(pingInterval) {
            clearInterval(pingInterval);
        }

        pingInterval = setInterval(() => {
            if(backgroundPort) {
                backgroundPort.postMessage({
                    type: 'req',
                    chan: CCP_PING,
                });
            }
        }, 500);
        backgroundPort.postMessage({
            type: 'req',
            chan: BG_MEDIA_PERM_CHECK
        })
    }
}

// window.sendMessage = sendMessage;