import React, { useEffect } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Toast } from '../common/Toast';
import { sendMessage } from '../../services';
import { CCP_STATE, CCP_STATES, RETRY_CCP_CONNECTION } from '../../constants/constants';
// import Emitter from '../../emitter';

/**
 * Map Store State To component Props
 * @param {*} state
 */

function mapStateToProps(state:any) {
    return {
        session: state.session,
    };
}


const CCPConnectionErrorPopup = ({message}:any) => {
    const [showLoader, setShowLoader] = React.useState(false);
    const sendRetryRequest = () => {
        console.log("Retry request sent %%%%%%%%%%%%%%%%%%%%%");
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 30000);

        sendMessage({
            type:'req',
            chan: RETRY_CCP_CONNECTION,
            body: {}
        })
    }
    // useEffect(() => {
    //     Emitter.on(CCP_STATE, (ev) => {
    //        if(ev.body.status === CCP_STATES.CONNECTED) {
    //             setShowLoader(false);
    //        }
    //     });
    //     return () => {
    //         Emitter.on(CCP_STATE, (ev) => {
    //             if(ev.body.status === CCP_STATES.CONNECTED) {
    //                  setShowLoader(false);
    //             }
    //          });
    //     }
    // }, [])
    return (
        <>
            {message && (
                <div
                    area-role="mediaAvailableMessage"
                    className="mediaAvailableMessage"
                >
                    <Toast message={message} closable={true} action={true}
                        actionButtonLabel = 'retry'
                        actionCallback ={sendRetryRequest}
                        showProgressCircle={showLoader}
                        ></Toast>
                </div>
            )}
        </>
    );
};
export default connect(mapStateToProps)(CCPConnectionErrorPopup);
