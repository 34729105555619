import {
  getMiloDeeplink,
  getTransferEndpoints,
  updateContactReleaseToAIL,
  getContactEventLoggingAPIHandler,
} from "../api/session-api";
import { sendMessage } from "../chrome/tabs";
import {
  API_CALL_ANSWER,
  API_MILO_CHANNEL_TRANSACTION,
  INTERNAL_CALL_RELEASE,
  STATE_CALL,
  API_CALL_TRANSFER_ENDPOINTS,
  API_CONTACT_EVENT_LOGGING,
} from "../constants/events";
// import { emitter, SessionEvent } from '../emitter';

import // Call,
//MiloTransaction,
//resetNormalCall,
// updateNormalCall,
//updateTransferEndpoints,
"../reducers/call";
import {
  resetNormalCall,
  updateNormalCall,
  updateTransferEndpoints,
} from "../actions/call";
import storeRTK from "../store/storeRTK";
import Emitter from "../emitter";
import { callPlayer } from "../amazon-connect/handlers/call";
// import { SessionEvent } from "../types/call.type";

export async function callHandler(event: any) {
  const callPresent = storeRTK.getState().call.id;
  if (
    event?.self?.state !== "RINGING" &&
    event?.self?.direction != "OUTBOUND"
  ) {
    if (callPresent) {
      callPlayer.pause();
    }
  }
  if (event?.self?.state) {
    if (event.self?.state == "RELEASED") {
      const cstate = storeRTK.getState();
      if (cstate.call.id === event.id) {
        Emitter.emit(INTERNAL_CALL_RELEASE, event);
        storeRTK.dispatch(resetNormalCall());
        Emitter.emit("STATE_CALL", { body: event.body });
        updateContactReleaseToAIL(
          "voice",
          event.id as string,
          event.customer?.phoneNumber || ""
        );
      }
    } else if (event.self?.state == "TERMINATED") {
      //Do nothing
    } else if (event) {
      const callNotPresent = !storeRTK.getState().call.id;
      storeRTK.dispatch(updateNormalCall(event));

      const state = storeRTK.getState();

      if (callNotPresent && state.call.self?.direction != "OUTBOUND") {
        if (state.call.self?.state === "RINGING") {
          callPlayer.play();
          const callId = state.call.id;
          // Check if the browser supports notifications
          if ("Notification" in window) {
            // Request permission to show notifications

            Notification.requestPermission().then((permission) => {
              if (permission === "granted" && document.hidden) {
                // Create a notification
                const notification = new Notification(
                  "You have an incoming call",
                  {
                    body: "Click to answer it",
                    icon: "icon.png",
                  }
                );

                // Handle click events on the notification
                notification.onclick = () => {
                  const currState = storeRTK.getState();
                  if (
                    currState.call.id === callId &&
                    currState.call.self?.state === "RINGING"
                  ) {
                    // Accept call on click
                    Emitter.emit(API_CALL_ANSWER, {
                      body: { conversationId: currState.call.id },
                    });

                    // TODO: if user is not on call page, redirect to call page also show call tab in sidebar
                  }
                  // Close the notification
                  notification.close();
                };
              }
            });
          } else {
            console.error(
              "This browser does not support desktop notifications."
            );
          }
        }
      }
    }
  }
}

async function miloHandler(ev: any) {
  console.log(ev);
  const miloDeeplink = await getMiloDeeplink(
    ev.body?.contactId,
    ev.body?.phoneNumber
  );
  setTransferEndpoints(ev.body?.contactId);
  if (miloDeeplink) {
    // put half sec timeout because milo can come immediately and bg state may not be updated till then.
    setTimeout(() => {
      window.open(miloDeeplink, "_blank");
    }, 100);
  }
}

async function setTransferEndpoints(contactId: string | undefined) {
  if (contactId) {
    const allowedTransferEndpoints = await getTransferEndpoints(contactId);
    storeRTK.dispatch(updateTransferEndpoints(allowedTransferEndpoints));
    sendMessage({
      type: "notif",
      chan: API_CALL_TRANSFER_ENDPOINTS,
      body: { transferEndpoints: allowedTransferEndpoints },
      success: true,
    });
  } else {
    console.log("Error: error in setting transfer endpoints");
  }
}

async function contactEventHandler(ev: any) {
  getContactEventLoggingAPIHandler(ev.body?.contactId, ev.body?.eventType);
}

export default function registerCallHandlers() {
  Emitter.on(STATE_CALL, callHandler);
  Emitter.on(API_MILO_CHANNEL_TRANSACTION, miloHandler);
  Emitter.on(API_CONTACT_EVENT_LOGGING, contactEventHandler);
}
