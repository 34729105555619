import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ActionButton,
  Flex,
  View,
} from "@adobe/react-spectrum";
import { NONE } from "../../../constants/constants";
import CaseAi from "../../CasePage/CaseAI/CaseAi";
import CallHistorySidebar from "./CallHistorySidebar";
import CallHistoryDetail from "./CallHistoryDetail";
import { RootState } from "../../../store/storeConfig";
import ActiveCallContainer from "../CallDialer/ActiveCallContainer";

const CallHistory = () => {
  const { history } = useSelector((state: RootState) => state);
  const { conversations } = history || {};

  const [activeCallDetail, setActiveCallDetail] = useState<any>({});
  const [callList, setCallList] = useState<any>([]);
  const [filterAction, setFilterAction] = useState("all");

  useEffect(() => {
    if (conversations?.length > 0) {
      setActiveCallDetail(conversations[0]);
      setCallList(conversations);
    }
  }, []);

  const getActiveCallId = (id: string) => {
    const activeCall = conversations.find((call: any) => call.id === id);
    setActiveCallDetail(activeCall);
  };

  const handleMissedCall = () => {
    const missedCallList =
      conversations?.length > 0 &&
      conversations.filter((call: any) => call.self?.missed === true);
    setCallList(missedCallList);
    setFilterAction("missed");
  };

  const handleAllCall = () => {
    setCallList(conversations);
    setFilterAction("all");
  };

  return (
    <View backgroundColor={"static-white"} width={"100%"} height={"100%"}>
      <Flex height={"100%"}>
        {conversations?.length > 0 ? (
          <View backgroundColor={"static-white"} width={"100%"}>
            <Flex direction={"column"} height={"100%"}>
              <View backgroundColor="static-white" height={"10%"}>
                <View
                  paddingX={20}
                  paddingY={"12px"}
                  backgroundColor="static-white"
                >
                  <ActionButton
                    UNSAFE_className={`call-history-button ${filterAction === "all" && "call-history-button-active" }`}
                    onPress={handleAllCall}
                  >
                    All
                  </ActionButton>
                  <ActionButton
                    marginX={8}
                    UNSAFE_className={`call-history-button ${filterAction === "missed" && "call-history-button-active" }`}
                    onPress={handleMissedCall}
                  >
                    Missed
                  </ActionButton>
                  <ActionButton
                    UNSAFE_className="call-history-button"
                    isDisabled
                  >
                    Favourites
                  </ActionButton>
                </View>
              </View>
              <View height={"90%"}>
                <Flex
                  direction={"row"}
                  alignItems={"start"}
                  height={"100%"}
                  width={"100%"}
                >
                  <CallHistorySidebar
                    getActiveCallId={getActiveCallId}
                    activeCallerId={activeCallDetail.id}
                    callList={callList}
                  />
                  <CallHistoryDetail activeCallDetail={activeCallDetail} />
                </Flex>
              </View>
            </Flex>
          </View>
        ) : (
          <Flex alignItems={"center"} justifyContent={"center"} width={"90%"}>
            <ActiveCallContainer
              title="No Call history"
              description="Start a new call or wait for the call"
            />
          </Flex>
        )}
        <View
          borderColor="gray-300"
          borderWidth="thin"
          backgroundColor="static-white"
          borderTopWidth={NONE}
          height={"100%"}
        >
          <CaseAi caseID="" isDisabled={true} />
        </View>
      </Flex>
    </View>
  );
};

export default CallHistory;
