import {
    LOG_PREFIX, CONTACT_EVENT_STATE_MAP
} from '../../constants/constants';
import { connectSession } from './../handlers/session';
import { updateChatAndSend } from '../handlers/chat';
import { changeAgentStateToAvailable } from '../handlers/agent';
import { AmazonChatTranscriptMessage } from '../models/chat';
import Emitter from '../../emitter';
import { API_CHAT_UPDATE, API_CHECK_FOR_LOST_MESSAGE, API_DOWNLOAD_ATTACHMENT_STATUS, API_SEND_ATTACHMENT_DOWNLOAD_SAVEAS, API_SEND_ATTACHMENT_STATUS, ATTACHMENT_FAILED_MESSAGE, STATE_CHAT_HISTORY } from '../constants';
const historyToken: { [key: string]: string } = {};
const lp = LOG_PREFIX + "[api.chat.ts]: ";

export function getAgentContactById(id: string) {
    const logPrefix = lp + "getAgentContactById id = " + id + " ";
    try {
        let contacts = connectSession.agent.getContacts();
        if(contacts.length > 0) {
            const contact = contacts.find((ct:any) => ct.contactId === id);
            return contact;
        }
        return null;
    } catch(error) {
        console.log(logPrefix + " Error in getting contact by id" , error)
        return null;
    }
}

export function rejectChatContact(contactId: string) {
    const logPrefix = lp + "rejectChatContact contactId = " + contactId + " ";
    try {
        const contact = getAgentContactById(contactId);
        changeAgentStateToAvailable(); 

        if(contact) {
            contact.reject({
                success: function () {
                    clearCurrentChatContact(contactId);
                    console.log(logPrefix + ' Rejected Incoming chat via Streams');
                },
                failure: function (err: any) {
                    console.log(logPrefix + ' Failed to Reject chat via Streams', err);
                    clearCurrentChatContact(contactId);
                    clearChatOnError(contactId);
                }
            });
        } else {
            console.log(logPrefix +" Error in rejecting chat, contact not exist");
            clearCurrentChatContact(contactId);
            clearChatOnError(contactId);
        }
    } catch(error) {
        console.log(logPrefix + " Error in rejecting chat contact", error);
        clearCurrentChatContact(contactId);
        clearChatOnError(contactId);
    }
}

export function acceptChatContact(contactId: string) {
    // Streams API call to Accept the Incoming Contact
    const logPrefix = lp + "acceptChatContact contactId = " + contactId + " ";
    try {
        const contact = getAgentContactById(contactId);
        if(contact) {
            contact.accept({
                success: function () {
                    console.log(logPrefix + 'Accepted via Streams');
                },
                failure: function (err: Error) {
                    console.log(logPrefix + 'Failed to establish connection via Streams', err);
                    Emitter.emit(API_CHAT_UPDATE, {
                      body: {
                        body: {
                          err,
                          message:
                            logPrefix +
                            "Failed to establish connection via Streams",
                        },
                      },
                    });
                    clearCurrentChatContact(contactId);
                    clearChatOnError(contactId);
                }
            });
        } else {
            console.log(logPrefix + "Error in accepting chat, contact not exist");
            clearCurrentChatContact(contactId);
            clearChatOnError(contactId);
        }
    } catch(error) {
        console.log(logPrefix + "Error in accepting chat contact", error);
        clearCurrentChatContact(contactId);
        clearChatOnError(contactId);
    }
}

export function transferChatToQuickConnects(ev: any) {
    const logPrefix = lp + "transferChatToQuickConnects: " + ev.body?.conversationId + " ";
    if(!ev?.body?.transferEndpoint && !ev.body.transferEndpoint.quick_connect_type && ev.body.transferEndpoint.arn) {
        console.error(logPrefix + "Error in transfer, transferEndpoint is not valid", ev?.body?.transferEndpoint);
        return
    }
    
    let transferEndpoint =  {
        agentLogin: null,
        endpointId: ev.body.transferEndpoint.arn,
        endpointARN: ev.body.transferEndpoint.arn,
        phoneNumber: null,
        name: ev.body.transferEndpoint.name,
        queue: null,
        type: ev.body.transferEndpoint.quick_connect_type.toLowerCase()
    } as unknown  as connect.EndpointType.QUEUE;
    let chatContact = getAgentContactById(ev.body?.conversationId);
    try{
        chatContact.addConnection(transferEndpoint, {
            success: function(data: any) {
                console.log(logPrefix + "transfer success in Queue, will disconnect agent connection now", data);
                chatContact.getAgentConnection().destroy({
                    success: () => {
                        console.log(logPrefix + "Agent connection is destroyed successfully");
                    }, failure: (error: Error) => {
                        console.log(logPrefix + "Error in disconnecting agent connection", error)
                    }
                });
            },
            failure: function(error: Error) {
                console.log(logPrefix + "Chat transfer failed", error);
            }
        });
    } catch(error){
        console.log(logPrefix + "Chat transfer failed in catch", error);    
    }
   
}

export function disconnectChatContact(conversationId: string) {
    // Streams API call to Drop a Connected Contact
    const logPrefix = lp + "disconnectChatContact conversationId = " + conversationId + " ";
    try {
        const contact = getAgentContactById(conversationId);
        const agentConn = contact.getAgentConnection();
        if(agentConn) {
            agentConn.destroy({
                success: function () {
                    console.log(logPrefix + 'Contact disconnected via Streams');
                    clearCurrentChatContact(conversationId);
                },
                failure: function (err: any) {
                    console.log(logPrefix + 'Failed to disconnect the contact via Streams', err);
                    clearCurrentChatContact(conversationId);
                    clearChatOnError(conversationId);
                }
            }); 
        } else {
            console.log(logPrefix + "Agent connection not available, will disconnect chat");
            clearCurrentChatContact(conversationId);
            clearChatOnError(conversationId);
        }
    } catch(error) {
        console.log(logPrefix + "Error in disconnecting chat contact", error);
        clearCurrentChatContact(conversationId);
        clearChatOnError(conversationId);
    }
}

export function sendChatMessage(ev: any) {
    const logPrefix = lp + "sendChatMessage: " + ev.body?.contactId + " ";
    try {
        const contact = getAgentContactById(ev?.body?.contactId);
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            if(session) {
                session.sendMessage({
                    contentType: "text/plain",
                    message: ev.body.message
                })
                .then((res: any) => {
                    console.log(logPrefix + "Message sent", res);
                })
                .catch((error: any) => console.log(logPrefix + "Error in sending message in chat session with id", error));
            } else {
                console.error(logPrefix + "Error: No agentChatSession is available");
            }
        })
    } catch(error) {
        console.log(logPrefix + "Error in send message in chat session with id", error);
        throw error;
    }
}
export function getChatHistoryAPI(ev: any) {
    const logPrefix = lp + "getChatHistory: " + ev.body?.conversationId + " ";
    try {
        const contact = getAgentContactById(ev.body.conversationId);
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            if(session) {
                const params: any = {
                    maxResults: 100,
                    sortOrder: "ASCENDING",
                    direction: "BACKWARD"
                  }
                  if(historyToken[ev.body.conversationId]) {
                    params['nextToken'] = historyToken[ev.body.conversationId]; 
                  }
                session.getTranscript(params).then((res: any) => {
                    const transcript = res.data.Transcript.filter((event: AmazonChatTranscriptMessage) => ((event.Type === "MESSAGE" || event.Type === "ATTACHMENT") && ['CUSTOMER', 'AGENT'].includes(event.ParticipantRole)));
                    const formatT = transcript.map((msg: AmazonChatTranscriptMessage) => {
                    if (msg?.Type === 'ATTACHMENT') {
                        return {
                            messageId: msg.Id,
                            role: msg.ParticipantRole,
                            text: msg?.Attachments,
                            timestamp: new Date(msg.AbsoluteTime).getTime()
                        }
                    }
                    return {
                        messageId: msg.Id,
                        role: msg.ParticipantRole,
                        text: msg.Content,
                        timestamp: new Date(msg.AbsoluteTime).getTime()
                    }
                    
                    });
                    console.log(logPrefix + "Chat history fetched", formatT.length, res.data.NextToken)
                    historyToken[ev.body.conversationId] = res.data.NextToken;
                    Emitter.emit(STATE_CHAT_HISTORY, {
                      body: {
                        conversationId: ev.body.conversationId,
                        transcript: formatT,
                        nextHistoryToken: res.data.NextToken,
                        success: true
                      },
                    });
                    return {
                        conversationId: ev.body.conversationId,
                        transcript: formatT,
                        nextHistoryToken: res.data.NextToken
                    }
                }).catch((error: any) => {
                    console.log(logPrefix + "Error in getTranscript", error)
                    throw error;
                });
            } else {
                console.error(logPrefix + "Error: No agentChatSession is available while trying to get chat history");
            }
        });
    } catch(error) {
        console.log(logPrefix + "Error in get history chat session with id", error);
        throw error;
    }
}

export function getLostMessages(ev: any) {
    const logPrefix = lp + "getLostMessages: " + ev.body?.conversationId + " ";
    try {
        const contact = getAgentContactById(ev.body.conversationId);
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            if(session) {
                session.getTranscript({
                    maxResults: 100,
                    sortOrder: "ASCENDING"
                  }).then((res: any) => {
                    const transcript = res.data.Transcript.filter((event: AmazonChatTranscriptMessage) => ((event.Type === "MESSAGE" || event.Type === "ATTACHMENT") && ['CUSTOMER', 'AGENT'].includes(event.ParticipantRole)));
                    const formatT = transcript.map((msg: AmazonChatTranscriptMessage) => {
                        if(msg?.Type === "ATTACHMENT"){
                            return {
                                id: msg.Id,
                                senderId: msg.ParticipantId,
                                role: msg.ParticipantRole,
                                body: msg?.Attachments,
                                bodyType: msg.Type,
                                timestamp: msg.AbsoluteTime,
                                webChatConversation: {
                                    id: msg?.ContactId ? msg.ContactId : ev.body.conversationId
                                }
                            }
                        }
                        return {
                            id: msg?.Id,
                            senderId: msg?.ParticipantId,
                            role: msg?.ParticipantRole,
                            body: msg?.Content,
                            bodyType: msg?.ContentType,
                            timestamp: msg?.AbsoluteTime,
                            webChatConversation: {
                                id: ev.body.conversationId
                            }
                        }
                    });
                    Emitter.emit(API_CHECK_FOR_LOST_MESSAGE, {
                      body: formatT,
                      success: true,
                    });
                }).catch((error: Error) => {
                    console.log(logPrefix + "Error in getTranscript",error)
                });
            } else {
                console.error(logPrefix + "Error: No agentChatSession is available while trying to get chat history");
            }
        });
    } catch(error) {
        console.log(logPrefix + "Error in get history chat session with id" + ev.body.conversationId, error);
    }
}

function clearChatOnError(contactId: string) {
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId})
}

export function clearCurrentChatContact(contactId: string) {
    const contact = getAgentContactById(contactId);
    if(contact) {
        // TODO handle ended chat, because clear api might fail for ended chat
        try {
            contact.clear();
        } catch(e) {
            throw e;
        }
    } else {
        //console.log("Error cannot clear contact, no contact exists");
    }
}

export function sendChatTyping(ev: any) {
    const logPrefix = lp + "sendChatTyping: " + ev.body?.contactId + " ";
    try{
        const contact = getAgentContactById(ev?.body?.contactId);
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            if(session) {
                session.sendEvent({
                    contentType: "application/vnd.amazonaws.connect.event.typing",
                }).then((res: any) => console.log("typing sent: ", res));
            } else {
                console.error(logPrefix + "Error: No agentChatSession is available");
            }
        })
    } catch(error) {
        console.log(logPrefix + "Error in send message in chat session with id" + ev.body.contactId, error);
        throw error;
    }
    //TODO implement send typing code from amazon connect document
}

export function acceptOrRejectChat(ev: any) {
    if(ev.body.state === 'connected') {
        acceptChatContact(ev.body.conversationId);
    } else if(ev.body.state === 'disconnected') {
        disconnectChatContact(ev.body.conversationId);
    }
}

export function downloadAttachmentFromAmzn(ev: any) {
  const logPrefix = lp + "downloadAttachment: " + ev.body?.conversationId + " ";
  try {
    const contact = getAgentContactById(ev.body.conversationId);
    const cnn = contact
      .getConnections()
      .find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
    cnn.getMediaController().then((session: any) => {
      if (session) {
        // const awsSdkResponse = await session.downloadAttachment({
        //     attachmentId: ev.body.attachmentName
        //   });
        //   console.log('awsSdkResponse>>>>>>>',awsSdkResponse)
        //   const { attachment } = awsSdkResponse.data;
        session
        .downloadAttachment({
            attachmentId: ev.body.attachmentId,
          })
          .then((blob: Blob) => {
            // downloadAttachmentFile(res, ev.body.attachmentName);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = ev.body.attachmentName
            link.click();
            // Emitter.emit(API_SEND_ATTACHMENT_DOWNLOAD_SAVEAS, {
            //   body: {
            //     fileName: ev.body.attachmentName,
            //     url: url,
            //     success: true,
            //   },
            // });
            Emitter.emit(API_DOWNLOAD_ATTACHMENT_STATUS, {
              body: {
                conversationId: ev.body.conversationId,
                attachmentId: ev.body.attachmentId,
                success: true,
              },
            });
          })
          .catch((error: Error) => {
            console.log(logPrefix + "Error in downloadAttachment", error);
            Emitter.emit(API_DOWNLOAD_ATTACHMENT_STATUS, {
              body: {
                conversationId: ev.body.conversationId,
                attachmentId: ev.body.attachmentId,
                success: false,
              },
            });
          });
      } else {
        console.error(
          logPrefix +
            "Error: No agentChatSession is available while trying to get attachments"
        );
        Emitter.emit(API_DOWNLOAD_ATTACHMENT_STATUS, {
          body: {
            conversationId: ev.body.conversationId,
            attachmentId: ev.body.attachmentId,
            success: false,
          },
        });
      }
    });
  } catch (error) {
    console.log(
      logPrefix +
        "Error in attachment session with id" +
        ev.body.conversationId,
      error
    );
    Emitter.emit(API_DOWNLOAD_ATTACHMENT_STATUS, {
      body: {
        conversationId: ev.body.conversationId,
        attachmentId: ev.body.attachmentId,
        success: false,
      },
    });
  }
}

export function sendAttachmentToAmzn(ev: any) {
  try {
    const contact = getAgentContactById(ev.body.conversationId);
    if (ev.body.attachment) {
      const cnn = contact
        .getConnections()
        .find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
      cnn.getMediaController().then((session: any) => {
        if (session) {
          const binaryData = atob(ev.body.attachment);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: ev.body.contentType });
          const file = new File([blob], ev.body.attachmentName, {
            type: ev.body.contentType,
          });
          session
            .sendAttachment({
              attachment: file,
            })
            .then((res: any) => {
              Emitter.emit(API_SEND_ATTACHMENT_STATUS, {
                body: {
                  conversationId: ev.body.conversationId,
                  attachmentName: ev.body.attachmentName,
                  success: true,
                },
              });
            })
            .catch((error: any) => {
              Emitter.emit(API_SEND_ATTACHMENT_STATUS, {
                body: {
                  conversationId: ev.body.conversationId,
                  message: error.message
                    ? error.message
                    : ATTACHMENT_FAILED_MESSAGE,
                  attachmentName: ev.body.attachmentName,
                  success: false,
                },
              });
            });
        } else {
          console.error(
            "Error: No agentChatSession is available while trying to get attachments"
          );
          Emitter.emit(API_SEND_ATTACHMENT_STATUS, {
            body: {
              conversationId: ev.body.conversationId,
              message: ATTACHMENT_FAILED_MESSAGE,
              attachmentName: ev.body.attachmentName,
              success: false,
            },
          });
        }
      });
    }
  } catch (err: any) {
    Emitter.emit(API_SEND_ATTACHMENT_STATUS, {
      body: {
        conversationId: ev.body.conversationId,
        message: err.message ? err.message : ATTACHMENT_FAILED_MESSAGE,
        attachmentName: ev.body.attachmentName,
        success: false,
      },
    });
  }
}
