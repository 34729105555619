import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendMessage } from "../chrome/tabs";
import { BG_HISTORY_CHANGE } from "../constants/events";
import { Call } from "../reducers/call";
import { Callback } from "../reducers/callback";
import { IndividualChat } from "../reducers/chat";
import //addCallToHistory,
//deleteCallFromHistory,
//removeCallFromHistory,
//setHistory,
//addCallbackToHistory,
//deleteCallbackFromHistory,
//removeCallbackFromHistory,
//deleteChatFromHistory,
// removeChatFromHistory,
//addChatToHistory,
//addCaseToHistory,
//removeCaseFromHistory,
// deleteCaseFromHistory,
"../reducers/history";
import { AppDispatch, RootState } from "../store";
import { IndividualCase } from "../reducers/case";
import {
  addCallToHistory,
  addCallbackToHistory,
  addCaseToHistory,
  addChatToHistory,
  deleteCallFromHistory,
  deleteCallbackFromHistory,
  deleteCaseFromHistory,
  deleteChatFromHistory,
  removeCallFromHistory,
  removeCallbackFromHistory,
  removeCaseFromHistory,
  removeChatFromHistory,
  setHistory,
} from "../actions/history";
import storeRTK from "../store/storeRTK";
import { ChatMessage } from "../types/chat.type";

function sendChangeMessage(state: RootState) {
  const history = state.history;
  sendMessage({
    type: "notif",
    body: history,
    chan: BG_HISTORY_CHANGE,
  });
}

export const initializeHistory = createAsyncThunk<
  void,
  null,
  { dispatch: AppDispatch; state: RootState }
>("history/initializeHistory", (ignore, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const agent_id = state.agent.id as string;
  const agentData = localStorage.getItem("agent_id");
  if (agentData !== null) {
    const agentSavedData = JSON.parse(agentData);
    storeRTK.dispatch(setHistory(agentSavedData[agent_id] || {}));
  }
  sendChangeMessage(thunkApi.getState());
});

export const addCallToHistoryAction = createAsyncThunk<
  void,
  Call,
  { dispatch: AppDispatch; state: RootState }
>("history/addCallToHistoryAction", (call, thunkApi) => {
  let state = thunkApi.getState();
  if (!state.call.id || !state.call.self) return;
  const existingCallIndex = state.history.conversations.findIndex(
    (c: Call) => c.id === call.id
  );
  if (existingCallIndex > -1) {
    thunkApi.dispatch(deleteCallFromHistory(existingCallIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.conversations.length >=
    parseInt(process.env.MAX_HISTORY_ITEMS as string)
  ) {
    const lastEntry: Callback = getLastEntryFromCallOrCallbacks();
    if (
      lastEntry?.conversationMedia &&
      lastEntry?.conversationMedia === "CALLBACK"
    ) {
      thunkApi.dispatch(removeCallbackFromHistory());
    } else {
      thunkApi.dispatch(removeCallFromHistory());
    }
  }
  thunkApi.dispatch(addCallToHistory(call));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

export const addCallbackToHistoryAction = createAsyncThunk<
  void,
  Callback,
  { dispatch: AppDispatch; state: RootState }
>("history/addCallbackToHistoryAction", (callback, thunkApi) => {
  let state = thunkApi.getState();
  if (
    !state.callback.id ||
    !state.callback.self ||
    !state.callback.self?.callback?.callbackNumber ||
    !state.callback.customer?.address
  )
    return;
  const existingCallbackIndex = state.history.callback.findIndex(
    (c: any) => c.id === callback.id
  );
  if (existingCallbackIndex > -1) {
    thunkApi.dispatch(deleteCallbackFromHistory(existingCallbackIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.callback.length >=
    parseInt(process.env.MAX_HISTORY_ITEMS as string)
  ) {
    const lastEntry: any = getLastEntryFromCallOrCallbacks();
    if (
      lastEntry?.conversationMedia &&
      lastEntry?.conversationMedia === "CALLBACK"
    ) {
      thunkApi.dispatch(removeCallbackFromHistory());
    } else {
      thunkApi.dispatch(removeCallFromHistory());
    }
  }
  thunkApi.dispatch(addCallbackToHistory(callback));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

export const addChatToHistoryAction = createAsyncThunk<
  void,
  IndividualChat,
  { dispatch: AppDispatch; state: RootState }
>("history/addChatToHistoryAction", (chat, thunkApi) => {
  let state = thunkApi.getState();
  //check if chat is already released
  if (!chat.self?.id) return;
  const liveChatIndex = state.chat.chats.findIndex((c) => c.id === chat.id);

  if (liveChatIndex === -1) return;

  const existingChatIndex = state.history.chat.findIndex(
    (c: ChatMessage) => c.id === chat.id
  );
  if (existingChatIndex > -1) {
    thunkApi.dispatch(deleteChatFromHistory(existingChatIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.chat.length >=
    parseInt(process.env.MAX_CHAT_HISTORY_ITEMS as string)
  ) {
    thunkApi.dispatch(removeChatFromHistory());
  }
  thunkApi.dispatch(addChatToHistory(chat));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

// Code To add case from history
export const addCaseToHistoryAction = createAsyncThunk<
  void,
  IndividualCase,
  { dispatch: AppDispatch; state: RootState }
>("history/addCaseToHistoryAction", (caseObject, thunkApi) => {
  let state = thunkApi.getState();
  if (!caseObject?.id) return;
  const liveCaseIndex = state.cases.cases.findIndex(
    (c: any) => c.id === caseObject.id
  );
  if (liveCaseIndex === -1) return;
  const existingCaseIndex = state.history.cases.findIndex(
    (c: any) => c.id === caseObject.id
  );
  if (existingCaseIndex > -1) {
    thunkApi.dispatch(deleteCaseFromHistory(existingCaseIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.cases.length >=
    parseInt(process.env.MAX_CHAT_HISTORY_ITEMS as string)
  ) {
    thunkApi.dispatch(removeCaseFromHistory());
  }
  //check if case is already released
  thunkApi.dispatch(addCaseToHistory(caseObject));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

export function getLastEntryFromCallOrCallbacks() {
  const state = storeRTK.getState();
  const callAndCallbacks = [
    ...state.history.callback,
    ...state.history.conversations,
  ];
  callAndCallbacks.sort((a: any, b: any) => {
    let a_time: any = a?.self?.endTime || a?.self?.connectedTime;
    let b_time: any = b?.self?.endTime || b?.self?.connectedTime;
    return b_time - a_time;
  });
  return callAndCallbacks.pop();
}
