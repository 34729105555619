import ring from "./ring.wav";
import chatRing from "./chat-ring.mp3";
import digitalRing from "./digital-ring.mp3";
import dangRing from "./dang.mp3";
import phonenewringtone from "./phonenewringtone.mp3";
import ringbacknewtone from "./ringbacknewtone.mp3";
import { RING_TYPES } from "../../constants/constants";

let sound = ring;
export default class Player {
  constructor(options) {
    switch (options.type) {
      case RING_TYPES.CALL:
        sound = phonenewringtone;
        break;
      case RING_TYPES.CHAT:
        sound = chatRing;
        break;
      case RING_TYPES.MESSAGE:
        sound = digitalRing;
        break;
      case RING_TYPES.LOGOUT_ALARM:
        sound = dangRing;
        break;
      case RING_TYPES.CALLBACK:
        sound = digitalRing;
        break;
      case RING_TYPES.CASE:
        sound = ringbacknewtone;
        break;
      default:
        sound = ring;
        break;
    }
    this.audio = new Audio(sound);
    this.audio.loop = options.loop;
  }
  // play(deviceId) {
  //   try {
  //     if (deviceId) {
  //       this.audio
  //         .setSinkId(deviceId)
  //         .then((res) => {
  //           this.audio.play();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       this.audio.play();
  //     }
  //   } catch (error) {
  //     console.log("Problem in playing audio file", error);
  //   }
  // }
  play() {
    try {
      this.audio.play();
    } catch (error) {
      console.log("Problem in playing audio file", error);
    }
  }
  pause() {
    this.audio.pause();
  }
  setOutputDevice(selectedDeviceId) {
    this.audio.setSinkId(selectedDeviceId);
  }
}
