import { useState } from "react";
import {
  ActionButton,
  ActionGroup,
  Content,
  Flex,
  Form,
  Item,
  Key,
  TextField,
  View,
} from "@adobe/react-spectrum";
import { CloseIconBlack, Send } from "../../common/Icons";
import storeRTK from "../../../store/storeRTK";
import { setSuggestCaseIssue } from "../../../actions/case";

const DislikeReasonDialog = (props: any) => {
  const { activeCase, message } = props;
  const [feedbackText, setFeedBackText] = useState("");

  const getMessage = activeCase?.suggestCaseMessages?.find(
    (msg: any) => msg.uniqueId === message.uniqueId
  );

  const setAction = (key: Key) => {
    if (key === "reason-1") {
      setFeedBackText("reason-1 text");
    }
    if (key === "reason-2") {
      setFeedBackText("reason-2 text");
    }
    if (key === "reason-3") {
      setFeedBackText("reason-3 text");
    }
    if (key === "reason-4") {
      setFeedBackText("reason-4 text");
    }
  };

  const handleSendFeedBack = () => {
    if (getMessage) {
      storeRTK.dispatch(
        setSuggestCaseIssue(
          activeCase.id,
          getMessage.userResponse,
          getMessage.aiResponse,
          getMessage.showSuggestDialog,
          false,
          true,
          true,
          feedbackText,
          message.uniqueId
        )
      );
      setFeedBackText("");
    }
  };

  const handleCloseFeedbackDialog = () => {
    if (getMessage) {
      storeRTK.dispatch(
        setSuggestCaseIssue(
          activeCase.id,
          getMessage.userResponse,
          getMessage.aiResponse,
          getMessage.showSuggestDialog,
          false,
          true,
          false,
          feedbackText,
          message.uniqueId
        )
      );
    }
  };

  return (
    <View
      height="188px"
      marginTop={10}
      marginBottom={10}
      padding={12}
      UNSAFE_className="case-ai-feedback-dialog"
    >
      <Content>
        <Flex direction="row" justifyContent="space-between">
          <span className="case-ai-feedback-heading">Tell us why ?</span>
          <span onClick={handleCloseFeedbackDialog}>
            <CloseIconBlack />
          </span>
        </Flex>
        <ActionGroup marginTop={22} onAction={(key) => setAction(key)}>
          <Item key="reason-1">reason here</Item>
          <Item key="reason-2"> reason here 2</Item>
          <Item key="reason-3"> reason here 3</Item>
          <Item key="reason-4"> reason here 4</Item>
        </ActionGroup>
        <Form marginTop="22px">
          <Flex direction="row" justifyContent="space-between">
            <TextField
              name="feedback"
              type="text"
              value={feedbackText}
              placeholder="I didn't receive the ..."
              isQuiet
              width="250px"
              onChange={setFeedBackText}
            />
            <ActionButton
              UNSAFE_style={{ cursor: "pointer" }}
              onPress={handleSendFeedBack}
            >
              <Send />
            </ActionButton>
          </Flex>
        </Form>
      </Content>
    </View>
  );
};

export default DislikeReasonDialog;
