import { STATUS_SET, LOGOUT } from '../constants/action-types';

export function setStatus(isLoggedIn = true) {
    return {
        type: STATUS_SET,
        isLoggedIn,
    };
}

export function setLogoutState() {
    return {
        type: LOGOUT,
    };
}
