/**
 * Generic Polling class to handle various polling related tasks.
 */
export default class Polling {
    /**
     *
     * @param {Function} pollingCallback function(continuePoll, Polling)
     * @param {Number} delay Delay between polling in milliseconds
     */
    constructor(pollingCallback, delay) {
        // Initialize variables
        this._timer = null;
        this._shouldPoll = true;
        this.continuePoll = this.continuePoll.bind(this);
        this.poll = this.poll.bind(this);
        this._started = false;

        this.setParameters(pollingCallback, delay);
    }

    setParameters(pollingCallback, delay) {
        this._delay = delay;
        this._pollingCallback = pollingCallback;
    }

    hasStarted() {
        return this._started;
    }

    /**
     * Start Polling.
     */
    start() {
        if (this._started) return;

        this._started = true;
        this._shouldPoll = true;
        this.poll();
    }

    /**
     * The polling function, which call the polling Callback
     * with the continue function to continue the polling.
     */
    poll() {
        if (!this._shouldPoll) return;
        this._pollingCallback(this.continuePoll, this);
    }

    /**
     * Convenience function to run the next polling instance.
     */
    continuePoll() {
        this._timer = setTimeout(this.poll, this._delay);
    }

    /**
     * Stop Polling
     */
    stop() {
        this._shouldPoll = false;
        this._started = false;
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }
}
