import {
  CASE_RESET,
  CASE_SET,
  INDIVIDUAL_CASE_RESET,
  INITIAL_CASE_SET,
  ACTIVE_CASE_UPDATE,
  CASE_CONNECTED_TIME,
  CASE_SET_SUGGEST_ISSUE,
  CASE_SET_SUMMARY_ISSUE,
  REROUTE_CASE_DATA,
} from "../constants/action-types";

export function caseReset() {
  return {
    type: CASE_RESET,
  };
}

export function setInitialCaseState(indCase: any) {
  return {
    type: INITIAL_CASE_SET,
    indCase,
  };
}
export function setCaseState(indCase: any) {
  return {
    type: CASE_SET,
    indCase,
  };
}

export function individualCaseReset(indCase: any) {
  return {
    type: INDIVIDUAL_CASE_RESET,
    indCase,
  };
}

export function setActiveCaseUpdate(message: any) {
  let case_id = message.caseID ? message.caseID : message;
  return {
    type: ACTIVE_CASE_UPDATE,
    caseID: case_id,
  };
}

export function setCaseConnectedTime(
  caseID: string | undefined,
  caseHours: number,
  caseMins: number,
  caseSecs: number
) {
  return {
    type: CASE_CONNECTED_TIME,
    caseID,
    connectedTime: { caseHours, caseMins, caseSecs },
  };
}

export function setSuggestCaseIssue(
  caseID: string,
  userResponse: string,
  aiResponse: string,
  showSuggestDialog: boolean,
  isLiked: boolean,
  isDisliked: boolean,
  isDislikeFeedbackOpen: boolean,
  dislikeReason: string,
  uniqueId: string
) {
  return {
    type: CASE_SET_SUGGEST_ISSUE,
    caseID,
    userResponse,
    aiResponse,
    showSuggestDialog,
    isLiked,
    isDisliked,
    isDislikeFeedbackOpen,
    dislikeReason,
    uniqueId,
  };
}

export function setCaseSummaryIssue(
  caseID: string,
  userResponse: string,
  aiResponse: string
) {
  return {
    type: CASE_SET_SUMMARY_ISSUE,
    caseID,
    userResponse,
    aiResponse,
  };
}

export function getRerouteCaseData(caseID: string, caseRerouteData: any) {
  return {
    type: REROUTE_CASE_DATA,
    caseID,
    caseRerouteData,
  };
}
