import {
    individualCaseReset,
    setCaseState,
    setActiveCaseUpdate,
} from '../actions/case';
import { IndividualTask } from '../amazon-connect/models/task';
import {
    STATE_CASE,
    API_ACTIVE_CASE_UPDATE,
} from '../constants/constants';
import Emitter from '../emitter';
import storeRTK from '../store/storeRTK';

/**
 * Handling the case state update functonality
 * @param {*} message
 */
export function updateCase(message:any) {
    const caseBody = message.body;
    if (
        caseBody?.self?.state === 'RELEASED' ||
        caseBody?.self?.state === 'TERMINATED'
    ) {
        const state = storeRTK.getState();
        const wasActive = caseBody.id === state.cases.activeCaseID;
        let getActiveCaseIndex = null;
        if (wasActive) {
            getActiveCaseIndex = state.cases.cases.findIndex(
              (c: IndividualTask) => c.id === state.cases.activeCaseID
            );
        }
        storeRTK.dispatch(individualCaseReset(caseBody));
        if (wasActive) {
            const newState = storeRTK.getState();
            const len = newState.cases.cases.length;
            if (len === 0) {
                const body = { caseID: '' };
                storeRTK.dispatch(setActiveCaseUpdate(body));
            } else if (getActiveCaseIndex === 0) {
                //when removed case is first one
                const body = { caseID: newState.cases.cases[0].id };
                storeRTK.dispatch(setActiveCaseUpdate(body));
            } else if (getActiveCaseIndex === len) {
                //when removed case was last one
                const body = { caseID: newState.cases.cases[len - 1].id };
                storeRTK.dispatch(setActiveCaseUpdate(body));
            } else {
                //when removed case was in middle
                const body = {
                    caseID: newState.cases.cases[getActiveCaseIndex - 1].id,
                };
                storeRTK.dispatch(setActiveCaseUpdate(body));
            }
        }
    } else {
        storeRTK.dispatch(setCaseState(caseBody));

        const state = storeRTK.getState();
        if (
            state.cases.cases.length === 1 &&
            state.cases.cases[0].id !== state.cases.activeCaseID
        ) {
            const body = { caseID: state.cases.cases[0].id };
            storeRTK.dispatch(setActiveCaseUpdate(body));
        }
    }
}


function setActiveCase(messageEnvelope: any) {
  if (messageEnvelope.body) {
    storeRTK.dispatch(setActiveCaseUpdate(messageEnvelope));
  }
}

/**
 * Register default Events for Case
 */
export default function registerCaseHandlerEvents() {
    Emitter.on(STATE_CASE, updateCase);
    Emitter.on(API_ACTIVE_CASE_UPDATE, setActiveCase);
}
