import { useState } from "react";
import { sendMessage } from "../../services";
import { BG_LOGIN_DETAILS } from "../../constants/constants";
// import Emitter from '../../emitter';

import {
  Provider,
  defaultTheme,
  Dialog,
  Button,
  ActionButton,
  DialogTrigger,
  Heading,
} from "@adobe/react-spectrum";

import "./LoginForm.css";
import { Alert } from "../common/Icons";
import { checkVpnConnNTimes } from "../../utils/utils";
import { initiateLogin } from "../../actions/session";
import store from "../../store";
import { loginHandler } from "../../controller/okta";
import Emitter from "../../emitter";
import { setLoader } from "../../actions/common";
import storeRTK from "../../store/storeRTK";
import { useSelector } from "react-redux";

const LoginForm = () => {
  const [alert, setAlert] = useState(false);
  const isLoading = useSelector((state: any) => state.common?.isLoading);

  const onCloseHandler = () => {
    setAlert(false);
    storeRTK.dispatch(setLoader(false));
  };

  const handleLogin = () => {
    loginHandler();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    storeRTK.dispatch(setLoader(true));
    setTimeout(() => {
      handleLogin();
    }, 100);
  };

  return (
    <Provider theme={defaultTheme}>
      <div className="loginFormWrapper" style={{ backgroundColor: "#EAEAEA"}}>
        {alert && (
          <DialogTrigger type="modal" mobileType="modal" defaultOpen={true}>
            <ActionButton isHidden isQuiet></ActionButton>
            {(close) => (
              <Dialog size={"S"} UNSAFE_className="custom-dialog">
                <Heading level={4}>
                  <span>
                    <span style={{ marginRight: "15px" }}>
                      <Alert />
                    </span>
                    <span>Unable to connect to server</span>
                  </span>
                </Heading>
                <Button
                  UNSAFE_className={"buttonMin"}
                  variant="cta"
                  onPress={onCloseHandler}
                >
                  OK
                </Button>
              </Dialog>
            )}
          </DialogTrigger>
        )}
        <form className={"loginForm"} action="" onSubmit={(e) => onSubmit(e)}>
          {!isLoading && (
            <Button
              type="submit"
              variant="cta"
              margin={"auto"}
              data-testid="loginButton"
            >
              Login
            </Button>
          )}
        </form>
      </div>
    </Provider>
  );
};

export default LoginForm;
