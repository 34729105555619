export const STATUS_SET = "STATUS_SET";
export const LOGOUT = "LOGOUT";

export const AGENT_SET = "AGENT_SET";
export const AGENT_SET_STATES = "AGENT_SET_STATES";
export const AGENT_SET_CURRENT_STATE = "AGENT_SET_CURRENT_STATE";
export const AGENT_SET_QUEUES = "AGENT_SET_QUEUES";
export const AGENT_SET_ACW_STATE = "AGENT_SET_ACW_STATE";
export const RESET_AGENT_SET_ACW_STATE = "RESET_AGENT_SET_ACW_STATE";
export const AGENT_SET_CANNEDRESPONSES = "AGENT_SET_CANNEDRESPONSES";
export const AGENT_UPDATE_STATE = "AGENT_UPDATE_STATE";
export const AGENT_UPDATE_STATE_FROM_AWS = "AGENT_UPDATE_STATE_FROM_AWS";
export const AGENT_UPDATE_GEO_LOCATION = "AGENT_UPDATE_GEO_LOCATION";

export const INITIATE_RELOGIN = "INITIATE_RELOGIN";
export const INITIATE_LOGIN = "INITIATE_LOGIN";
export const CALL_SET = "CALL_SET";
export const CALL_RESET = "CALL_RESET";
export const CALL_SET_CONSULT = "CALL_SET_CONSULT";
export const CALL_SET_CONSULT_TYPE = "CALL_SET_CONSULT_TYPE";
export const UPDATE_CALL = "UPDATE_CALL";
export const UPDATE_CALL_TRANSFER_ENDPOINTS = "UPDATE_CALL_TRANSFER_ENDPOINTS";
export const RESET_NORMAL_CALL = "RESET_NORMAL_CALL";
export const STORE_LOGIN = "STORE_LOGIN";
export const RESET_STATE = "RESET_STATE";
export const UPDATE_MONITORING_CALL_STATUS = "UPDATE_MONITORING_CALL_STATUS";

export const CALLBACK_RESET = "CALLBACK_RESET";
export const CALLBACK_SET = "CALLBACK_SET";
export const PLAYBACK_MEDIA_URL_SET = "PLAYBACK_MEDIA_URL_SET";
export const UPDATE_NORMAL_CALLBACK = "UPDATE_NORMAL_CALLBACK";

export const CHAT_RESET = "CHAT_RESET";
export const CHAT_SET = "CHAT_SET";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const LOST_MESSAGE = "LOST_MESSAGE";
export const INDIVIDUAL_CHAT_RESET = "INDIVIDUAL_CHAT_RESET";
export const INITIAL_CHAT_SET = "INITIAL_CHAT_SET";

export const CASE_CONNECTED_TIME = "CASE_CONNECTED_TIME";
export const CASE_SET_SUMMARY_ISSUE ="CASE_SET_SUMMARY_ISSUE";
export const CASE_SET_SUGGEST_ISSUE ="CASE_SET_SUGGEST_ISSUE";
export const ACTIVE_CASE_UPDATE = "ACTIVE_CASE_UPDATE";
export const CASE_RESET = "CASE_RESET";
export const CASE_SET = "CASE_SET";
export const INDIVIDUAL_CASE_RESET = "INDIVIDUAL_CASE_RESET";
export const INITIAL_CASE_SET = "INITIAL_CASE_SET";
export const HISTORY_SET = "HISTORY_SET";
export const HISTORY_RESET = "HISTORY_RESET";
export const NEW_HISTORY_SET = "NEW_HISTORY_SET";
export const ADD_CALL_HISTORY = "ADD_CALL_HISTORY";
export const REMOVE_CALL_HISTORY = "REMOVE_CALL_HISTORY";
export const DELETE_CALL_HISTORY = "DELETE_CALL_HISTORY";
export const CALLBACK_HISTORY = "CALLBACK_HISTORY";
export const ADD_CALLBACK_HISTORY = "ADD_CALLBACK_HISTORY";
export const REMOVE_CALLBACK_HISTORY = "REMOVE_CALLBACK_HISTORY";
export const DELETE_CALLBACK_HISTORY = "DELETE_CALLBACK_HISTORY";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY";
export const REMOVE_CHAT_HISTORY = "REMOVE_CHAT_HISTORY";
export const DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY";
export const CASE_HISTORY = "CASE_HISTORY";
export const ADD_CASE_HISTORY = "ADD_CASE_HISTORY";
export const REMOVE_CASE_HISTORY = "REMOVE_CASE_HISTORY";
export const DELETE_CASE_HISTORY = "DELETE_CASE_HISTORY";
export const HISTORY_CASE_SET_SUMMARY_ISSUE = "HISTORY_CASE_SET_SUMMARY_ISSUE";
export const HISTORY_CASE_SET_SUGGEST_ISSUE = "HISTORY_CASE_SET_SUGGEST_ISSUE";
export const REROUTE_CASE_DATA = "REROUTE_CASE_DATA"; 

export const CUSTOMER_SET = "CUSTOMER_SET";
export const FEATURE_RESET = "FEATURE_RESET";

export const BUSINESS_ATTRIBUTE_SET = "BUSINESS_ATTRIBUTE_SET";
export const FEATURE_SET = "FEATURE_SET";
export const SET_CASE_DETAILS = "SET_CASE_DETAILS";

export const SESSION_SET = "SESSION_SET";
export const WEBRTC_SET = "WEBRTC_SET";
export const SET_CCP_STATE = "SET_CCP_STATE";

export const WEBRTC_ERROR = "WEBRTC_ERROR";
export const RESET_WEBRTC_ERROR = "RESET_WEBRTC_ERROR";
export const SESSION_ERROR = "SESSION_ERROR";
export const RESET_SESSION_ERROR = "RESET_SESSION_ERROR";
export const WEBRTC_RESET = "WEBRTC_RESET";
export const SET_WEBRTC_SESSION = "SET_WEBRTC_SESSION";
export const RESET_UNREAD_MESSAGE_COUNT = "RESET_UNREAD_MESSAGE_COUNT";
export const ACTIVE_CHAT_UPDATE = "ACTIVE_CHAT_UPDATE";
export const SET_TRANSFER_END_POINTS_UPDATE = "SET_TRANSFER_END_POINTS_UPDATE";
export const UPDATE_CHAT_TRANSCRIPT = "UPDATE_CHAT_TRANSCRIPT";
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const SET_VPN_STATUS = "SET_VPN_STATUS";
export const SET_CALL_AGENT_STATUS = "SET_CALL_AGENT_STATUS";
export const SET_CHAT_AGENT_STATUS = "SET_CHAT_AGENT_STATUS";
export const SET_CASE_AGENT_STATUS = "SET_CASE_AGENT_STATUS";
export const UPDATE_MEDIA_DEVICES = "UPDATE_MEDIA_DEVICES";
export const UPDATE_SELECTED_MEDIA_DEVICE = "UPDATE_SELECTED_MEDIA_DEVICE";
export const UPDATE_RING_ON_DIFFERENT_DEVICES =
  "UPDATE_RING_ON_DIFFERENT_DEVICES";
export const UPDATE_VOICE_NETWORK_STATE = "UPDATE_VOICE_NETWORK_STATE";
export const UPDATE_ASSINGED_QUEUE = "UPDATE_ASSINGED_QUEUE";
export const SET_SELECTED_ATTACHMENT_UPDATE = "SET_SELECTED_ATTACHMENT_UPDATE";
export const SET_UPLOADING_ATTACHMENT_UPDATE =
  "SET_UPLOADING_ATTACHMENT_UPDATE";
export const UPDATE_COOKIE = "UPDATE_COOKIE";
export const INITIATE_DISCONNECT = "INITIATE_DISCONNECT";
export const INITIAL_DETAILS_SET = "INITIAL_DETAILS_SET";
export const INITIATE_LOGIN_SESSION = "INITIATE_LOGIN_SESSION";
export const INITIATE_LOGOUT = "INITIATE_LOGOUT";
export const INITIATE_NAVIGATOR = "INITIATE_NAVIGATOR";
export const SET_CURRENT_SESSION_TAB = "SET_CURRENT_SESSION_TAB";
export const SET_SESSION_CONNECTED = "SET_SESSION_CONNECTED";
export const SET_SESSION_DISCONNECT = "SET_SESSION_DISCONNECT";
export const SET_SESSION_RECONNECT = "SET_SESSION_RECONNECT";
export const RESET_SESSION = "RESET_SESSION";
export const VOICE_UTILIZATION_AVAILABLE = "VOICE_UTILIZATION_AVAILABLE";
export const WEBRTC_SESSION_WINDOW = "WEBRTC_SESSION_WINDOW";
export const SET_WEBRTC_TOKEN = "SET_WEBRTC_TOKEN";
