import React, { useState } from 'react';
import { ProgressCircle, Flex, Button } from '@adobe/react-spectrum';
import './style.css';

export const Toast = ({
    message,
    open = true,
    closable = false,
    showProgressCircle = false,
    variant = 'negative',
    duration = null,
    onClose = null,
    action = false,
    actionButtonLabel = 'ok',
    actionCallback = null,
}:any) => {
    const [isopen, setIsopen] = useState(open);
    if (duration) {
        setTimeout(() => {
            setIsopen(false);
            if (onClose)
                onClose();
        }, duration);
    }
    return (
        <>
            {isopen && (
                <>
                    <div className={`spectrum-Toast spectrum-Toast--${variant}`}>
                        {variant === 'positive' ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" role="img" fill="currentColor" width="24" height="24" aria-hidden="false" aria-label="Success">
                            <path d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2Zm10.666 9.08L16.018 27.341a1.208 1.208 0 0 1-.875.461c-.024.002-.05.002-.073.002a1.2 1.2 0 0 1-.85-.351l-7.784-7.795a1.2 1.2 0 0 1 0-1.698l1.326-1.325a1.201 1.201 0 0 1 1.695 0l5.346 5.347L25.314 8.473A1.203 1.203 0 0 1 27 8.263l1.455 1.133a1.205 1.205 0 0 1 .211 1.684Z"></path>
                        </svg>) : <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="14"
                            viewBox="0 0 14 14"
                            width="14"
                        >
                            <rect
                                id="ToDelete"
                                fill="#ff13dc"
                                opacity="0"
                                width="14"
                                height="14"
                            />
                            <path d="M6.66089,1.00256.15556,12.64356a.38889.38889,0,0,0,.33911.57866H13.50533a.3889.3889,0,0,0,.33912-.57866L7.33911,1.00256a.38889.38889,0,0,0-.67822,0ZM7.77778,11.47222a.19445.19445,0,0,1-.19445.19445H6.41667a.19445.19445,0,0,1-.19445-.19445V10.30556a.19445.19445,0,0,1,.19445-.19445H7.58333a.19445.19445,0,0,1,.19445.19445Zm0-2.33333a.19444.19444,0,0,1-.19445.19444H6.41667a.19444.19444,0,0,1-.19445-.19444V4.47222a.19444.19444,0,0,1,.19445-.19444H7.58333a.19444.19444,0,0,1,.19445.19444Z" />
                        </svg>
                        }

                        <div className="spectrum-Toast-body">
                            <div className="spectrum-Toast-content">
                                <Flex direction={'row'}>
                                <span className="progress-Circle">
                                    {message}
                                    {showProgressCircle && (
                                        <ProgressCircle
                                            isIndeterminate={true}
                                            size="S"
                                            variant="primary"
                                            aria-label="progress..."
                                        />
                                    )}
                                </span>
                                {action && (
                                    <Button
                                        variant="secondary" style="outline"
                                        UNSAFE_className='text-White'
                                        onPress={actionCallback}
                                    >
                                        {actionButtonLabel}
                                    </Button>
                                    )}
                                </Flex>
                            </div>
                        </div>
                        {closable && (
                            <div className="spectrum-Toast-buttons">
                                <button
                                    className="spectrum-ClearButton spectrum-ClearButton--overBackground spectrum-ClearButton--sizeM"
                                    onClick={() => { setIsopen(false); onClose(); }}
                                >
                                    <div className="spectrum-ClearButton-fill">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="white"
                                        >
                                            <g id="CrossSize100">
                                                <rect
                                                    id="Frame"
                                                    width="8"
                                                    height="8"
                                                    fill="red"
                                                    opacity="0"
                                                />
                                                <path d="M5.2381,4,7.69434,1.544A.87543.87543,0,0,0,6.45654.30566L4.00012,2.76208,1.54346.30566A.87526.87526,0,0,0,.30615,1.544L2.76221,4,.30615,6.45605A.87526.87526,0,0,0,1.54346,7.69434L4.00012,5.23792,6.45654,7.69434a.87543.87543,0,1,0,1.2378-1.23829Z" />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
