import React from 'react';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import LoginPageContent from './LoginForm';
import './index.css';
const Login = () => {
        return (
            <div className="LoginPage">
                <HeaderComponent />
                <LoginPageContent />
                <FooterComponent />
            </div>
        );
}
export default Login;
