import React, { useState, useEffect } from "react";
import {
  Avatar,
  Flex,
  ProgressCircle,
  StatusLight,
  Text,
  View,
} from "@adobe/react-spectrum";
import { CallOutwardIcon, OnCallIcon, CallInwardIcon } from "../common/Icons";
import { AgentStatus } from "../../types/agent.type";
import "./style.css";
//@ts-ignore
import EyeIcon from "../../assets/eye-icon.svg?react";
import { BeingMonitoredIcon } from "../common/Icons";

interface SupervisorSidebarProps {
  agentList: AgentStatus[];
  selectedAgent: string;
  setSelectedAgent: (agent: string) => void;
  isFetching: boolean;
}

const SupervisorSidebar: React.FC<SupervisorSidebarProps> = ({
  agentList,
  setSelectedAgent,
  selectedAgent,
  isFetching,
}) => {
  const [callDurations, setCallDurations] = useState<{
    [agentId: string]: string;
  }>({});

  const getSelectedAgent = (ldap: string) => {
    setSelectedAgent(ldap);
  };

  const getCallIcon = (contactType: string, contactMonitored: boolean) => {
    if (contactType === "OUTBOUND" && !contactMonitored) {
      return <CallOutwardIcon />;
    } else if (contactType === "INBOUND" && !contactMonitored) {
      return <CallInwardIcon />;
    } else if (contactType === "MONITOR" && !contactMonitored) {
      return <BeingMonitoredIcon />;
    } else if (contactMonitored) {
      return <EyeIcon />;
    }
    return;
  };

  const getStatusDetails = (
    status: String,
    type: String,
    agentCurrentContacts: []
  ) => {
    let agentStatus: String;
    let statusLight: String;

    if (agentCurrentContacts.length === 0) {
      switch (status) {
        case "On Queue":
          agentStatus = "On queue";
          statusLight = "info";
          break;
        case "Available":
          agentStatus = "Not ready";
          statusLight = "notice";
          break;
        case "Offline":
          agentStatus = "Log-off";
          statusLight = "neutral";
          break;
        default:
          agentStatus = status;
          statusLight = "notice";
          break;
      }
    } else {
      agentStatus = "On call";
      statusLight = "positive";
    }

    if (type === "agentStatus") {
      return agentStatus;
    }
    return statusLight;
  };

  const formatTimeForCall = (time: string) => {
    // Convert the input time (Unix timestamp) to milliseconds
    const callTime = parseInt(time) * 1000;
    const currentTime = Date.now();
    const elapsedTime = currentTime - callTime;
    const elapsedSeconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(elapsedSeconds / 60);
    const seconds = elapsedSeconds % 60;

    if (minutes === 0 && seconds === 0) {
      return "";
    }

    const formattedTime = `${String(minutes).padStart(1, "0")}m : ${String(
      seconds
    ).padStart(2, "0")}s`;
    return formattedTime;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCallDurations((prevDurations) => {
        const newDurations = { ...prevDurations };
        agentList.forEach((agent: any) => {
          if (agent?.agent_current_contacts.length > 0) {
            const contactConnectedTimestamp =
              agent?.agent_current_contacts[0]?.contact_connected_timestamp;
            if (contactConnectedTimestamp) {
              newDurations[agent.agent_id] = formatTimeForCall(
                contactConnectedTimestamp
              );
            }
          }
        });

        return newDurations;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [agentList]);

  return (
    <View
      borderTopColor={"gray-200"}
      borderTopWidth={"thick"}
      width={"36%"}
      height={"100%"}
    >
      {agentList?.length > 0 ? (
        agentList.map((agent: any) => (
          <div
            key={agent.agent_id}
            onClick={() => getSelectedAgent(agent.agent_ldap)}
          >
            <Flex
              flexGrow={1}
              justifyContent={"space-between"}
              direction={"column"}
              UNSAFE_className={`call-monitor-sidebar-list-item ${
                agent.agent_ldap === selectedAgent
                  ? "call-monitor-sidebar-list-active"
                  : ""
              }`}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                flexGrow={1}
              >
                <Flex flex={2}>
                  <Flex>
                    <Avatar
                      src="https://i.imgur.com/kJOwAdv.png"
                      alt="avatar with custom size"
                      size={25}
                    />
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text
                      marginX={10}
                      UNSAFE_className={"supervisor-agent-name"}
                    >
                      {agent.agent_name}
                    </Text>
                  </Flex>
                </Flex>
                <Flex alignItems={"center"} flex={1}>
                  <StatusLight
                    UNSAFE_className="call-monitor-status-light"
                    variant={
                      getStatusDetails(
                        agent.agent_current_status_name,
                        "statusLight",
                        agent.agent_current_contacts
                      ) as
                        | "chartreuse"
                        | "fuchsia"
                        | "indigo"
                        | "magenta"
                        | "purple"
                        | "yellow"
                        | "info"
                        | "neutral"
                        | "notice"
                        | "negative"
                        | "positive"
                        | "celery"
                        | "seafoam"
                    }
                  />
                  <Text
                    marginX={10}
                    UNSAFE_className={
                      agent.agent_current_status_name === "Offline"
                        ? "call-monitor-agent-status log-off"
                        : "call-monitor-agent-status"
                    }
                  >
                    {getStatusDetails(
                      agent.agent_current_status_name,
                      "agentStatus",
                      agent.agent_current_contacts
                    )}
                    <span className="on-call-direction-icon">
                      {getCallIcon(
                        agent.agent_current_contacts[0]?.contact_type,
                        agent.agent_current_contacts[0]?.contact_monitored
                      )}
                    </span>
                  </Text>
                </Flex>
                <Flex marginStart={25} justifyContent={"center"} flex={1}>
                  {agent?.agent_current_contacts[0]?.contact_id &&
                    callDurations[agent.agent_id] !== "" && (
                      <span className="call-monitor-time">
                        <span>
                          {agent.agent_current_contacts.length > 0 && (
                            <Flex width={"100%"}>
                              <Text UNSAFE_className="call-monitor-call-icon">
                                <OnCallIcon />
                              </Text>
                              <Text marginX={4}>
                                {callDurations[agent.agent_id]}
                              </Text>
                            </Flex>
                          )}
                        </span>
                      </span>
                    )}
                </Flex>
              </Flex>
            </Flex>
          </div>
        ))
      ) : !isFetching ? (
        <Flex
          height={"200px"}
          alignItems={"center"}
          direction={"column"}
          justifyContent={"center"}
        >
          <Text>No data found</Text>
        </Flex>
      ) : isFetching ? (
        <Flex
          height={"320px"}
          alignItems={"center"}
          direction={"column"}
          justifyContent={"center"}
        >
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      ) : null}
    </View>
  );
};

export default SupervisorSidebar;
