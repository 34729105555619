import {
  INTERNAL_SESSION_START,
  INTERNAL_CALL_RELEASE,
  INTERNAL_NETWORK_DISCONNECT,
  INTERNAL_CALLBACK_RELEASE,
  INTERNAL_CHAT_RELEASE,
  INTERNAL_CASE_RELEASE,
} from "../constants/events";
// import { emitter, SessionEvent } from '../emitter';
// import { Call } from '../reducers/call';
// import {
//     addCallbackToHistoryAction,
//     addCallToHistoryAction,
//      initializeHistory,
//     addChatToHistoryAction,
//     addCaseToHistoryAction,
// } from '../actions/history';
// import { store } from '../store';
import storeRTK from "../store/storeRTK";
import {
  addCallbackToHistoryAction,
  addCallToHistoryAction,
  initializeHistory,
  addChatToHistoryAction,
  addCaseToHistoryAction,
} from "../connector/history";
import Emitter from "../emitter";

function historyHandler(event: any) {
  if (event) {
    storeRTK.dispatch(addCallToHistoryAction(event));
  }
}
function historyHandlerForCallback(event: any) {
  if (event.body) {
    storeRTK.dispatch(addCallbackToHistoryAction(event.body));
  }
}
function historyHandlerForChat(event: any) {
  if (event.body) {
    storeRTK.dispatch(addChatToHistoryAction(event.body));
  }
}

export function historyInitialiseHandler() {
  storeRTK.dispatch(initializeHistory(null));
}
function historyHandlerOnNetworkDisconnect() {
  const state = storeRTK.getState();
  if (state.call.id && state.call.self?.state !== "RELEASED") {
    storeRTK.dispatch(addCallToHistoryAction(state.call));
  }
  if (
    state.callback.id &&
    state.callback.self?.callback?.state !== "RELEASED"
  ) {
    storeRTK.dispatch(addCallbackToHistoryAction(state.callback));
  }
}

// For case
function historyHandlerForCase(event: any) {
  if (event.body) {
    storeRTK.dispatch(addCaseToHistoryAction(event.body));
  }
}
 export default function registerHistoryHandlers() {
  Emitter.on(INTERNAL_CALL_RELEASE, historyHandler);
  Emitter.on(INTERNAL_SESSION_START, historyInitialiseHandler);
  Emitter.on(INTERNAL_NETWORK_DISCONNECT, historyHandlerOnNetworkDisconnect);
  Emitter.on(INTERNAL_CALLBACK_RELEASE, historyHandlerForCallback);
  Emitter.on(INTERNAL_CHAT_RELEASE, historyHandlerForChat);
  Emitter.on(INTERNAL_CASE_RELEASE, historyHandlerForCase);
 }
