import { Flex, Heading, Text } from "@adobe/react-spectrum";
import { PhoneDisabledIcon } from "../../common/Icons";

interface IActiveCallContainerProps {
  title: string;
  description: string;
}

const ActiveCallContainer = (props: IActiveCallContainerProps) => {
  const { title, description } = props;
  return (
    <Flex
      height={"auto"}
      alignItems={"center"}
      direction={"column"}
      justifyContent={"center"}
      width={"100%"}
      marginY={"size-200"}
      UNSAFE_style={
        title === "Start a new call or wait for the call"
          ? { backgroundColor: "#fff" }
          : {}
      }
      UNSAFE_className="no-active-call-container"
    >
      <PhoneDisabledIcon />
      <Heading UNSAFE_className="no-active-call_Dialer">{title}</Heading>
      <Text UNSAFE_className="no-active-call_Dialer-desc">{description}</Text>
    </Flex>
  );
};

export default ActiveCallContainer;
