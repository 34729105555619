import { View, Flex, Text, Avatar } from "@adobe/react-spectrum";
import "./index.css";
import { formatTime, getCallIcon } from "../../../utils";

interface ICallHistorySidebarProps {
  activeCallerId: string;
  getActiveCallId: (id: string) => void;
  callList: any;
}

const CallHistorySidebar = (props: ICallHistorySidebarProps) => {
  const { activeCallerId, getActiveCallId, callList } = props;

  return (
    <View
      UNSAFE_className="call-history-sidebar"
      borderTopColor={"gray-200"}
      borderTopWidth={"thick"}
      height={"100%"}
      width={"30%"}
    >
      {callList?.length > 0 &&
        callList.map((call: any) => {
          const { connectedTime, endTime } = call?.self; // Ensure these are numbers
          const { formattedConnectedTime, formattedDifference } = formatTime(
            connectedTime,
            endTime
          );

          return (
            <div
              key={call.id}
              onClick={() => getActiveCallId(call.id)}
              className="call-history-sidebar-list"
            >
              <Flex
                flexGrow={1}
                justifyContent={"space-between"}
                direction={"column"}
                UNSAFE_className={`call-history-sidebar-list-item ${
                  call.id === activeCallerId
                    ? " call-history-sidebar-list-active"
                    : ""
                }`}
              >
                <Flex>
                  <Flex>
                    <View>
                      <Avatar
                        src="https://i.imgur.com/kJOwAdv.png"
                        alt="avatar with custom size"
                        size={25}
                      />
                    </View>
                  </Flex>
                  <Flex
                    width={"100%"}
                    alignItems={"end"}
                    justifyContent={"space-between"}
                  >
                    <Flex
                      direction="column"
                      alignItems="start"
                      UNSAFE_className="call-history-list"
                    >
                      <Text UNSAFE_className="call-history-title">
                        {call.customer.attributes.Customer_ANI || "-"}
                      </Text>
                      <Text
                        UNSAFE_style={{ textAlign: "start", color: "#D7373F" }}
                      >
                        <Flex
                          UNSAFE_className="call-history-subtitle"
                          alignItems={"center"}
                          justifyContent={"center"}
                          marginTop={5}
                        >
                          <img
                            src={getCallIcon(call)}
                            className="call-history-icon"
                            alt="Missed call icon"
                          />
                          <Text
                            marginX={5}
                            UNSAFE_style={{ whiteSpace: "nowrap" }}
                          >
                            {formattedConnectedTime}
                          </Text>
                        </Flex>
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text
                        UNSAFE_style={{
                          textAlign: "start",
                          fontSize: "0.65rem",
                        }}
                      >
                        {formattedDifference}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          );
        })}
    </View>
  );
};

export default CallHistorySidebar;
