import {
    WEBRTC_SET,
    WEBRTC_ERROR,
    RESET_WEBRTC_ERROR,
    SET_WEBRTC_SESSION,
    WEBRTC_RESET,
    SET_CCP_STATE,
    UPDATE_MEDIA_DEVICES,
    UPDATE_SELECTED_MEDIA_DEVICE,
    UPDATE_RING_ON_DIFFERENT_DEVICES,
    UPDATE_VOICE_NETWORK_STATE,
    SET_WEBRTC_TOKEN,
} from '../constants/action-types';

/**
 *
 * @param {*} state
 */
export function setWebRTC(state:any) {
    return {
        type: WEBRTC_SET,
        webrtc: state,
    };
}
export function resetWebRTCSession() {
    return {
        type: WEBRTC_RESET
    };
}
export function setWebRTCError(error:any):any {
    return {
        type: WEBRTC_ERROR,
        error,
    }
}
export function resetWebRTCError() {
    return {
        type: RESET_WEBRTC_ERROR,
    }
}
export function setWebRTCSession(session:any) {
    return {
        type: SET_WEBRTC_SESSION,
        session,
    }
}
export function setCCPState(ccpSessionState:any) {
    return {
        type: SET_CCP_STATE,
        ccpSessionState
    }
}
export function updateMediaDevicesAction(mediaDevices:any) {
    return {
        type: UPDATE_MEDIA_DEVICES,
        mediaDevices
    }
}
export function updateMediaDeviceAction(deviceId:any) {
    return {
        type: UPDATE_SELECTED_MEDIA_DEVICE,
        deviceId
    }
}

export function updateRingOnDifferentDevice(ringOnDifferentDevice:any) {
    return {
        type: UPDATE_RING_ON_DIFFERENT_DEVICES,
        ringOnDifferentDevice
    }
}

export function updateVoiceNewtworkState(isVoiceNetworkAvailable:any) {
    return {
        type: UPDATE_VOICE_NETWORK_STATE,
        isVoiceNetworkAvailable
    }
}
export function setRtcToken(payload:any) {
    return {
        type: SET_WEBRTC_TOKEN,
        payload
    }
}