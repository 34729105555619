import { useEffect } from "react"
import { ProgressCircle } from "@adobe/react-spectrum"
import { INIT_CCP } from "../../constants/events";
import { getProfile } from "../../handlers/session.ts";
import { session } from "../../handlers/session.ts";
import { STATE_LOGIN } from "../../amazon-connect/constants/index.ts";
import Emitter from "../../emitter/index.ts";
import storeRTK from "../../store/storeRTK.ts";
import { setLoader } from "../../actions/common.ts";

const LoginCallback = () => {
    useEffect(() => {
            getProfile().then((response: any) => {
              Emitter.emit(INIT_CCP, response);
              session.onloggedIn({
                type: "notif",
                chan: STATE_LOGIN,
                body: response,
                success: true,
              });
              storeRTK.dispatch(setLoader(false));
              session.startCcpConnection();
              return (location.href = "/#")
            });
    }, [])

    return (
        <div
            style={{
                paddingTop: "15%",
                paddingLeft: "45%",
                position: "absolute",
                zIndex: "10",
            }}
        >
            <ProgressCircle aria-label="Loading…" isIndeterminate size="L" />
        </div>
    )
}

export default LoginCallback
