import registerSessionEvents from './session';
import registerHistoryEvents from './history';
import registerCaseHandlerEvents from './case';

/**
 * Register default events
 */
export default function registerEventHandlers() {
    registerSessionEvents();
    registerHistoryEvents();
    registerCaseHandlerEvents();
}

