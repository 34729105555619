import {
  Heading,
  View,
  Text,
  Flex,
  ActionButton,
  Button,
  Grid,
  TextField,
  Divider,
  Avatar,
} from "@adobe/react-spectrum";
import {
  AddCallIcon,
  BackspaceIcon,
  CallTransferIcon,
  ForwardPhoneCallIcon,
  MergeCallIcon,
  MuteIcon,
  UnMmuteIcon,
} from "../common/Icons";
import { useState } from "react";
import { DialogTrigger, Dialog } from "@adobe/react-spectrum";
import Emitter from "../../emitter";
import { useSelector } from "react-redux";
import { RootState } from "../../store/storeConfig";
import { API_CALL_MUTE, API_CALL_SEND_DTMF } from "../../constants/constants";
import CallTransferDialog from "./CallTransferDialog";
import "./style.css";

const CallTranscript = () => {
  const [dialerPopup, setDialerPopup] = useState(false);
  const [number, setNumber] = useState("");
  let call = useSelector((state: RootState) => state.call);
  const [transferDialogState, setTransferDialogState] = useState(false);
  const handleDialerPopup = () => {
    setDialerPopup(!dialerPopup);
  };

  const muteCallHandler = () => {
    Emitter.emit(API_CALL_MUTE, {
      body: { mute: !call?.self?.muted },
    });
  };

  const renderDialer = (num: number) => {
    let displayNum: any;
    switch (num) {
      case 10:
        displayNum = "*";
        break;
      case 11:
        displayNum = "0";
        break;
      case 12:
        displayNum = "#";
        break;
      case 13:
        displayNum = "+";
        break;
      case 14:
        displayNum = "x";
        break;
      case 15:
        displayNum = <BackspaceIcon />;
        break;
      default:
        displayNum = num;
    }

    const handleBtnpress = (num: number) => {
      if (num === 15) {
        setNumber(number.slice(0, -1));
      } else if (num === 14) {
        setNumber("");
      } else {
        Emitter.emit(API_CALL_SEND_DTMF, {
          body: {
            participantId: call.self?.id,
            conversationId: call.id,
            digits: number + displayNum,
          },
        });
        setNumber(number + displayNum);
      }
    };

    return (
      <Button
        variant="primary"
        key={num}
        onPress={() => handleBtnpress(num)}
        UNSAFE_className="dialer-btn"
      >
        {displayNum}
      </Button>
    );
  };

  return (
    <>
      <View
        borderWidth="thin"
        borderColor={!call.isMonitoringCall ? "gray-200" : "static-white"}
        height="100%"
        marginTop={5}
      >
        {/* *************** temporay comment out the below code, will uncomment when use  *************** */}
        {/* <View
          backgroundColor="gray-200"
          padding="8px"
          borderBottomWidth="thin"
          borderColor="gray-200"
          borderBottomColor="gray-400"
        >
          <Heading level={2} marginX={20}>
            Live Transcript
          </Heading>
        </View> */}
        {/* <View height="482px" UNSAFE_className="call-transcript-section" padding="20px">
          <View
            height="92px"
            width="401px"
            UNSAFE_className="call-chat-transcript-white"
            backgroundColor="gray-200"
          >
            <View>
              <Flex UNSAFE_className="call-chat-transcript-header">
                <Avatar
                  src="https://i.imgur.com/kJOwAdv.png"
                  alt="avatar with custom size"
                  size={26}
                  marginEnd={8}
                />
                <Text UNSAFE_className="call-chat-transcript-name">
                  Sarthak Acharya
                </Text>
              </Flex>
            </View>
            <Divider size="M" />
            <View
              backgroundColor="static-white"
              UNSAFE_className="call-transcript-chat-text"
            >
              <Text>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Inventore eveniet aut iusto? Natus, doloremque quisquam.
              </Text>
            </View>
          </View>
          <View
            height="72px"
            width="401px"
            UNSAFE_className="call-chat-transcript-blue"
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
            eveniet aut iusto? Natus, doloremque quisquam.
          </View>
          <View
            height="92px"
            width="401px"
            UNSAFE_className="call-chat-transcript-white"
            backgroundColor="gray-200"
          >
            <View>
              <Flex UNSAFE_className="call-chat-transcript-header">
                <Avatar
                  src="https://i.imgur.com/kJOwAdv.png"
                  alt="avatar with custom size"
                  size={26}
                  marginEnd={8}
                />
                <Text UNSAFE_className="call-chat-transcript-name">
                  Sarthak Acharya
                </Text>
              </Flex>
            </View>
            <Divider size="M" />
            <View
              backgroundColor="static-white"
              UNSAFE_className="call-transcript-chat-text"
            >
              <Text>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Inventore eveniet aut iusto? Natus, doloremque quisquam.
              </Text>
            </View>
          </View>
          <View
            height="72px"
            width="401px"
            UNSAFE_className="call-chat-transcript-blue"
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore
            eveniet aut iusto? Natus, doloremque quisquam.
          </View>
        </View> */}
        {!call.isMonitoringCall && (
          <View paddingTop="8px" UNSAFE_className="call-transcript-options">
            <Flex
              direction="row"
              justifyContent="space-between"
              height={"3.8rem"}
            >
              <Flex direction={"column"} alignItems="center">
                <DialogTrigger type="popover">
                  <ActionButton
                    onPress={handleDialerPopup}
                    UNSAFE_className="iconButton"
                  >
                    <CallTransferIcon />
                  </ActionButton>
                  <Dialog>
                    <Flex
                      marginTop={"32px"}
                      direction={"column"}
                      marginStart={"50px"}
                      justifyContent={"center"}
                    >
                      <TextField
                        value={number}
                        UNSAFE_className="dialer-textfield"
                        isQuiet
                        onChange={(e) => setNumber(e)}
                      />
                      <Grid
                        areas={[
                          "1 2 3",
                          "4 5 6",
                          "7 8 9",
                          "* 0 #",
                          "+ x backspace",
                        ]}
                        columns={["1fr", "1fr", "1fr"]}
                        rows={["1fr", "1fr", "1fr", "1fr", "ifr"]}
                        gap="size-100"
                        marginStart={"30px"}
                      >
                        {Array.from({ length: 15 }, (_, i) => i + 1).map(
                          (num) => renderDialer(num)
                        )}
                      </Grid>
                      <Button
                        width="310px"
                        height="48px"
                        variant="cta"
                        // onPress={makeCall}
                        aria-label="call-dial-button"
                        UNSAFE_className="call-dial-button"
                      >
                        Call
                      </Button>
                    </Flex>
                  </Dialog>
                </DialogTrigger>
                <Text UNSAFE_className="call-inProgress-font">Dialpad</Text>
              </Flex>
              <Flex direction={"column"} alignItems="center">
                <button
                  type="button"
                  className={
                    call?.self?.muted ? "iconButton active " : "iconButton"
                  }
                  onClick={muteCallHandler}
                >
                  {call?.self?.muted ? <UnMmuteIcon /> : <MuteIcon />}
                </button>
                <Text UNSAFE_className="call-inProgress-font">Mute</Text>
              </Flex>
              <Flex direction={"column"} alignItems="center">
                <button
                  disabled={true}
                  type="button"
                  className="iconButton"
                  style={{ cursor: "not-allowed" }}
                >
                  <AddCallIcon />
                </button>
                <Text UNSAFE_className="call-inProgress-font">Add Call</Text>
              </Flex>
              <Flex direction={"column"} alignItems="center">
                <button
                  disabled={true}
                  type="button"
                  className="iconButton"
                  style={{ cursor: "not-allowed" }}
                >
                  <MergeCallIcon />
                </button>
                <Text UNSAFE_className="call-inProgress-font">Merge</Text>
              </Flex>
              <Flex direction={"column"} alignItems="center">
                <button
                  type="button"
                  className="iconButton"
                  onClick={() => {
                    setTransferDialogState(true);
                  }}
                >
                  <ForwardPhoneCallIcon />
                </button>
                <Text UNSAFE_className="call-inProgress-font">Transfer</Text>
              </Flex>
            </Flex>
          </View>
        )}
        {transferDialogState && (
          <CallTransferDialog
            isOpen={transferDialogState}
            onClose={() => setTransferDialogState(false)}
          />
        )}
      </View>
    </>
  );
};

export default CallTranscript;
