import { setWebRTCError, resetWebRTCError,setWebRTCSession, resetWebRTCSession, setCCPState, updateMediaDevicesAction, updateMediaDeviceAction, updateRingOnDifferentDevice, updateVoiceNewtworkState } from '../actions/webrtc';
import {
    WEBRTC_ERROR,
    RESET_WEBRTC_ERROR,
    WEBRTC_SET_SESSION,
    WEBRTC_RESET_SESSION,
    CCP_STATE,
    UPDATE_MEDIA_DEVICES_LIST,
    STATE_UPDATE_RING_MEDIA_DEVICE,
    STATE_UPDATE_ROD_DEVICE,
    VOICE_NETWORK_STATE,
    API_CALL_HANGUP,
    API_CALL_REJECT,
    CALL_STATES
} from '../constants/constants';
import Emitter from '../emitter';
// import Emitter from '../emitter';
import store from '../store';
import storeRTK from '../store/storeRTK';

/**
 * Handling the call state update functonality
 * @param {*} message
 */
export function setError(message:any) {
    store.dispatch(setWebRTCError(message.body?.reason));
}
export function resetError() {
    store.dispatch(resetWebRTCError());
}
export function webRTCSetSession(message:any) {
    store.dispatch(setWebRTCSession(message.body?.session));
}
export function webRTCResetSession() {
    store.dispatch(resetWebRTCSession());
}
export function updateCcpState(message:any) {
    storeRTK.dispatch(setCCPState(message?.status));
}
export function updateMediaDevices(message:any) {
    store.dispatch(updateMediaDevicesAction(message.body?.mediaDevices));
}
export function updateSelectedMediaDevice(message:any) {
    if(message.success) {
        store.dispatch(updateMediaDeviceAction(message.body?.deviceId));
    } else {
        console.log("Error in updating selected Media Device", message.body.error);
    }
}
export function updateDifferentDevicesCheck(message:any) {
    store.dispatch(updateRingOnDifferentDevice(message.body?.ringOnDifferentDevice));
}

export function setVoiceNewtworkState(message:any) {
    store.dispatch(updateVoiceNewtworkState(message.body?.isVoiceNetworkAvailable));
}
/**
 * Register default Events for Call
 */
export default function registerWebRTCHandlerEvents() {
    // Emitter.on(WEBRTC_ERROR, setError);
    // Emitter.on(RESET_WEBRTC_ERROR, resetError);
    // Emitter.on(WEBRTC_SET_SESSION, webRTCSetSession);
    // Emitter.on(WEBRTC_RESET_SESSION, webRTCResetSession);
     Emitter.on(CCP_STATE, updateCcpState);
    // Emitter.on(UPDATE_MEDIA_DEVICES_LIST, updateMediaDevices);
    // Emitter.on(STATE_UPDATE_RING_MEDIA_DEVICE, updateSelectedMediaDevice);
    // Emitter.on(STATE_UPDATE_ROD_DEVICE, updateDifferentDevicesCheck);
    // Emitter.on(VOICE_NETWORK_STATE, setVoiceNewtworkState);
}
