import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./components/Login";
import LoginCallback from "./components/Login/LoginCallback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "./store";

function mapStateToProps(state: RootState) {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
  };
}

const App = () => {
  return (
    <Provider
      theme={defaultTheme}
      colorScheme="light"
      height={"100vh"}
      width={"100vw"}
    >
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login/redirect" element={<LoginCallback />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default connect(mapStateToProps)(App);
