import {
  ACTIVE_CHAT_UPDATE,
  CHAT_MESSAGE,
  LOST_MESSAGE,
  CHAT_RESET,
  CHAT_SET,
  INDIVIDUAL_CHAT_RESET,
  INITIAL_CHAT_SET,
  LOGOUT,
  RESET_UNREAD_MESSAGE_COUNT,
  UPDATE_CHAT_TRANSCRIPT,
  SET_TRANSFER_END_POINTS_UPDATE,
  SET_SELECTED_ATTACHMENT_UPDATE,
  SET_UPLOADING_ATTACHMENT_UPDATE,
  STORE_LOGIN,
  RESET_STATE,
} from "../constants/action-types";
import { ChatMessage, IndividualChat } from "../types/chat.type";
import { mergeUnique } from "../utils";

const initialState: any = { chats: [], activeChatID: "" };
export default (state = initialState, action: any) => {
  switch (action.type) {
    case CHAT_SET: {
      let chatId = action.chat.id;

      let existingIndex = state.chats.findIndex(
        (chat: IndividualChat) => chatId === chat.id
      );
      let chats = [...state.chats];
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          ...action.chat,
        };
      } else {
        chats.push(action.chat);
      }
      return {
        ...state,
        chats,
      };
    }
    case INITIAL_CHAT_SET: {
      return {
        ...state,
        ...action.chat,
      };
    }

    case CHAT_MESSAGE: {
      let chatId = action.message.webChatConversation.id;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex(
        (chat: IndividualChat) => chatId === chat.id
      );
      if (existingIndex !== -1) {
        const unreadMsgCnt =
          state.activeChatID !== chatId
            ? (chats[existingIndex].unreadMessages ?? 0) + 1
            : 0;
        const filteredChats = chats[existingIndex]?.messages?.filter(
          (chat: ChatMessage) => {
            return chat.id !== action.message.id;
          }
        );
        chats[existingIndex] = {
          ...chats[existingIndex],
          messages: [...(filteredChats || []), action.message],
          unreadMessages: unreadMsgCnt,
          scrollUpdate: false,
        };
      }

      return {
        ...state,
        chats: chats,
      };
    }

    case LOST_MESSAGE: {
      const chats = [...state.chats];
      if (action.message.length > 0) {
        const chatId = action.message[0].webChatConversation.id;
        let existingIndex = chats.findIndex((chat) => chatId === chat.id);
        if (existingIndex > -1) {
          const unreadMsgCnt =
            state.activeChatID !== chatId
              ? (chats[existingIndex].unreadMessages ?? 0) + 1
              : 0;
          chats[existingIndex] = {
            ...chats[existingIndex],
            messages: [...action.message],
            unreadMessages: unreadMsgCnt,
          };
        }
        return {
          ...state,
          chats: chats,
        };
      } else {
        return {
          ...state,
          chats: chats,
        };
      }
    }
    case RESET_UNREAD_MESSAGE_COUNT: {
      let chatId = action.chatID;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex((chat) => chatId === chat.id);
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          unreadMessages: 0,
        };
      }
      return {
        ...state,
        chats: chats,
      };
    }
    case ACTIVE_CHAT_UPDATE: {
      const chatId = action.chatID;
      return {
        ...state,
        activeChatID: chatId,
      };
    }
    case SET_SELECTED_ATTACHMENT_UPDATE: {
      let chatId = action.message.conversationId;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex((chat) => chatId === chat.id);
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          attachment: action.message.attachment,
        };
      }
      return {
        ...state,
        chats: chats,
      };
    }
    case UPDATE_CHAT_TRANSCRIPT: {
      let chatId = action.message.conversationId;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex((chat) => chatId === chat.id);
      let updatedTranscript = [];
      if (chats[existingIndex]?.transcript) {
        updatedTranscript = mergeUnique(
          action.message.transcript,
          chats[existingIndex].transcript,
          "messageId"
        );
      } else {
        updatedTranscript = action.message.transcript;
      }
      console.log(
        "+++++++++++++IN UI REDUCER++++++++++++++",
        updatedTranscript,
        action.message.nextHistoryToken
      );
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          transcript: updatedTranscript,
          nextHistoryToken: action.message.nextHistoryToken,
          scrollUpdate: true,
        };
      }

      return {
        ...state,
        chats: chats,
      };
    }

    case SET_TRANSFER_END_POINTS_UPDATE: {
      let chatId = action.message.conversationId;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex((chat) => chatId === chat.id);
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          allowedTransferEndpoints: [...action.message.transferEndpoints],
        };
      }
      return {
        ...state,
        chats,
      };
    }
    case LOGOUT:
    case CHAT_RESET:
      return { chats: [], activeChatID: "" };
    case INDIVIDUAL_CHAT_RESET: {
      let chatId = action.chat.id;
      let chatCopy = [...state.chats];
      let chats = chatCopy.filter((currentValue) => currentValue.id !== chatId);
      return {
        ...state,
        chats,
      };
    }

    case SET_UPLOADING_ATTACHMENT_UPDATE: {
      let chatId = action.message.conversationId;
      let chats = [...state.chats];
      let existingIndex = chats.findIndex((chat) => chatId === chat.id);
      if (existingIndex > -1) {
        chats[existingIndex] = {
          ...chats[existingIndex],
          uploadingFiles: [...action.message.attachment],
        };
      }
      return {
        ...state,
        chats: chats,
      };
    }
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.chat,
      };
    case RESET_STATE:
      return { chats: [], activeChatID: "" };
    default:
      return state;
  }
};
