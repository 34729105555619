import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "amazon-connect-taskjs";

import {
  CONNECT_STREAMS_DIV_ID,
  CONTACT_STATE_MAP,
  CCP_STATES,
  CCP_STATES_INDEX,
  LOG_PREFIX,
  STOP_SESSION,
} from "../../constants/constants";

import { Call } from "../models/call";
import { subscribeToAgentEvents } from "./agent";
import { subscribeToContactEvents } from "./contact";
import { Voicemail } from "../models/voicemail";
import { beforeUnloadListener } from "../../utils";
import { getAwsRegion, getCCPUrl } from "../../config";
import Emitter from "../../emitter";
import { CCP_STATE } from "../constants";
import { setLoader } from "../../actions/common";
import storeRTK from "../../store/storeRTK";
import { session } from "../../controller/session";
import { INIT_CCP } from "../../constants/events";
const lp = LOG_PREFIX + " [handlers.session.ts]: ";

class AmazonConnectSession {
  agent?: any;
  contact?: any;
  currCCPstate: number = -1;
  call: Call | null = null;
  voicemail: Voicemail | null = null;
  static instance: any;

  constructor() {
    this.initiateCcpSession = this.initiateCcpSession.bind(this);
    this.terminatCcpSession = this.terminatCcpSession.bind(this);
    this.getCall = this.getCall.bind(this);
    this.setCall = this.setCall.bind(this);
    this.getVoicemail = this.getVoicemail.bind(this);
    this.setVoicemail = this.setVoicemail.bind(this);
    this.logoutFromConnect = this.logoutFromConnect.bind(this);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AmazonConnectSession();
    }
    return this.instance;
  }

  async initiateCcpSession() {
    console.log(
      lp +
        "ccp start event called, current ccp state is = " +
        CCP_STATES_INDEX[this.currCCPstate]
    );
    if (this.currCCPstate !== CCP_STATES.CONNECTED) {
      this.updateCCPstateToBg(CCP_STATES.OPEN);
      // initialize the ccp
      try {
        // const containerDiv = document.getElementById(CONNECT_STREAMS_DIV_ID) as HTMLElement;
        const containerDiv = document.createElement("div");
        containerDiv.style.display = "none";
        document.body.appendChild(containerDiv);
        console.log(
          lp + "Container div for initiating ccp connection ",
          containerDiv
        );
        if (
          ![CCP_STATES.CONNECTING, CCP_STATES.CONNECTED].includes(
            this.currCCPstate
          )
        ) {
          this.updateCCPstateToBg(CCP_STATES.CONNECTING);
          // this.terminatCcpSession();
          const agent = storeRTK.getState().agent;
          connect.core.initCCP(containerDiv, {
            ccpUrl: agent?.aicConfig?.ccp_url || getCCPUrl(), // REQUIRED
            loginPopup: false, // optional, defaults to `true`
            loginPopupAutoClose: true, // optional, defaults to `false`
            region:
              (agent.instanceConfig && agent.instanceConfig[0]?.region) ||
              getAwsRegion(), // REQUIRED for `CHAT`, optional otherwise
            softphone: {
              // optional, defaults below apply if not provided
              allowFramedSoftphone: true, // optional, defaults to false
              disableRingtone: true, // optional, defaults to false
              //ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
            },
            chat: {
              disableRingtone: true,
            },
            task: {
              disableRingtone: true,
            },
            ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
            ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
            ccpLoadTimeout: 10000, //optional, defaults to 5000 (ms)
          });

          connect.core.onInitialized(() => {
            console.log(lp + "authorization succeeded!");
            this.updateCCPstateToBg(CCP_STATES.CONNECTED);
          });
          connect.core.onAuthFail(() => {
            console.log(lp + "Error!!!!!: Session expired! Need to login");
            this.updateCCPstateToBg(CCP_STATES.DISCONNECTED);
          });
          connect.core.onAuthorizeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have run out of retries to reload the CCP Iframe"
            );
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.core.onAccessDenied(() => {
            console.log(lp + "Error!!!!!: Access denied");
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.core.onIframeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have run out of retries to reload the CCP Iframe"
            );
            console.log(lp + "Logging out due to expired cookies");
            session.logout();
            setTimeout(() => {
              Emitter.emit(STOP_SESSION);
            }, 0);
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.core.onCTIAuthorizeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have failed CTI API authorization multiple times and we are out of retries"
            );
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.contact(subscribeToContactEvents);
          connect.agent(subscribeToAgentEvents);
        } else {
          console.log(
            lp +
              "ccp start event called, But current ccp state is already in progress = " +
              CCP_STATES_INDEX[this.currCCPstate]
          );
        }
      } catch (error: any) {
        console.log(
          lp + "Error in starting ccp, Error: " + error.message,
          error
        );
        console.log(
          lp +
            "Error in starting ccp, state is " +
            CCP_STATES_INDEX[this.currCCPstate]
        );
        this.updateCCPstateToBg(CCP_STATES.CLOSED);
      }
    } else {
      //Update currCCPState to chrome extension
      this.updateCCPstateToBg(this.currCCPstate);
    }
  }
  terminatCcpSession() {
    //window.removeEventListener('beforeunload', beforeUnloadListener);
    connect.core.terminate();
    this.logoutFromConnect();
  }
  logoutFromConnect() {
    fetch(
      `${
        storeRTK.getState().agent?.aicConfig?.ccp_url ||
        getCCPUrl().replace("ccp-v2", "logout")
      }`,
      {
        credentials: "include",
        mode: "no-cors",
      }
    )
      .then(() => {
        console.log(lp + "Logout successfull from connect");
      })
      .catch((error) =>
        console.log(lp + "Error in loggin out from connect", error)
      );
  }
  updateCCPstateToBg(status: number) {
    Emitter.emit(CCP_STATE, { status: status });
  }
  getCall() {
    return this.call;
  }
  setCall(call: any) {
    //Below ignore duplicate call ended events
    if (call.self.state === CONTACT_STATE_MAP.ENDED && !this.call?.id) return;

    if (call.self.state === CONTACT_STATE_MAP.ENDED) {
      this.call = null;
    } else {
      this.call = call;
    }
  }
  getVoicemail() {
    return this.voicemail;
  }
  setVoicemail(voicemail: any) {
    //Below ignore duplicate call ended events
    if (voicemail.self.state === CONTACT_STATE_MAP.ENDED && !this.voicemail?.id)
      return;

    if (voicemail.self.state === CONTACT_STATE_MAP.ENDED) {
      this.voicemail = null;
    } else {
      this.voicemail = voicemail;
    }
  }

  reloadWindow() {
    window.removeEventListener("beforeunload", beforeUnloadListener);
  }
}
export const connectSession = AmazonConnectSession.getInstance();
// window.onload = () => {
//     if(window.location?.href?.includes('media-perm=true')) {}
//     else {
//         setTimeout(() => {
//             connectSession.initiateCcpSession();
//         }, 1000);
//     }
// }
/**
 * Register default Events for Call
 */
export const registerConnectSessionHandlers = () => {
  Emitter.on(STOP_SESSION, connectSession.terminatCcpSession);
  Emitter.on(INIT_CCP, connectSession.initiateCcpSession);
};
