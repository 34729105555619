import { connectSession } from "./session";
import { Call } from "../models/call";
import { formatAttributes } from "../../utils";
import {
  clearCurrentCallContact,
  disconnectCallContactOnRefreshError,
} from "../api/call";
import { changeAgentStateToAvailable } from "./agent";
import {
  CONTACT_STATUS_MAP,
  CONTACT_EVENT_STATE_MAP,
  CONTACT_STATE_MAP,
  LOG_PREFIX,
  STATE_CALL,
  API_MILO_CHANNEL_TRANSACTION,
} from "../constants";
import Emitter from "../../emitter";
// @ts-ignore
import Player from "../../utils/audio/player";
const lp = LOG_PREFIX + "[handlers.call.ts]: ";
const defaultValue = {
  self: {},
  customer: {},
};

export const callPlayer = new Player({ type: "CALL", loop: true });

export function handleIncomingVoice(contact: any) {
  console.log(lp + "INCOMMING call contact event: ", contact);
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.INCOMING, contact);
}
export function handleConnectingVoice(contact: any) {
  console.log(lp + "CONNECTING call contact event: ", contact);
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.CONNECTING, contact);
}
  
export function handleMissedVoice(contact: any) {
  console.log(lp + "MISSED call contact event: ", contact, contact.isInbound());
  if (contact.isInbound()) {
    if (contact.getActiveInitialConnection() !== null) {
      changeAgentStateToAvailable();
    } else {
      console.log(
        `${lp} Contact ${contact.getContactId()} was missed but not changing agent status due to initial connection(customer) disconnected.`
      );
    }
  }
  callPlayer.pause();
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.MISSED, contact);
}

export function handleDestroyVoice(contact: any) {
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.DESTROYED, contact);
}

export function handleAcceptedVoice(contact: any) {
  console.log(
    lp +
      "ACCEPTED call contact event: accepted by agent. Contact state is " +
      contact.getStatus().type,
    contact
  );
  callPlayer.pause();
  //updateCallAndSend(CONTACT_EVENT_STATE_MAP.ACCEPTED, contact);
}
export function handleConnectedVoice(contact: any) {
  console.log(
    lp +
      "CONNECTED call contact event: connected to agent. Contact state is " +
      contact.getStatus().type,
    contact
  );
  callPlayer.pause();
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.CONNECTED, contact);

  // Send message to bg to open milo URL
  Emitter.emit(API_MILO_CHANNEL_TRANSACTION, {
    type: "notif",
    chan: API_MILO_CHANNEL_TRANSACTION,
    body: {
      phoneNumber: contact.getActiveInitialConnection()?.getEndpoint()
        .phoneNumber,
      contactId: contact.contactId || contact.getContactId(),
    },
  });

  // Send message to bg to update transfer ids
}

export function handleRefreshVoice(contact: any) {
  try {
    updateCallAndSend(CONTACT_EVENT_STATE_MAP.REFRESH, contact);
  } catch (e) {
    handleEndedVoice(contact);
  }
}

export function handleEndedVoice(contact: any) {
  updateCallAndSend(CONTACT_EVENT_STATE_MAP.ENDED, contact);
  if (contact.getStatus().type === "error") {
    console.log(
      lp + "Error in call contact event: ",
      contact.getStatus().type,
      contact
    );
  }
}

export function updateCallAndSend(
  type: any,
  contact: any,
  data?: { muted: boolean }
) {
  const existingCall = Object.assign({}, connectSession.getCall());
  const updatedCall = updateCall(type, contact, data);
  if (JSON.stringify(existingCall) === JSON.stringify(updateCall)) return;
  if (updatedCall != null) {
    connectSession.setCall(updatedCall);
  }
  Emitter.emit(STATE_CALL, updatedCall);
}

function updateCall(
  contactEventType: string,
  contact: any,
  data?: { muted: boolean }
): Call | null {
  try {
    let call = connectSession.getCall() || { ...defaultValue }; // Create a new object if call is null
    let updatedCall;

    switch (contactEventType) {
      case CONTACT_EVENT_STATE_MAP.INITIAL:
        // Similar logic, but ensure we create a new object
        updatedCall = {
          ...call,
          id: contact.contactId || contact.getContactId(),
          self: {
            ...call.self,
            id: contact.getAgentConnection().connectionId,
            state: contact.isInbound()
              ? CONTACT_STATE_MAP.INCOMING
              : CONTACT_STATE_MAP.DIALING,
            direction: contact.isInbound() ? "INBOUND" : "OUTBOUND",
            attributes: {},
            notes: "",
            endTime: "",
            muted: false,
          },
          customer: {
            ...call.customer,
            state: CONTACT_STATE_MAP.CONNECTED,
            attributes: formatAttributes(contact.getAttributes()),
            displayName: contact.getActiveInitialConnection()?.getEndpoint()
              .phoneNumber,
            phoneNumber: contact.getActiveInitialConnection()?.getEndpoint()
              .phoneNumber,
          },
        };
        return updatedCall;

      case CONTACT_EVENT_STATE_MAP.CONNECTING:
      case CONTACT_EVENT_STATE_MAP.INCOMING:
        return {
          ...call,
          self: {
            ...call.self,
            state: contact.isInbound()
              ? CONTACT_STATUS_MAP.INCOMING
              : CONTACT_STATUS_MAP.CONNECTING,
          },
        };

      case CONTACT_EVENT_STATE_MAP.CONNECTED:
        updatedCall = {
          ...call,
          self: {
            ...call.self,
            state: CONTACT_STATE_MAP.CONNECTED,
            held: call.self?.held || contact.getAgentConnection()?.isOnHold(),
            muted: call.self?.muted || false,
            connectedTime: call.self.connectedTime || new Date().getTime(),
          },
        };
        return updatedCall;

      case CONTACT_EVENT_STATE_MAP.MISSED:
        return {
          ...call,
          self: {
            ...call.self,
            missed: true,
          },
        };
        clearCurrentCallContact();
        return call;

      case CONTACT_EVENT_STATE_MAP.ENDED:
      case CONTACT_EVENT_STATE_MAP.DESTROYED:
        return {
          ...call,
          self: {
            ...call.self,
            state: CONTACT_STATE_MAP.ENDED,
            connectedTime: call.self.connectedTime || new Date().getTime(),
            endTime: call.self.endTime || new Date().getTime(),
          },
        };

      case CONTACT_EVENT_STATE_MAP.REFRESH:
        // Similar logic for handling refresh
        let selfState = (CONTACT_STATE_MAP as any)[
          contact.getState().type.toUpperCase()
        ];
        if (
          call.id === (contact.contactId || contact.getContactId()) &&
          selfState !== CONTACT_EVENT_STATE_MAP.ENDED
        ) {
          return {
            ...call,
            self: {
              ...call.self,
              state: selfState,
              held: contact.getActiveInitialConnection()?.isOnHold(),
            },
            customer: {
              ...call.customer,
              attributes: formatAttributes(contact.getAttributes()),
            },
          };
        }
        return call;

      case CONTACT_EVENT_STATE_MAP.MUTETOGGLE:
        return {
          ...call,
          self: {
            ...call.self,
            muted: data?.muted,
          },
        };

      default:
        return call;
    }
  } catch (err) {
    console.log(lp + "Error in updating call: " + err);
    return null;
  }
}
