import { SHOW_LOADER, SHOW_TAB } from "../constants/constants";

const initialState = {
  isLoading: false,
  tab: 3,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        isLoading: action.isLoading,
      };
    case SHOW_TAB:
      return {
        tab: action.tab,
      };
    default:
      return state;
  }
}
