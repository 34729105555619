import React from 'react';
import { connect } from 'react-redux';
import { Flex, View, ActionButton, Text, Divider, Button } from '@adobe/react-spectrum';
import './style.css';
import { ChevronLeft } from '../common/Icons';
import { sendMessage } from '../../services';
import { API_TAB_CHANGE, USER_GUIDE_LINK, API_DOWNLOAD_LOGS } from '../../constants/constants';
import { Accordion, AccordionItem } from '../common/Accordian';

/**
 * Map Store State to Componennt Props
 * @param {*} state
 */
function mapStateToProps(state:any) {
    return {
        webrtc: state.webrtc,
    };
}

const HelpSupport = (props:any) => {
  const { tabs } = props;
  const faqs = [
      {
          heading: "AICv3 Production User Guide - Wiki",
          value: (
              <ul>
                  <li>
                      <a href={USER_GUIDE_LINK} target='_blank'>Click here to access the complete AICv3 Production User Guide.</a>
                  </li>
              </ul>
          )
      },
  ];
  const backRedirect = () => {
      let [firstTab] = tabs;
      let { id } = firstTab;
      sendMessage({
          type: 'notif',
          body: {
              id,
          },
          chan: API_TAB_CHANGE
      });
  };

  const handleDownloadLogs = () => { 
      sendMessage({
          type: 'notif',
          body: {},
          chan: API_DOWNLOAD_LOGS
      });
  };
  return (
      <div className="mainContent">
          <Flex direction={'column'}>
              <Flex direction={'row'} alignItems="center" marginBottom={'15px'}>
                  <ActionButton onPress={() => backRedirect()} isQuiet UNSAFE_className='acs-left-chevron-btn'>
                      <ChevronLeft fill="#6E6E6E" />
                  </ActionButton>
                  <View>
                      <h3 className='page-main-heading' style={{ marginLeft: '0px' }}>Help & Support</h3>
                  </View>
              </Flex>
              <Divider size="S" />
              <Flex direction={'row'} alignItems="center" justifyContent='space-between' margin={'10px 0px 10px 0px'}>
                  <View>
                      <Text UNSAFE_className='acount-settings-heading'>Troubleshooting Guide</Text>
                  </View>
              </Flex>
          </Flex>

          <Accordion defaultIndex={null} style={{ marginBottom: '20px' }}>
              {faqs && faqs.map((faq, index) => {
                  return (
                      <AccordionItem
                          heading={faq.heading}
                          index={index + 1}
                          customClassName="text_align_help"
                      >
                          <div>{typeof faq.value === "string" ? <div dangerouslySetInnerHTML={{ __html: faq.value }} /> : faq.value}</div>
                      </AccordionItem>
                  )
              })}
          </Accordion>

          <Flex direction={'column'}>
              <Flex direction={'row'} alignItems="center" marginTop={'25px'}>
                  <Button variant="accent" onPress={handleDownloadLogs}>Download Logs</Button>
              </Flex>
          </Flex>
      </div>
  );
};


// Send Data form store to Connected Components needed
export default connect(mapStateToProps)(HelpSupport);