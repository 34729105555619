/// <reference path="../../types/globals.d.ts">
import { initiateLogout, initiateNavigator, resetSessionError, setCallAgentStatusOpen, setCaseAgentStatusOpen, setChatAgentStatusOpen, setCurrentSessionTab, setSessionError } from "../actions/session";
// import { store } from "../store";
// import Emitter, { Emitter, SessionEvent } from "../Emitter";
import {
  START_SESSION,
  STOP_SESSION,
  BG_TELEMETRY,
  BG_NOTIFICATION,
  BG_INFO,
  BG_MESSAGE,
  INTERNAL_OPEN_WINDOW,
  STATE_LOGIN,
  CCP_STATE,
  SESSION_STATE,
  INTERNAL_RESET_CCP_STATE,
  INTERNAL_CALL_API,
  BG_MEDIA_PERM_CHECK,
  BG_ASK_MEDIA_PERM,
  API_TAB_CHANGE,
  CHANGE_CALL_AGENT_STATUS,
  CHANGE_CHAT_AGENT_STATUS,
  CHANGE_CASE_AGENT_STATUS,
  UPDATE_MEDIA_DEVICES_LIST,
  API_UPDATE_RING_MEDIA_DEVICE,
  API_UPDATE_ROD_DEVICE,
  STATE_UPDATE_RING_MEDIA_DEVICE,
  SESSION_ERROR,
  RESET_SESSION_ERROR,
  VOICE_NETWORK_STATE,
  CCP_PING,
  CCP_PONG,
  SESSION_TIMER_ALERT,
  API_CALL_HANGUP,
  API_CALL_REJECT,
  API_WINDOW_RELOAD,
  CCP_ASK_MEDIA_PERMISSION,
  OPEN_MEDIA_PERMISSION_TAB,
  API_DOWNLOAD_LOGS_STATUS,
  BG_API_STATUS,
  SHOW_CCP_CONNECTION_ERROR,
  RETRY_CCP_CONNECTION,
  BG_LOGIN_DETAILS,
} from "../constants/events";
import {
 // initiateLogout,
 // initiateNavigator,
  // resetSessionError,
 // setCallAgentStatus,
  //setCaseAgentStatus,
 // setChatAgentStatus,
  // setSessioError,
} from "../reducers/session";
import {
  checkMediaPermission,
  openPermissionTab,
  openPermissionTabForCCP,
  sendMessage,
  sendMessagePort,
  sendMessageToExtenal,
  sendMessageToOffscreen,
} from "../chrome/tabs";
import {
  MISSING_GROUP_MSG,
  MISSING_GROUP_TITLE,
  WEBRTC_CONNECTED_MSG,
  NETWORK_DISCONNECT_RECONNECT,
  ALLOWED_CONV_MEDIA_TYPES,
  VOICE_NETWORK_DISCONNECT,
  SESSION_TIMER_WARNING,
  AGENT_STATUS_LIST,
} from "../constants/common";
import {
  changeAgentState,
  checkVoicePolicy,
  getCannedResponses,
  getProfile,
  getlostMessages,
  stopSession,
  rejectCallback,
} from "../api/session-api";
import {
  //setCCPsessionState,
  //updateMediaDevices,
  //updateRODevice,
  //updateRingMediaDevice,
  //setVoiceNetworkState,
} from "../reducers/webrtc";
import { IndividualChat } from "../reducers/chat";
import { ccpWindow } from "./window";
import { checkInternet } from "../utils/utils";
import { setCCPState, updateMediaDeviceAction, updateMediaDevicesAction, updateRingOnDifferentDevice, updateVoiceNewtworkState } from "../actions/webrtc";
import storeRTK from "../store/storeRTK";
import { logoutSession } from "../connector/session";
import Emitter from "../emitter";
import { SessionEvent } from "../types/call.type";

export const CCP_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  CONNECTED: 4,
  DISCONNECTED: 5,
};
let retryCount = 0;
const maxRetries = 2;
const delay = 30000; // 30 seconds

class Session {
  ccpStateInterval: any = null;
  agentProfile: any = null;
  sessionTimer: any = null;
  lastPingTime: number = 0;
  constructor() {
    this.onloggedIn = this.onloggedIn.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.startCcpConnection = this.startCcpConnection.bind(this);
    this.sendSessionTimeoutAlert = this.sendSessionTimeoutAlert.bind(this);
    this.checkSessionTimeout = this.checkSessionTimeout.bind(this);
    this.clearCallOnNetworkVPNDisconnect =
      this.clearCallOnNetworkVPNDisconnect.bind(this);
    this.performNetworkReconnectActions =
      this.performNetworkReconnectActions.bind(this);
    this.checkCCPStateAndRetry = this.checkCCPStateAndRetry.bind(this);
  }

  login() {
    Emitter.emit(INTERNAL_OPEN_WINDOW);
    setTimeout(() => {
      getProfile()
        .then((agentProfile: any) => {
          if (agentProfile && agentProfile.email) {
            this.agentProfile = agentProfile;
            // Checking CCP Access when it is true then only to proceed to login.
            const ccpAccess =
              typeof agentProfile?.aicConfig?.CCP_ACCESS === "string"
                ? JSON.parse(agentProfile?.aicConfig?.CCP_ACCESS)
                : agentProfile?.aicConfig?.CCP_ACCESS;
            if (ccpAccess) {
              this.startCcpConnection({
                ccp_url: agentProfile?.aicConfig?.ccp_url || "",
              });
            } else {
              const wid = ccpWindow.getCcpWindowId();
              if (wid) {
                chrome.windows.remove(wid);
                ccpWindow.windowCloseListener(wid);
              }
              Emitter.emit(BG_TELEMETRY, {
                type: "notif",
                chan: BG_INFO,
                body: {
                  type: BG_LOGIN_DETAILS,
                  chan: BG_LOGIN_DETAILS,
                  info: `Login failed due to ccp access false for user ${agentProfile?.email}`,
                },
              });
              storeRTK.dispatch(
                loginFailure(
                  "We're sorry, but you don't currently have permission to proceed."
                )
              );
            }
          } else {
            storeRTK.dispatch(
              loginFailure("Error in getting profile, no email id received")
            );
          }
        })
        .catch((error: any) => {
          storeRTK.dispatch(loginFailure("Error in getting profile"));
        });
    }, 500);
  }

  logout() {
    console.log("Logging out: Logout function called!!!");
    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      body: {
        type: STOP_SESSION,
        chan: STOP_SESSION,
        info: `Logging out: Logout function called!!!`,
      },
    });
    if (this.ccpStateInterval) {
      clearInterval(this.ccpStateInterval);
      this.ccpStateInterval = undefined;
    }
    // clear the session timer
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
      this.sessionTimer = undefined;
    }
    const state = storeRTK.getState();
    if (state.session.loggedIn) {
      const state = storeRTK.getState();
      if (!state.session.isLogoutProcessing) {
        storeRTK.dispatch(initiateLogout({ isLogoutProcessing: true }));
        storeRTK.dispatch(logoutSession("normal logout, logging out user"));
      } else {
        console.log(
          "Logout already in progress, not sending logout request again"
        );
        //initiateLogout({ isLogoutProcessing: false });
        //store.dispatch(logoutSession('normal logout, logging out user'));
      }
    } else {
      storeRTK.dispatch(logoutSession("No session conn, logging out user"));
    }

    try {
      stopSession();
    } catch (e) {
      console.log("stop session api failed and returned error", e);
    }
  }
  onloggedIn(ev: SessionEvent<any>) {
    if (ev.success) {
      const state = storeRTK.getState();
      const initialState = {
        type: "notif",
        body: {
          agent: this.agentProfile,
          state: { loggedIn: true },
          call: {},
          case: {},
        },
        success: true,
      } as any;
      if (
        this.agentProfile?.email &&
        initialState &&
        state.session.loggedIn !== true
      ) {
        storeRTK.dispatch(loginSession(initialState.body));
        sendMessageToOffscreen(ev);
        // Meanwhile Fetch cannedResponse too
        getCannedResponses();
      } else if (!this.agentProfile?.email) {
        // storeRTK.dispatch(
        //   loginFailure("Error in getting profile, no email id received")
        // );
      } else {
        console.log("Already logged in not updating");
      }

      if (initialState?.body?.agent && state.session.loggedIn !== true) {
        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          body: {
            type: BG_MESSAGE,
            chan: BG_MESSAGE,
            message: `User with email ${
              initialState?.body?.agent?.email
            } logged into AICv3 v${
              process.env.VERSION
            } at ${new Date().getTime()}`,
          },
        });
      } else if (state.session.loggedIn === true) {
        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          body: {
            type: BG_MESSAGE,
            chan: BG_MESSAGE,
            message: `User with email ${
              initialState?.body?.agent?.email
            } closed and reopen ccp-client window or service worker restarted of AICv3 v${
              process.env.VERSION
            } at ${new Date().getTime()}`,
          },
        });
      }
      //This is to check on login if agent has right group assigned or not
      if (initialState?.body?.agent) {
        const agent = initialState?.body?.agent;
        if (
          !agent?.allowedConversationMedia ||
          agent?.allowedConversationMedia?.length === 0
        ) {
          setTimeout(
            () =>
              sendMessage({
                type: "notif",
                chan: BG_NOTIFICATION,
                body: {
                  type: "error",
                  title: MISSING_GROUP_TITLE,
                  message: MISSING_GROUP_MSG,
                },
              }),
            500
          );
        }
      }
      clearInterval(this.ccpStateInterval);
      this.ccpStateInterval = setInterval(() => {
        this.startCcpConnection();
      }, 5000);

      clearInterval(this.sessionTimer);
      this.sessionTimer = setInterval(() => {
        this.checkSessionTimeout();
      }, 5000);
    } else {
      const errorEvent = ev as SessionEvent<string>;
      // storeRTK.dispatch(
      //   loginFailure(errorEvent.body || "Error occurred during login.")
      // );
    }
  }
  //Window related functions

  callApi(ev: SessionEvent<any>, timeout?: number) {
    try {
      // sendMessageToExtenal(ev);
      return Promise.resolve({
        type: "res",
        chan: ev.chan,
        body: {},
        success: true,
      } as SessionEvent<any>);
    } catch (error) {
      return Promise.reject({
        type: "res",
        chan: ev.chan,
        body: "Error in sending message",
        error,
        success: false,
      } as SessionEvent<any>);
    }
  }

  //Api call related functions
  ccpStatusChanged(ev: SessionEvent<{ status: number }>) {
    if (CCP_STATES.CONNECTED === ev.body?.status) {
      const state = storeRTK.getState();
      if (
        state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED &&
        state.webrtc.isVoiceNetworkAvailable
      ) {
        sendMessage({
          type: "notif",
          chan: BG_MESSAGE,
          body: {
            message: WEBRTC_CONNECTED_MSG,
          },
        });
      }
      //reset session error state also
      if (state.session.error) {
        // storeRTK.dispatch(resetSessionError());
        sendMessage({
          type: "notif",
          chan: RESET_SESSION_ERROR,
        });
      }
    }
    const state = storeRTK.getState();
    // change ccpSessionState only if it is different from current state
    if (state.webrtc.ccpSessionState !== ev.body?.status) {
      Emitter.emit(BG_TELEMETRY, {
        type: "notif",
        chan: BG_INFO,
        body: {
          type: CCP_STATE,
          chan: CCP_STATE,
          reason: JSON.stringify(ev),
        },
      });
      if (ev.body && ev.body?.status) {
        storeRTK.dispatch(
          setCCPState({ ccpSessionState: ev.body?.status })
        );
      }
    }
    // send the event to all tabs weather it is same or not so that all tabs can update their state if not updated
    sendMessage({
      type: "notif",
      chan: CCP_STATE,
      body: ev.body,
    });
  }

  resetCCPsessionState() {
    storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
    sendMessage({
      type: "notif",
      chan: CCP_STATE,
      body: {
        status: CCP_STATES.CLOSED,
      },
    });
    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      body: {
        type: "notif",
        chan: CCP_STATE,
        body: {
          status: CCP_STATES.CLOSED,
          message: `ccp state is reset.`,
        },
      },
    });
  }

  ccpPingRequest(_ignored: any, port: chrome.runtime.Port) {
    const state = storeRTK.getState();
    sendMessagePort(
      {
        type: "res",
        chan: CCP_PONG,
        body: {
          loggedIn: state.session.loggedIn,
          isReloginProcessing: state.session.isReloginProcessing,
        },
        success: true,
      },
      port
    );
  }

  // Call getState onLoggedIn
  isVoiceAllowed() {
    const state = storeRTK.getState();
    if (
      // check if voice available in allowedConversationMedia
      state.agent?.allowedConversationMedia?.filter(
        (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
      )?.length !== 0
    ) {
      if (state.agent?.availableMedia?.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async checkEligiblityForVoice() {
    if (!this.isVoiceAllowed()) {
      return false;
    }
    return await checkVoicePolicy();
  }
  checkCCPStateAndRetry() {
    const state = storeRTK.getState();
    if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
      if (retryCount < maxRetries) {
        retryCount++;
        this.performNetworkReconnectActions(true);
        setTimeout(() => {
          if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
            if (retryCount < maxRetries) {
              this.checkCCPStateAndRetry();
            } else {
              retryCount = 0;
              console.log("Max retries reached. Could not connect to CCP.");
              sendMessage({
                type: "notif",
                chan: SHOW_CCP_CONNECTION_ERROR,
                body: {
                  message: "Max retries reached. Could not connect to CCP.",
                  timestamp: new Date().getTime(),
                },
              });

              Emitter.emit(BG_TELEMETRY, {
                type: "notif",
                chan: BG_INFO,
                success: true,
                body: {
                  type: SHOW_CCP_CONNECTION_ERROR,
                  chan: SHOW_CCP_CONNECTION_ERROR,
                  message: "Max retries reached. Could not connect to CCP.",
                  timestamp: new Date().getTime(),
                  context: { agent: true },
                },
              });
            }
          }
        }, delay * retryCount);
      } else {
        retryCount = 0;
        console.log("Max retries reached. Could not connect to CCP.");
        sendMessage({
          type: "notif",
          chan: SHOW_CCP_CONNECTION_ERROR,
          body: {
            message: "Max retries reached. Could not connect to CCP.",
            timestamp: new Date().getTime(),
          },
        });

        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          success: true,
          body: {
            type: SHOW_CCP_CONNECTION_ERROR,
            chan: SHOW_CCP_CONNECTION_ERROR,
            message: "Max retries reached. Could not connect to CCP.",
            timestamp: new Date().getTime(),
            context: { agent: true },
          },
        });
      }
    }
  }
  performNetworkReconnectActions = (retry?: boolean) => {
    //reset session error state also

    const state = storeRTK.getState();
    // if(state.webrtc.ccpSessionState === CCP_STATES.CONNECTED) {
    //     sendMessage({
    //         type: 'notif',
    //         chan: CCP_STATE,
    //         body: {
    //             status: CCP_STATES.CONNECTED
    //         }
    //     })
    //     return;
    // }
    const currentTime = new Date().getTime();
    const timeSinceLastPing = currentTime - this.lastPingTime;
    const retryPrefix = retry ? "RETRYING: " : "";
    const prefix =
      retryPrefix +
      `NETWOR_RECONNECTED: User ${state.agent.firstName} ${
        state.agent.lastName
      } with email = ${state.agent?.email} is online now at ${new Date()}`;
    console.log(
      `${prefix}, his current state is = ${state.agent.state}, perform connection refresh actions at}`
    );

    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      success: true,
      body: {
        type: NETWORK_DISCONNECT_RECONNECT,
        chan: NETWORK_DISCONNECT_RECONNECT,
        message: `${prefix}, his current state is = ${state.agent.state}, perform connection refresh actions}`,
        timestamp: currentTime,
      },
    });

    if (state.session.error) {
      storeRTK.dispatch(resetSessionError());
      sendMessage({
        type: "notif",
        chan: RESET_SESSION_ERROR,
      });
    }
    storeRTK.dispatch(initiateNavigator({ isOnline: true }));

    if (state?.chat?.chats?.length > 0) {
      try {
        state?.chat?.chats?.forEach((ch: IndividualChat) => {
          if (ch?.id) {
            getlostMessages(ch.id);
          }
        });
      } catch (error) {
        console.log("error get lost messages", error);
      }
    }

    // close ccp window if exists so that it reopens again and makes fresh connection
    const logoutTimeout = state.agent?.aicConfig?.network_disconnect_logout_time
      ? state.agent?.aicConfig?.network_disconnect_logout_time * 1000
      : 10000; // 10 seconds in milliseconds
    if (timeSinceLastPing > logoutTimeout || retry) {
      const wid = ccpWindow.getCcpWindowId();
      if (wid) {
        sendMessageToExtenal({
          type: "req",
          chan: API_WINDOW_RELOAD,
          body: {},
        });
        if (state.call.id) {
          this.clearCallOnNetworkVPNDisconnect();
        }
        chrome.windows.remove(wid, () => {
          ccpWindow.windowCloseListener(wid);
        });
      }
    } else {
      console.log(
        `${prefix}, not refreshing connection due to short disconnect duration}`
      );
      Emitter.emit(BG_TELEMETRY, {
        type: "notif",
        chan: BG_INFO,
        success: true,
        body: {
          type: "info",
          chan: "info",
          message: `${prefix}, not refreshing connection due to short disconnect duration}`,
          timestamp: new Date().getTime(),
        },
      });
    }
  };
  async startCcpConnection(t?: { ccp_url: string }) {
    const isInternetConnected = await checkInternet();

    if (isInternetConnected) {
      const state = storeRTK.getState();

      if (!state.session.isOnline && state.session.loggedIn) {
        //if user is offline and network is back, perform reconnect actions
        this.performNetworkReconnectActions();
        setTimeout(() => {
          this.checkCCPStateAndRetry();
        }, 30000);
      }
      this.lastPingTime = new Date().getTime();
      let isEligibleForVoice = await this.checkEligiblityForVoice();
      if (!isEligibleForVoice) {
        //save in store and call tab red
        storeRTK.dispatch(updateVoiceNewtworkState(false));
        sendMessage({
          type: "notif",
          chan: VOICE_NETWORK_STATE,
          body: {
            isVoiceNetworkAvailable: false,
          },
        });
        //update telemetry
        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          body: {
            type: SESSION_ERROR,
            chan: SESSION_ERROR,
            message: VOICE_NETWORK_DISCONNECT,
            timestamp: new Date().getTime(),
          },
        });
        // Clear call if call exists
        this.clearCallOnNetworkVPNDisconnect();
      } else {
        storeRTK.dispatch(updateVoiceNewtworkState(true));
        sendMessage({
          type: "notif",
          chan: VOICE_NETWORK_STATE,
          body: {
            isVoiceNetworkAvailable: true,
          },
        });
      }
    } else {
      const state = storeRTK.getState();
      if (state.webrtc.ccpSessionState !== CCP_STATES.CLOSED) {
        storeRTK.dispatch(
          setCCPState({ ccpSessionState: CCP_STATES.CLOSED })
        );
        sendMessage({
          type: "notif",
          chan: CCP_STATE,
          body: {
            status: CCP_STATES.CLOSED,
          },
        });
      }
      storeRTK.dispatch(
        setSessionError({
          message: NETWORK_DISCONNECT_RECONNECT,
          type: "ping",
        })
      );
      sendMessage({
        type: "notif",
        chan: SESSION_ERROR,
        body: {
          message: NETWORK_DISCONNECT_RECONNECT, //pause player
          type: "ping",
          timestamp: new Date().getTime(),
        },
      });
      Emitter.emit(BG_TELEMETRY, {
        type: "notif",
        chan: BG_INFO,
        body: {
          message: NETWORK_DISCONNECT_RECONNECT,
          type: "ping",
          chan: SESSION_ERROR,
          timestamp: new Date().getTime(),
        },
      });

      storeRTK.dispatch(initiateNavigator({ isOnline: false }));
      if (state?.callback?.selfState === "RINGING") {
        rejectCallback();
      }
    }
  }

  checkSessionTimeout() {
    const state = storeRTK.getState();
    const { aicConfig } = state.agent;
    const sessionWarningTime =
      (Number(aicConfig?.session_warning_duration) || 0) * 1000 ||
      SESSION_TIMER_WARNING * 1000; //converting to mili seconds
    if (state?.session?.loggedInAt) {
      const sessionDuration = new Date().getTime() - state?.session?.loggedInAt;
      if (sessionDuration > sessionWarningTime) {
        this.sendSessionTimeoutAlert();
      }
    }
  }

  sendSessionTimeoutAlert() {
    const state = storeRTK.getState();
    if (state?.agent?.state?.displayName != AGENT_STATUS_LIST.AVAILABLE) {
      const stateOfAgent = state?.agent?.states?.allowedStates.filter(function (
        st: any
      ) {
        return st.displayName === AGENT_STATUS_LIST.AVAILABLE;
      });
      if (typeof stateOfAgent !== "undefined" && stateOfAgent.length > 0) {
        changeAgentState({ agentState: stateOfAgent[0].id });
      }
    }

    if (!state?.call?.id && !state?.chat?.activeChatID) {
      sendMessage({
        type: "notif",
        chan: SESSION_TIMER_ALERT,
        body: { dialog: true },
      });
    }
  }
  clearCallOnNetworkVPNDisconnect() {
    const state = storeRTK.getState();
    if (state.call.id) {
      if (
        state.call.self?.state === "ESTABLISHED" ||
        state.call.self?.state === "DIALING"
      ) {
        sendMessageToExtenal({
          type: "notif",
          chan: API_CALL_HANGUP,
          body: { conversationId: state.call.id },
        });
      } else {
        sendMessage({
          body: { conversationId: state.call?.id },
          type: "req",
          chan: API_CALL_REJECT,
        });
      }
    }
  }
}

export const session = new Session();

export default function registerCcpSessionHandlers() {
  Emitter.on(START_SESSION, session.login);
  Emitter.on(STOP_SESSION, session.logout);
  Emitter.on(STATE_LOGIN, session.onloggedIn);
  Emitter.on(CCP_STATE, session.ccpStatusChanged);
  Emitter.on(SESSION_STATE, (ignored, port) => {
    const state = storeRTK.getState();
    sendMessagePort(
      {
        type: "res",
        chan: SESSION_STATE,
        body: state,
        success: true,
      },
      port
    );
  });
  Emitter.on(INTERNAL_RESET_CCP_STATE, session.resetCCPsessionState);
  Emitter.on(INTERNAL_CALL_API, session.callApi);
  Emitter.on(CCP_PING, session.ccpPingRequest);
  // Media permission check events
  Emitter.on(BG_MEDIA_PERM_CHECK, (ev, port) => {
    checkMediaPermission((res: boolean) => {
      sendMessagePort(
        {
          type: "res",
          chan: BG_MEDIA_PERM_CHECK,
          body: { wasAllowed: res },
          success: true,
        },
        port
      );
    });
  });
  Emitter.on(BG_ASK_MEDIA_PERM, (ignored, port) => {
    openPermissionTab();
  });

  Emitter.on(CCP_ASK_MEDIA_PERMISSION, (ignored, port) => {
    sendMessage({
      type: "notif",
      chan: CCP_ASK_MEDIA_PERMISSION,
      success: true,
    });
    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      body: {
        type: CCP_ASK_MEDIA_PERMISSION,
        chan: CCP_ASK_MEDIA_PERMISSION,
        message: "CCP is asking for media permission",
        context: {
          agent: true,
        },
      },
    });
  });
  Emitter.on(OPEN_MEDIA_PERMISSION_TAB, (ignored, port) => {
    openPermissionTabForCCP();
  });
  // persist main header tab on backend and send event back to all tabs of UI
  Emitter.on(API_TAB_CHANGE, (ev:any) => {
    if (ev.body) {
      storeRTK.dispatch(setCurrentSessionTab(ev.body));
      sendMessage({
        type: "notif",
        body: ev.body,
        chan: API_TAB_CHANGE,
        success: true,
      });
    }
  });

  // status of dialog open/close on call, chat or case tab
  Emitter.on(CHANGE_CALL_AGENT_STATUS, (ev:any) => {
    if (ev.body) {
      storeRTK.dispatch(setCallAgentStatusOpen(ev.body));
    }
  });
  Emitter.on(CHANGE_CHAT_AGENT_STATUS, (ev:any) => {
    if (ev.body) {
      storeRTK.dispatch(setChatAgentStatusOpen(ev.body));
    }
  });
  Emitter.on(CHANGE_CASE_AGENT_STATUS, (ev:any) => {
    if (ev.body) {
      storeRTK.dispatch(setCaseAgentStatusOpen(ev.body));
    }
  });

  // Media devices list fetch and update events
  Emitter.on(UPDATE_MEDIA_DEVICES_LIST, (ev) => {
    storeRTK.dispatch(updateMediaDevicesAction(ev.body?.mediaDevices));
    sendMessage({
      type: "notif",
      chan: UPDATE_MEDIA_DEVICES_LIST,
      body: ev.body,
    });
  });
  Emitter.on(API_UPDATE_RING_MEDIA_DEVICE, (ev:any) => {
    //This below event will be listened only in WT, UI is already updated
    sendMessageToOffscreen(ev);
  });
  Emitter.on(API_UPDATE_ROD_DEVICE, (ev:any) => {
    storeRTK.dispatch(updateRingOnDifferentDevice(ev.body?.ringOnDifferentDevice));
  });
  Emitter.on(STATE_UPDATE_RING_MEDIA_DEVICE, (ev:any) => {
    if (ev.success) {
      storeRTK.dispatch(updateMediaDeviceAction(ev.body?.deviceId));
    }

    //BG will update UI about the setSink Device success
    sendMessage(ev);
  });

  Emitter.on(API_DOWNLOAD_LOGS_STATUS, (ev:any) => {
    sendMessage({
      type: "notif",
      chan: BG_API_STATUS,
      body: {
        message: ev.body,
        status: ev.success,
      },
    });
  });
  Emitter.on(RETRY_CCP_CONNECTION, () => {
    session.performNetworkReconnectActions(true);
  });
}
