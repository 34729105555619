import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Flex, View } from "@adobe/react-spectrum";
import { sendMessage } from "../../services";
import {
  CALL_STATES,
  CHAT_STATES,
  STATE_WEBCHATMESSAGE,
  CALL,
  CHAT,
  API_TAB_CHANGE,
  NONE,
  ALLOWED_CONV_MEDIA_TYPES,
  CASE,
  CASE_STATES,
  CCP_STATES,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  BG_BROADCAST,
  SETTINGS,
  VOICE_NETWORK_DISCONNECT,
  HELPSUPPORT,
  SHOW_API_STATUS_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_CCP_CONNECTION_ERROR,
  HIDE_CCP_CONNECTION_ERROR,
  CCP_CONNECTION_ERROR_MSG,
} from "../../constants/constants";
import CallPage from "../CallPage";
import "./style.css";
import WrapUpPopover from "../CallPage/CallWebcase/WrapUpPopover";
import { SETTING_OPTIONS } from "./constants";
import { canMakeCall } from "../../utils";
import ConnectionPopup from "./ConnectionPopup";
import CCPConnectionErrorPopup from "./CCPConnectionErrorPopup";
import ChatPage from "../ChatPage";
import CasePage from "../CasePage";
import { Toast } from "../common/Toast";
import AccountSettings from "../AccountSettings";
import HelpSupport from "../HelpSupport";
import storeRTK from "../../store/storeRTK";
import { setCurrentSessionTab } from "../../actions/session";
import Emitter from "../../emitter";
import TabSwitch from "../common/Tabs/TabSwitch";
import { IndividualChat } from "../../types/chat.type";
import { IndividualTask } from "../../amazon-connect/models/task";
import SidebarCP from "../Sidebar";
import { callTabs, caseTabs } from "../common/Data/data";
import { RootState } from "../../store/storeConfig";
import HeaderComponent from "../Login/HeaderComponent";
/**
 * Map Store State To component Props
 * @param {*} state
 */

function mapStateToProps(state: any) {
  return {
    call: state.call,
    agent: state.agent,
    callback: state.callback,
    webrtc: state.webrtc,
    chat: state.chat,
    session: state.session,
    cases: state.cases,
  };
}

const Dashboard = (props: any) => {
  const historyState = useSelector((state: RootState) => state.history);
  const { call } = useSelector((state: RootState) => state);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [logDownloadMsg, setLogDownloadMsg] = useState("");
  const [showLogStatus, setShowLogStatus] = useState(false);
  const [showMessageLog, setShowMessageLog] = useState(false);
  const [showCCPConnectionError, setShowCCPConnectionError] = useState(false);

  useEffect(() => {
    // storeRTK.dispatch(setCurrentSessionTab({ id: "call" }));
  }, [props.session.currentTab]);

  const tabChangedOnAgentStatusChange = (message: any) => {
    sendMessage({
      type: "notif",
      chan: API_TAB_CHANGE,
      body: {
        id: message.id,
      },
    });
  };
  const changeTabOnAgentStatusChange = () => {
    tabChangedOnAgentStatusChange({ id: NONE });
  };
  const onTabChange = (id: any) => {
    // sendMessage({
    //   type: "notif",
    //   body: {
    //     id,
    //   },
    //   chan: API_TAB_CHANGE,
    // });
    //todo - enable call and chat
    storeRTK.dispatch(setCurrentSessionTab({ id: id }));
    // storeRTK.dispatch(setCurrentSessionTab({ id: "case" }));
    setNewMessageAlert(0);
  };

  // useEffect(() => {
  //     Emitter.on(SHOW_MESSAGE, (body:any) => {
  //         setShowMessage(true);
  //         setMessage(body.message);
  //     });
  //     Emitter.on(HIDE_MESSAGE, () => {
  //         setShowMessage(false);
  //         setMessage('');
  //     });
  //     return () => {
  //         Emitter.off(HIDE_MESSAGE, () => {
  //             setShowMessage(false);
  //             setMessage('');
  //         });
  //         Emitter.off(SHOW_MESSAGE, (body:any) => {
  //             setShowMessage(true);
  //             setMessage(body.message);
  //         });
  //     }
  // }, []);
  useEffect(() => {
    if (
      props.call.id &&
      props.call.self?.state === CALL_STATES.RINGING &&
      props.session.currentTab !== CALL
    ) {
      onTabChange(CALL);
    }
  }, [props.call]);

  useEffect(() => {
    if (
      props.callback.id &&
      props.callback.self?.callback?.state === CALL_STATES.RINGING &&
      props.session.currentTab !== CALL
    ) {
      onTabChange(CALL);
    }
  }, [props.callback]);

  useEffect(() => {
    if (
      !props.chat?.activeChatID &&
      props.chat?.chats?.length > 0 &&
      props.session.currentTab === NONE
    ) {
      onTabChange(CHAT);
    }
  }, [props.chat?.chats?.length]);

  useEffect(() => {
    if (
      props.chat?.chats?.length === 0 &&
      !props.cases?.activeCaseID &&
      props.cases?.cases?.length > 0 &&
      props.session.currentTab === NONE
    ) {
      onTabChange(CASE);
    }
  }, [props.cases?.cases?.length]);

  // useState
  const [newChatRinging, setNewChatRinging] = useState(0);
  const [newCaseRinging, setNewCaseRinging] = useState(0);

  useEffect(() => {
    if (props.chat?.chats.length) {
      const count = props.chat?.chats?.filter(
        (ele: IndividualChat) => ele?.selfState === CHAT_STATES.RINGING
      ).length;
      setNewChatRinging(count);
    } else {
      setNewChatRinging(0);
    }
  }, [props.chat]);
  useEffect(() => {
    if (props.cases.cases.length) {
      const count = props.cases?.cases?.filter(
        (ele: IndividualTask) => ele?.selfState === CASE_STATES.RINGING
      ).length;
      setNewCaseRinging(count);
    } else {
      setNewCaseRinging(0);
    }
  }, [props.cases]);

  const [newMessageAlert, setNewMessageAlert] = useState(0);

  const updateChatUnreadPointer = (message: any) => {
    let { body } = message;
    if (body.body !== undefined && body.role === "CUSTOMER") {
      setNewMessageAlert(1);
    } else {
      setNewMessageAlert(0);
    }
  };

  Emitter.on(STATE_WEBCHATMESSAGE, updateChatUnreadPointer);
  let tabValues = [];

  if (
    props.agent?.allowedConversationMedia?.filter(
      (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
    )?.length !== 0
  ) {
    let color =
      props.webrtc.isVoiceNetworkAvailable &&
      canMakeCall(props.agent.availableMedia) &&
      !props.session.error &&
      props.webrtc.ccpSessionState === CCP_STATES.CONNECTED
        ? props?.call?.id || props?.callback?.id
          ? "yellow"
          : ""
        : "red";
    tabValues.push({ id: CALL, name: "Call", color: color });
  }
  if (
    props.agent?.allowedConversationMedia?.filter(
      (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.CHAT
    )?.length !== 0
  ) {
    let color =
      props.session.error || props.webrtc.ccpSessionState === CCP_STATES.CLOSED
        ? "red"
        : props?.chat?.activeChatID
        ? "yellow"
        : "";
    tabValues.push({ id: CHAT, name: "Chat", color: color });
  }
  if (
    props.agent?.allowedConversationMedia?.filter(
      (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.CASE
    )?.length !== 0
  ) {
    let color =
      props.session.error || props.webrtc.ccpSessionState === CCP_STATES.CLOSED
        ? "red"
        : props?.cases?.activeCaseID
        ? "yellow"
        : "";
    tabValues.push({ id: CASE, name: "Case", color: color });
  }
  const onMessageClose = () => {
    setShowMessage(false);
    setMessage("");
    sendMessage({
      type: "notif",
      chan: BG_BROADCAST,
      body: {
        type: "notif",
        body: "",
        chan: HIDE_MESSAGE,
      },
    });
  };

  // useEffect(() => {
  //     Emitter.on(SHOW_API_STATUS_MESSAGE, showApiStatusMessage);
  //     Emitter.on(HIDE_ERROR_MESSAGE, ()=>{setShowMessageLog(false)});
  //     Emitter.on(SHOW_CCP_CONNECTION_ERROR, () => {
  //         setShowCCPConnectionError(true);
  //     });
  //     Emitter.on(HIDE_CCP_CONNECTION_ERROR, () => {
  //         setShowCCPConnectionError(false);
  //     });
  //     if(props.webrtc.ccpSessionState === CCP_STATES.CONNECTED){
  //         setShowCCPConnectionError(false);
  //     }
  //     return () => {
  //         Emitter.off(SHOW_API_STATUS_MESSAGE, showApiStatusMessage);
  //         Emitter.off(HIDE_ERROR_MESSAGE, ()=>{setShowMessageLog(false)});
  //         Emitter.off(SHOW_CCP_CONNECTION_ERROR, () => {
  //             setShowCCPConnectionError(true);
  //         });
  //         Emitter.off(HIDE_CCP_CONNECTION_ERROR, () => {
  //             setShowCCPConnectionError(false);
  //         });
  //     };
  // }, [props]);
  const onErrorMessageClose = () => {
    if (showMessageLog === true) {
      setShowMessageLog(false);
      sendMessage({
        type: "notif",
        chan: BG_BROADCAST,
        body: {
          type: "notif",
          body: "",
          chan: HIDE_ERROR_MESSAGE,
        },
      });
    }
  };

  const showApiStatusMessage = (messages: any) => {
    setShowMessageLog(true);
    const { status, message } = messages;
    setShowLogStatus(status);
    setLogDownloadMsg(message.message);
  };

  return (
    <View UNSAFE_className="mainWrapper">
      <HeaderComponent />
      <Flex direction="row" height={"92.5%"} width={"100%"}>
        <View width={"12%"}>
          <SidebarCP />
        </View>
        {/* <View>
          {props.session.currentTab === CASE && (
            <TabSwitch tabs={caseTabs} history={historyState.cases} />
          )}
        </View> */}
        <View width={"88%"} height={"100%"}>
          {props.session.currentTab === CALL && (
            <TabSwitch
              tabs={callTabs}
              history={historyState.conversations}
              isActiveCall={call?.id}
            />
          )}
        </View>
      </Flex>

      {/* ------------- chat call case commenetd for testing ----------------*/}

      {/* {props.session.currentTab === CALL ? <CallPage /> : <></>}
      {props.session.currentTab === CHAT ? <ChatPage /> : <></>} */}
      {props.session.currentTab === CASE ? <CasePage /> : <></>}
      {props.session.currentTab === SETTINGS ? (
        <AccountSettings tabs={tabValues} />
      ) : (
        <></>
      )}
      {props.session.currentTab === HELPSUPPORT ? (
        <HelpSupport tabs={tabValues} />
      ) : (
        <></>
      )}
      {props.session.currentTab === NONE ? (
        <>
          {/* <AgentStateIcon agentStatusName={props.agent?.state?.displayName} /> */}
          {navigator.onLine &&
            !props.webrtc.isVoiceNetworkAvailable &&
            // props.session.error &&
            props.agent?.allowedConversationMedia?.filter(
              (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
            )?.length !== 0 && (
              <div
                area-role="mediaAvailableMessage"
                className="mediaAvailableMessage"
              >
                <Toast message={VOICE_NETWORK_DISCONNECT}></Toast>
              </div>
            )}
          {props.agent?.allowedConversationMedia?.filter(
            (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
          )?.length !== 0 &&
            !canMakeCall(props.agent.availableMedia) && (
              <div
                area-role="mediaAvailableMessage"
                className="mediaAvailableMessage"
              >
                <Toast message="Voice is not configured, you can not receive/make calls."></Toast>
              </div>
            )}
          {showMessage && (
            <div
              area-role="mediaAvailableMessage"
              className="mediaAvailableMessage"
            >
              <Toast
                message={message}
                variant="positive"
                duration={5000}
                closable={true}
                onClose={onMessageClose}
              ></Toast>
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      {/* <WebRTCPopup /> */}
      {showCCPConnectionError && (
        <CCPConnectionErrorPopup message={CCP_CONNECTION_ERROR_MSG} />
      )}
      {/* (!props.call.id || !props.call.self?.state) &&
                    (!props.callback.id || !props.callback.self?.callback?.state) && */}

      {/* ------------- chat call case commenetd for testing ----------------*/}

      {props.agent.states?.allowedStates &&
        props.session.currentTab !== CALL &&
        props.session.currentTab !== CHAT &&
        props.session.currentTab !== CASE && (
          <>
            <ConnectionPopup />
            {showMessageLog && (
              <div
                area-role="mediaAvailableMessage"
                className="mediaAvailableMessage"
                key="errorMessage"
              >
                <Toast
                  message={logDownloadMsg}
                  variant={showLogStatus ? "positive" : "negative"}
                  duration={5000}
                  closable={true}
                  onClose={() => onErrorMessageClose()}
                ></Toast>
              </div>
            )}
            {/* <footer>
              <AgentStatus onSubmit={changeTabOnAgentStatusChange} />
            </footer> */}
          </>
        )}
      <WrapUpPopover />
    </View>
  );
};

export default connect(mapStateToProps)(Dashboard);
