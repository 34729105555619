import React from "react";
import {ChevronRight, ChevronDown} from '../Icons';
import {Divider} from '@adobe/react-spectrum';
import './style.css';

export const AccordionItem = ({
  heading,
  subHeading,
  isCollapsed,
  handleClick,
  children,
  customClassName,
}) => {
  return (
    <>
      <button className="accordion-button accordion-button-chat" onClick={handleClick}>
        <div className="accordion-icon">
          {isCollapsed ? <ChevronRight /> : <ChevronDown />}
        </div>
        <div className="accordion-panel">
        <span className={`accordion-heading ${customClassName ? customClassName : ""}`}>{heading}</span>
          <span className="accordion-subHeading">{subHeading}</span>
        </div>
      </button>
      <div
        className={`accordion-item ${isCollapsed ? "collapsed" : "expanded"}`}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
      <Divider size="S" />
    </>
  );
};

export const Accordion = ({ defaultIndex, onItemClick, children }) => {
  const [bindIndex, setBindIndex] = React.useState(defaultIndex);

  const changeItem = (itemIndex) => {
    if (typeof onItemClick === "function") onItemClick(itemIndex);
    if (itemIndex !== bindIndex) setBindIndex(itemIndex);
    if(itemIndex === bindIndex) setBindIndex(null);
  };
  // const items = children.filter((item) => item.type.name === "AccordionItem");

  return (
    <>
      {children?.length > 0 && children.map(({ props }:any) => (
        <AccordionItem
          isCollapsed={bindIndex !== props.index}
          heading={props.heading}
          subHeading={props.subHeading}
          handleClick={() => changeItem(props.index)}
          children={props.children}
          customClassName={props?.customClassName}
        />
      ))}
    </>
  );
};
