import {
  resetAgentACWState,
  setAgentACWState,
  setAgentCurrState,
  setCompleteAgentState,
  updateAgentCannedResponses,
  updateAgentGeoLocationAction,
} from "../actions/agent";
import { answerCase, chatWrapup, emailWrapup, rejectCase } from "../api/session-api";
import {
  AGENT_AFTER_CALL_WORK_STATE_ID,
  API_CASE_ANSWER,
  CALL,
  CALLBACK,
  CHAT,
  EMAIL} from "../constants/constants";
import {
  STATE_ACW,
  STATE_AGENT,
} from "../constants/constants";
import { API_CASE_REJECT, INTERNAL_SESSION_START } from "../constants/events.ts";
import Emitter from "../emitter/index.ts";
// import {
//     AgentState,
//     agentUpdateState,
//     resetAgentUpdateAfterCallWork,
//     agentUpdateAfterCallWork,
//     agentUpdateCannedResponses,
//     AgentAcw,
//     CannedResponses,
// } from '../reducers/agent';
import storeRTK from "../store/storeRTK";
// import { caseHandler, updateActiveCaseTab } from "./cases.ts";
import { historyInitialiseHandler } from "./history.ts";

export function agentPresenceHandler(ev: any) {
  storeRTK.dispatch(setAgentCurrState(ev));
}

export function setCompleteAgentData(ev: any) {
  storeRTK.dispatch(setCompleteAgentState(ev.body));
}

//storeRTK.dispatch(setInitialChatState(messageEnvelope.body.chat));

function afterCallWorkHandler(ev: any) {
  if (ev.success && ev.body) {
    if (ev.body.state === "PENDING") {
      if (
        ev.body.conversationMedia === CALL ||
        ev.body.conversationMedia === CALLBACK
      ) {
        const update = {
          acwState: {
            id: AGENT_AFTER_CALL_WORK_STATE_ID,
            displayName: "After Call Work",
          },
          acwStateModifiedDate: new Date().getTime(),
        };
        storeRTK.dispatch(setAgentACWState(update));
      } else if (ev.body.conversationMedia === CHAT) {
        const participantId = ev.body?.selfId;
        const conversationId = ev.body.conversationId;
        if (participantId && conversationId) {
          setTimeout(() => {
            chatWrapup({ participantId, conversationId });
          }, 0);
        } else {
          console.warn(
            "Either participantId or conversationId is missing during chat wrapup",
            ev
          );
        }
      } else if (ev.body.conversationMedia === EMAIL) {
        const participantId = ev.body?.selfId;
        const conversationId = ev.body.conversationId;
        if (participantId && conversationId) {
          setTimeout(() => {
            emailWrapup({ participantId, conversationId });
          }, 0);
        } else {
          console.warn(
            "Either participantId or conversationId is missing during email wrapup",
            ev
          );
        }
      }
    } else if (ev.body.state === "COMPLETE") {
      if (
        ev.body.conversationMedia === CALL ||
        ev.body.conversationMedia === CALLBACK
      ) {
        const update = {
          acwState: null,
          acwStateModifiedDate: 0,
        };
        storeRTK.dispatch(resetAgentACWState());
      } else if (ev.body.conversationMedia === CHAT) {
        //do nothing
      }
    }
  }
}
export function cannedResponseHandler(event: any) {
  if (event.body) {
    storeRTK.dispatch(updateAgentCannedResponses(event.body));
  }
}

export function updateAgentGeoLocation(data: {geoLocation: string}) {
  storeRTK.dispatch(updateAgentGeoLocationAction(data));
}
console.log("***** cases registerCaseHandlers called from agent.ts for testing purpose as of now agent.ts file calling only *****")
 export default function registerAgentHandlers() {
    Emitter.on(STATE_AGENT, agentPresenceHandler);
    Emitter.on(INTERNAL_SESSION_START, historyInitialiseHandler);
    Emitter.on(API_CASE_ANSWER, answerCase);
    Emitter.on(API_CASE_REJECT,rejectCase)
    Emitter.on(STATE_ACW, afterCallWorkHandler);
//     Emitter.on(STATE_CANNEDRESPONSES, cannedResponseHandler);
}
