import { Divider, Flex, View } from "@adobe/react-spectrum";
import "./style.css";
import CaseAi from "../../CasePage/CaseAI/CaseAi";
import RecentCalls from "./RecentCalls";
import CallDialPad from "./CallDialpad";
import ActiveCallContainer from "./ActiveCallContainer";

const CallDialer = () => {
  return (
    <Flex justifyContent={"space-around"} width={"100%"} height={"100%"}>
      <View width={"100%"} backgroundColor={"gray-100"}>
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          UNSAFE_style={{ backgroundColor: "#fff" }}
        >
          <Flex height={"40%"}>
            <ActiveCallContainer
              title="No active call"
              description=" Start a new call or wait for the call"
            />
          </Flex>
          <Flex
            width={"100%"}
            height={"60%"}
            justifyContent={"space-around"}
            alignItems={"center"}
            UNSAFE_className="call-dialer-container"
            UNSAFE_style={{ backgroundColor: "#f8f8f8" }}
          >
            <CallDialPad />
            <Divider size="S" orientation="vertical" />
            <RecentCalls />
          </Flex>
        </Flex>
      </View>
      <Flex UNSAFE_style={{ backgroundColor: "#fff" }}>
        <CaseAi caseID="" isDisabled={true} />
      </Flex>
    </Flex>
  );
};

export default CallDialer;
