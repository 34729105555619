
// import { store } from '../store';
// import { emitter } from '../emitter';
import {
    BG_TELEMETRY,
    BG_NOTIFICATION,
    BG_INFO,
    INTERNAL_OPEN_WINDOW,
    WEBRTC_WINDOW_CLOSED,
    API_CALL_REJECT,
    STOP_SESSION,
    INTERNAL_RESET_CCP_STATE,
    INTERNAL_CALL_API,
    STOP_CCP,
} from '../constants/events';
//import { setWebrtcWindowStatus } from '../reducers/session';
import {  sendMessage, sendMessageToExtenal } from '../chrome/tabs';
import {  WINDOW_CLOSE_DURING_CALL_MSG, WINDOW_CLOSE_DURING_CALL_TITLE, OFFSCREEN_DOCUMENT_PATH } from '../constants/common';
import { setWebrtcWindowStatus } from '../actions/session';
import storeRTK from '../store/storeRTK';

let webrtcWindow: { open: boolean, id?: number } = {
    open: false,
    id: undefined
};

class CcpWindow {
    constructor() {
        this.openWindow = this.openWindow.bind(this);
        this.openCcpWindow = this.openCcpWindow.bind(this);
        this.closeCcpWindow = this.closeCcpWindow.bind(this);
        this.removeWindow = this.removeWindow.bind(this);
        this.windowCloseListener = this.windowCloseListener.bind(this);
        this.getCcpWindowId = this.getCcpWindowId.bind(this);

        //If window is opened already add close listener to handle service worker restart
        // chrome.storage.sync.get(['webrtcWindowId'], (result) => {
        //     console.log("Fetched webrtc window id", result);
        //     const wid = result?.webrtcWindowId || webrtcWindow.id;
        //     if(wid) {
        //         webrtcWindow.id = wid,
        //         webrtcWindow.open = true;
        //     }
        //     if(webrtcWindow.id && webrtcWindow.open === true) {
        //         if (!chrome.windows.onRemoved.hasListener(this.windowCloseListener)) {
        //             chrome.windows.onRemoved.addListener(this.windowCloseListener);
        //         }
        //     }
        // });
        
    }
  
    openWindow(info?: { openMinimized: boolean }) {
        chrome.windows.create({ url:  `${process.env.AIC_CCP_CLIENT_URL}?version=${process.env.VERSION}`, top: 0, left: 0, width: 320, height: 360, type: "popup" }).then(window => {
            webrtcWindow.open = true;
            webrtcWindow.id = window.id;
            chrome.storage.sync.set({ webrtcWindowId: window.id });
            storeRTK.dispatch(setWebrtcWindowStatus({ id: window.id }));
            if (!chrome.windows.onRemoved.hasListener(this.windowCloseListener)) {
                chrome.windows.onRemoved.addListener(this.windowCloseListener);
            }
            //setTimeout(() => this.checkRingingStatus(), 1000);
        });
    }

    openCcpWindow(info?: { openMinimized: boolean }) {
        chrome.storage.sync.get(['webrtcWindowId'], (result) => {
            const wid = result?.webrtcWindowId;
            if (wid) {
                this.removeWindow(wid, () => {
                    this.openWindow(info);
                });
            } else if(webrtcWindow.id) {
                this.removeWindow(webrtcWindow.id, () => {
                    this.openWindow(info);
                });
            } else {
                this.openWindow(info);
            }
        });
    }
    getCcpWindowId() {
        return webrtcWindow.id;
    }
    closeCcpWindow() {
        chrome.storage.sync.get(['webrtcWindowId'], (result) => {
            const wid = result?.webrtcWindowId || webrtcWindow.id;
            if(wid) {
                sendMessageToExtenal({
                    type: 'req',
                    chan: STOP_CCP,
                    body: {}
                });
                setTimeout(()=> {
                    this.removeWindow(wid, () => {
                        webrtcWindow.open = false;
                        webrtcWindow.id = undefined;
                        chrome.storage.sync.set({ webrtcWindowId: '' });
                    });
                }, 1000);
            } else {
                console.log("No window available with id " + wid);
            }
        })
    }

    removeWindow(wid: number, callback: any) {
        setTimeout(() => {
            try {
                chrome.windows.remove(wid, callback)
            }
            catch (error) {
                console.log("Error while removing window", error);
                //Window not available, do nothing
                callback(false);
            }
        }, 100);
    }

    windowCloseListener = (windowId: number) => {
        console.log("windowCloseListener", windowId, webrtcWindow.id);
        // Run this listener only for AIC window
        if (windowId === webrtcWindow.id) {
            const state = storeRTK.getState();
            if (state.call.id) {
                sendMessage({
                    type: 'notif',
                    chan: BG_NOTIFICATION,
                    body: { message: WINDOW_CLOSE_DURING_CALL_MSG, title: WINDOW_CLOSE_DURING_CALL_TITLE, type: 'warning' },
                    success: false,
                });
            }
            // emitter.emit(BG_TELEMETRY, {
            //     type: 'notif',
            //     chan: BG_INFO,
            //     body: {
            //         type: WEBRTC_WINDOW_CLOSED,
            //         chan: WEBRTC_WINDOW_CLOSED,
            //         message: "Webrtc window closed",
            //         state: state
            //     },
            // });
    
            webrtcWindow = { open: false, id: undefined };
            //chrome.storage.sync.set({ webrtcWindowId: '' });
    
            if (state.call.self?.state == 'RINGING') {
                // emitter.emit(INTERNAL_CALL_API, {
                //     type: 'req',
                //     chan: API_CALL_REJECT,
                //     body: {
                //         participantId: state.call.self?.id,
                //         conversationId: state.call.id,
                //     },
                // })
            }
            // emitter.emit(INTERNAL_RESET_CCP_STATE);
            setTimeout(() => {
                const state = storeRTK.getState();
                if (state.session.loggedIn && !state.session.isLogoutProcessing && !webrtcWindow.open) {
                    this.openCcpWindow();
                }
            }, 500);
        }
    }  
}

export const ccpWindow = new CcpWindow();

export default function registerWindowHandlers() {
    // emitter.on(INTERNAL_OPEN_WINDOW, () => {
    //     const state = store.getState();
    //     /**
    //      * Do not close window if relogin due to pod restart
    //     */
    //     if(!state.session.serverInitiatedDisconnect) {
    //         ccpWindow.openCcpWindow()
    //     }
    // });
    // emitter.on(STOP_SESSION, ccpWindow.closeCcpWindow);
}
