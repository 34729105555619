import {
    CHAT_RESET,
    CHAT_SET,
    CHAT_MESSAGE,
    LOST_MESSAGE,
    INDIVIDUAL_CHAT_RESET,
    INITIAL_CHAT_SET,
    RESET_UNREAD_MESSAGE_COUNT,
    ACTIVE_CHAT_UPDATE,
    UPDATE_CHAT_TRANSCRIPT,
    SET_TRANSFER_END_POINTS_UPDATE,
    SET_SELECTED_ATTACHMENT_UPDATE,
    SET_UPLOADING_ATTACHMENT_UPDATE,
} from '../constants/action-types';

export function chatReset() {
    return {
        type: CHAT_RESET,
    };
}

export function setInitialChatState(chat:any) {
    return {
        type: INITIAL_CHAT_SET,
        chat,
    };
}
export function setChatState(chat:any) {
    return {
        type: CHAT_SET,
        chat,
    };
}
export function setChatMessage(message:any) {
    return {
        type: CHAT_MESSAGE,
        message,
    };
}
export function updateLostMessage(message:any){
    return {
        type: LOST_MESSAGE,
        message,
    };
}

export function individualChatReset(chat:any) {
    return {
        type: INDIVIDUAL_CHAT_RESET,
        chat,
    };
}
export function resetUnreadMessagesCount(message:any) {
    return {
        type: RESET_UNREAD_MESSAGE_COUNT,
        chatID: message.chatID
    };
}

export function setActiveChatUpdate(message:any){
    return {
        type: ACTIVE_CHAT_UPDATE,
        chatID: message.chatID
    }
}
export function updateChatTranscript(message:any) {
    return {
        type: UPDATE_CHAT_TRANSCRIPT,
        message,
    };
}

export function setTransferEndPoint(message:any) {
    return {
        type: SET_TRANSFER_END_POINTS_UPDATE,
        message,
    }
}

export function setSelectedAttachment(message:any) {
    return {
        type: SET_SELECTED_ATTACHMENT_UPDATE,
        message,
    }
}

export function setUploadingAttachment(message:any) {
    return {
        type: SET_UPLOADING_ATTACHMENT_UPDATE,
        message,
    }
}