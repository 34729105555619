import { useEffect, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import Emitter from "../../emitter";
import {
  API_CHANGE_AGENT_STATE,
  SESSION_TIMER_ALERT,
} from "../../constants/constants";
import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
} from "@adobe/react-spectrum";
import { setLoader } from "../../actions/common";
import storeRTK from "../../store/storeRTK";
import { session } from "../../handlers/session";
// import { loginHandler } from "../../controller/okta";

/**
 * Map Store State To component Props
 * @param {*} state
 */

function mapStateToProps(state: any) {
  return {
    session: state.session,
    agent: state.agent,
    call: state.call,
    chat: state.chat,
  };
}

const SessionTimer = (props: any) => {
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    Emitter.on(SESSION_TIMER_ALERT, (event) => {
      if (event.body) {
        setDialog(true);
      }
    });
    return () => {
      Emitter.off(SESSION_TIMER_ALERT, (event) => {
        if (event.body) {
          setDialog(true);
        }
      });
      setDialog(false);
    };
  }, []);

  const handleLogout = () => {
    storeRTK.dispatch(setLoader(true));
    const offlineState = props.agent?.states?.allowedStates?.find(
      (s: any) => s?.displayName === "Offline"
    );
    if (offlineState && offlineState.id) {
      Emitter.emit(API_CHANGE_AGENT_STATE, {
        body: { agentState: offlineState?.id },
        type: "req",
      });
    }
    session.logout();
    storeRTK.dispatch(setLoader(false));
  };

  // const handleLogin = () => {
  //     storeRTK.dispatch(setLoader(true));
  //     setTimeout(() => {
  //         loginHandler();
  //     }, 100);
  // };
  // const redirectForAuth = () => {
  //     handleLogout();
  //     setTimeout(handleLogin, 500);
  // };

  return (
    <DialogTrigger isOpen={dialog}>
      <ActionButton isHidden>Dialog</ActionButton>
      <Dialog width={"30rem"}>
        <Heading>Session expire warning! </Heading>
        <Divider />
        <Content>
          Your session is about to expire, please reauthenticate to stay
          connected.
        </Content>
        <ButtonGroup>
          <Button variant="secondary" onPress={handleLogout}>
            Log Off
          </Button>
          {/* <Button variant="primary" autoFocus onPress={redirectForAuth}>Re-Authenticate</Button> */}
        </ButtonGroup>
      </Dialog>
    </DialogTrigger>
  );
};
export default connect(mapStateToProps)(SessionTimer);
