import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Toast } from '../common/Toast';

/**
 * Map Store State To component Props
 * @param {*} state
 */

function mapStateToProps(state) {
    return {
        session: state.session,
    };
}


const ConnectionPopup = (props) => {
    return (
        <>
            {props.session?.error && props.session.error?.message && (
                <div
                    area-role="mediaAvailableMessage"
                    className="mediaAvailableMessage"
                >
                    <Toast message={props.message || props.session.error?.message} showProgressCircle={true}></Toast>
                </div>
            )}
        </>
    );
};
export default connect(mapStateToProps)(ConnectionPopup);
