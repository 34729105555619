import {
  HISTORY_RESET,
  HISTORY_SET,
  ADD_CALL_HISTORY,
  NEW_HISTORY_SET,
  REMOVE_CALL_HISTORY,
  DELETE_CALL_HISTORY,
  CALLBACK_HISTORY,
  ADD_CALLBACK_HISTORY,
  REMOVE_CALLBACK_HISTORY,
  DELETE_CALLBACK_HISTORY,
  CHAT_HISTORY,
  ADD_CHAT_HISTORY,
  REMOVE_CHAT_HISTORY,
  DELETE_CHAT_HISTORY,
  CASE_HISTORY,
  ADD_CASE_HISTORY,
  REMOVE_CASE_HISTORY,
  DELETE_CASE_HISTORY,
  HISTORY_CASE_SET_SUMMARY_ISSUE,
  HISTORY_CASE_SET_SUGGEST_ISSUE,
} from "../constants/action-types";

/**
 * Sets the history state
 * @param History
 */
export function setHistoryState(history: any) {
  return {
    type: HISTORY_SET,
    history,
  };
}
export function resetHistory(history: any) {
  return {
    type: HISTORY_RESET,
    history,
  };
}
export function setHistory(history: any) {
  return {
    type: NEW_HISTORY_SET,
    history,
  };
}
export function addCallToHistory(history: any) {
  return {
    type: ADD_CALL_HISTORY,
    history,
  };
}
export function removeCallFromHistory() {
  return {
    type: REMOVE_CALL_HISTORY,
  };
}
export function deleteCallFromHistory(history: any) {
  return {
    type: DELETE_CALL_HISTORY,
    history,
  };
}
export function setCallbackHistory(history: any) {
  return {
    type: CALLBACK_HISTORY,
    history,
  };
}
export function addCallbackToHistory(history: any) {
  return {
    type: ADD_CALLBACK_HISTORY,
    history,
  };
}
export function removeCallbackFromHistory() {
  return {
    type: REMOVE_CALLBACK_HISTORY,
  };
}
export function deleteCallbackFromHistory(history: any) {
  return {
    type: DELETE_CALLBACK_HISTORY,
    history,
  };
}
export function setChatHistory(history: any) {
  return {
    type: CHAT_HISTORY,
    history,
  };
}
export function addChatToHistory(history: any) {
  return {
    type: ADD_CHAT_HISTORY,
    history,
  };
}
export function removeChatFromHistory() {
  return {
    type: REMOVE_CHAT_HISTORY,
  };
}
export function deleteChatFromHistory(history: any) {
  return {
    type: DELETE_CHAT_HISTORY,
    history,
  };
}
export function setCaseHistory(history: any) {
  return {
    type: CASE_HISTORY,
    history,
  };
}
export function addCaseToHistory(history: any) {
  return {
    type: ADD_CASE_HISTORY,
    history,
  };
}
export function removeCaseFromHistory() {
  return {
    type: REMOVE_CASE_HISTORY,
  };
}
export function deleteCaseFromHistory(history: any) {
  return {
    type: DELETE_CASE_HISTORY,
    history,
  };
}

export function setHistoryCaseSummaryIssue(
  caseID: string,
  userResponse: string,
  aiResponse: string
) {
  return {
    type: HISTORY_CASE_SET_SUMMARY_ISSUE,
    caseID,
    userResponse,
    aiResponse,
  };
}

export function setHistoryCaseSuggestIssue(
  caseID: string,
  userResponse: string,
  aiResponse: string
) {
  return {
    type: HISTORY_CASE_SET_SUGGEST_ISSUE,
    caseID,
    userResponse,
    aiResponse,
  };
}
