export const PERMISSION_CHECK_PATH = '/media-perm=true';
export const BG_NOTIFICATION = 'bg.Notification';
export const BG_ERROR_MESSAGE = 'bg.Error.Message';
export const CCP_ERROR_MESSAGE = 'ccp.Error.Message';
export const CCP_ASK_MEDIA_PERMISSION = 'ccp.AskMediaPerm';
export const BG_BROADCAST = 'bg.Broadcast';
export const BG_TABS_ADD = '/bg/tabs/add';
export const BG_TELEMETRY = 'bg.Telemetry';
export const SESSION_STATE = 'session.State';
export const BG_PERF_LOADUI = '/bg/performance/load-ui';
export const EXTENSION_DISCONNECT = '/internal/bg/disconnect';
export const CCP_PING = 'ccp.Ping';
export const CCP_PONG = 'ccp.Pong';
export const CCP_MIC_PERM_CHECK = 'ccp.MICPermCheck';
export const API_CHANGE_AGENT_STATE = 'api.changeAgentState';
export const START_CCP = 'start.ccp';
export const STOP_CCP = 'stop.ccp';
export const STATE_AGENT = 'state.Agent';
export const STATE_CALL = 'state.Call';
export const STATE_CASE ='state.Task';
export const STATE_ACW = 'state.ACW';
export const STATE_LOGIN = 'state.login';
export const API_CALL_ANSWER = 'api.callAnswer';
export const API_CALL_ANSWER_ERROR = 'api.callAnswer.Error';
export const API_TASK_ANSWER_ERROR = 'api.taskAnswer.Error';
export const API_CALL_REJECT = 'api.callReject';
export const API_CALL_HANGUP = 'api.callHangup';
export const API_CALL_MUTE = 'api.callMute';
export const API_CALL_UPDATE = 'api.callUpdate';
export const API_CALL_TRANSFER = 'api.callTransfer';
export const API_MILO_CHANNEL_TRANSACTION = 'api.milo.channelTransaction';
export const API_MILO_CHAT_CHANNEL_TRANSACTION = 'api.milo.chatChannelTransaction';
export const API_MILO_CASE_CHANNEL_TRANSACTION = 'api.milo.caseChannelTransaction';
export const API_MILO_VOICEMAIL_CHANNEL_TRANSACTION = 'api.milo.voicemailChannelTransaction';
export const BG_RTC_RECONNECT = 'bg.rtcReconnect';
export const CCP_STATE = 'ccp.state';
export const API_CALL_DIAL = 'api.callDial';
export const API_CALL_DIAL_ERROR = 'api.callDial.error';
export const API_CALL_SEND_DTMF = 'api.sendDTMF';

export const BG_NETWORK_DISCONNECT = 'bg.network.disconnect';
export const WT_REMOVE_WINDOW = 'wt.remove.window';
export const BG_RTC_DISCONNECT = 'bg.rtc.disconnect';
export const BG_INFO = 'bg.Info';
export const BG_WARNING = 'bg.Warning';
export const WT_INITIATE_RELOGIN = 'wt.Initiate.Relogin';
export const CONNECT_STREAMS_DIV_ID = "connect-streams-div";

//do not close warning message
export const WINDOW_CLOSE_WARNING_MESSAGE = "Do not close this window, if you close it, you will loose phone connection and AIC will not function properly.";

// Callback related events
export const STATE_CALLBACK = 'state.Callback';
export const API_CALLBACK_UPDATE = 'api.callbackUpdate';
export const API_CALLBACK_REJECT = 'api.callbackReject';
export const API_CALLBACK_DIAL = 'api.callbackDial';
export const STATE_VOICEMAIL = 'state.voicemail';

// Case related events
export const API_CASE_UPDATE = 'api.caseUpdate';
export const API_CASE_REJECT = 'api.caseReject';

// Chat related events
export const API_CHAT_UPDATE = 'api.chatUpdate';
export const API_CHAT_REJECT = 'api.chatReject';
export const API_SENDCHATMESSAGE = 'api.sendChatMessage';
export const API_SENDCHATTYPING = 'api.sendChatTyping';
export const API_CHAT_TRANSFER = 'api.chatTransfer';
export const API_CHAT_HISTORY = 'api.chatHistory';
export const API_CHECK_FOR_LOST_MESSAGE = 'api.checkLostMessage';
export const STATE_CHAT = 'state.Chat';
export const STATE_WEBCHATMESSAGE = 'state.WebChatMessage';
export const API_DOWNLOAD_ATTACHMENT = 'api.downloadAttachment';
export const API_DOWNLOAD_ATTACHMENT_STATUS = 'api.downloadAttachment.Status';
export const API_SEND_ATTACHMENT = 'api.sendAttachment';
export const API_SEND_ATTACHMENT_STATUS = 'api.sendAttachment.Status';
export const API_SEND_ATTACHMENT_DOWNLOAD_SAVEAS = 'api.download.fileSaveas';
export const STATE_CHAT_HISTORY = 'state.ChatHistory';
export const API_WINDOW_RELOAD = 'api.windowReload';
export const LOG_PREFIX = 'AIC window: ';
export const CONNECT_AGENT_STATES = [
    {
        "id": 0,
        "displayName": "Training",
    },
    {
        "id": 1,
        "displayName": "Busy",
    },
    {
        "id": 2,
        "displayName": "Meeting",
    },
    {
        "id": 3,
        "displayName": "Available",
    },
    {
        "id": 4,
        "displayName": "Offline",
    }
];

export const MESSAGE_TARGETS = {
    OFFSCREEN: 'offscreen',
    UI: 'ui',
    BG: 'bg',
    AIL: 'AIL'
}

export const CONTACT_STATE_MAP = {
    INCOMING: 'RINGING',
    CONNECTING: 'RINGING',
    DIALING: 'DIALING',
    ACCEPTED: 'RINGING',
    CONNECTED: 'ESTABLISHED',
    ENDED: 'RELEASED'
}

export const CONTACT_EVENT_STATE_MAP = {
    INITIAL: 'INITIAL',
    INCOMING: 'INCOMING',
    CONNECTING: 'CONNECTING',
    ACCEPTED: 'ACCEPTED',
    CONNECTED: 'CONNECTED',
    REFRESH: 'REFRESH',
    ENDED: 'ENDED',
    MUTETOGGLE: 'MUTETOGGLE',
    MISSED: 'MISSED',
    DESTROYED:'DESTROYED',
    RINGING: 'RINGING',
}

export const STATE_AGENT_TRANSFER_QUEUES = 'state.agentTransferQueues';

export const CONTACT_STATUS_MAP = {
    INCOMING: 'RINGING',
    CONNECTING: 'DIALING',
    CONNECTED: 'ESTABLISHED',
    ACCEPTED: 'ACCEPTED',
    ENDED: 'RELEASED',
    REFRESHING: 'REFRESHING',
}

export const CHANNEL_TYPES = {
    VOICE: 'voice',
    CHAT: 'chat',
    VOICEMAIL: 'voicemail',
    TASK: 'task',
};
export const CCP_STATES = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
    CONNECTED: 4,
    DISCONNECTED: 5,
};

export const CCP_STATES_INDEX = [
    'CONNECTING',
    'OPEN',
    'CLOSING',
    'CLOSED',
    'CONNECTED',
    'DISCONNECTED',
];

export const STATE_AGENT_CONNECT_STATES = 'state.AgentConnectStates';
export const STATE_CALL_MUTE = 'state.call.mute';

export const CCP_CONNECTION_SUCCESS = 'ccp.connection.success';
export const API_TRANSFER_FAILURE = 'api.transfer.failure';
export const ATTACHMENT_FAILED_MESSAGE = 'Failed to send attachment';
export const API_DOWNLOAD_LOGS = 'api.download.logs';
export const API_DOWNLOAD_LOGS_STATUS = 'api.downloadLogs.Status';
export const STATE_INITIAL_CONTACT = 'state.initialContact';
export const API_CONTACT_EVENT_LOGGING = 'api.contactEventLogging';
export const LOGGER_FILENAMES = [
    'CCP-LOGS.txt',
    'SERVICE-WORKER.txt'
]