import {
  sendMessage,
  sendMessageToExtenal,
  sendMessageToOffscreen,
} from "../chrome/tabs";
import {
  answerCase,
  autoAnswerCase,
  getContactEventLoggingAPIHandler,
  getMiloDeeplinkForCase,
  getTransferEndpoints,
  updateContactReleaseToAIL,
} from "../api/session-api";
import {
  STATE_CASE,
  // API_ACTIVE_CASE_UPDATE,
  API_TAB_CHANGE,
  BG_CASESRING_PLAY,
  BG_CASESRING_PAUSE,
  SESSION_STATE,
  API_ACTIVE_CASE_UPDATE,
  BG_CASE_RELEASE,
  API_MILO_CASE_CHANNEL_TRANSACTION,
  INTERNAL_CASE_RELEASE,
  BG_TELEMETRY,
  BG_INFO,
  API_CASE_AUTO_ANSWER,
} from "../constants/events";
// import { emitter, SessionEvent } from '../emitter';
import // IndividualCase,
//resetNormalCase,
// updateNormalCase,
// setActiveCaseUpdate,
"../reducers/case";
// import { store } from '../store';
import {
  individualCaseReset,
  setActiveCaseUpdate,
  setCaseState,
} from "../actions/case";
import { setCurrentSessionTab } from "../actions/session";
import storeRTK from "../store/storeRTK";
import Emitter from "../emitter";
import { IndividualCase } from "../types/case.type";
import { IndividualTask } from "../amazon-connect/models/task";

function caseHandler(event: any) {
  if (event.body?.self?.state) {
    if (event.body?.self?.state == "RELEASED") {
      sendMessage({
        type: "notif",
        chan: BG_CASE_RELEASE,
        body: { id: "case" },
        success: true,
      });
      const state = storeRTK.getState();
      const ifCasePresent = state.cases.cases.findIndex(
        (indCase: any) => indCase.id === event.body?.id
      );

      if (ifCasePresent > -1) {
        Emitter.emit(INTERNAL_CASE_RELEASE, event);
      }
      // check if there is another ringing case except the current one, if yes dont pause the ringer
      const ifRingingCasePresent = state.cases.cases.filter(
        (indCase: any) =>
          indCase.self?.state === "RINGING" && indCase.id !== event.body?.id
      );
      //to pause the ring only once, as the release event can come multiple
      // times and it can pause ringing of other cases.
      // if case is present and there is no ringing case
      if (ifCasePresent > -1 && ifRingingCasePresent.length === 0) {
        sendMessageToOffscreen({
          type: "notif",
          chan: BG_CASESRING_PAUSE,
          body: { id: "case" },
          success: true,
        });
      }
      const wasActive = event.body.id === state.cases.activeCaseID;
      let getActiveCaseIndex = null;
      if (wasActive) {
        getActiveCaseIndex = state.cases.cases.findIndex(
          (indCase: any) => indCase.id === state.cases.activeCaseID
        );
      }
      storeRTK.dispatch(individualCaseReset(event.body));

      sendMessage({
        type: "notif",
        chan: STATE_CASE,
        body: event.body,
        success: true,
      });
      if (wasActive) {
        const newState = storeRTK.getState();
        const len = newState.cases.cases.length;
        if (len === 0) {
          const body = { caseID: "" };
          storeRTK.dispatch(setActiveCaseUpdate(body));
        } else if (getActiveCaseIndex != null && getActiveCaseIndex <= len) {
          const currentActiveCaseID = newState.cases.activeCaseID;
          const getCaseIndex = storeRTK
            .getState()
            .cases.cases.findIndex(
              (indCase:any) => indCase.id === currentActiveCaseID
            );
          const newActiveCaseIndex =
            getActiveCaseIndex > 0 && getActiveCaseIndex <= len
              ? getActiveCaseIndex - 1
              : 0;
          if (getCaseIndex === -1) {
            const body = {
              caseID: newState.cases.cases[newActiveCaseIndex].id,
            };
            storeRTK.dispatch(setActiveCaseUpdate(body));
          }
        }
      }
      updateContactReleaseToAIL("case", event.body?.id as string);
    } else if (event.body?.self?.state == "TERMINATED") {
      //Do nothing
    } else if (event.body) {
      const existingCaseIndex = storeRTK
        .getState()
        .cases.cases.findIndex((indCase: any) => indCase.id === event.body?.id);

      let state = storeRTK.getState();
      let caseSelfState = null;
      if (existingCaseIndex !== -1) {
        // case exists
        caseSelfState = state.cases?.cases[existingCaseIndex]?.self?.state;
      }
      storeRTK.dispatch(setCaseState(event.body));
      state = storeRTK.getState();

      if (
        state.cases.cases.length === 1 &&
        state.cases.cases[0].id !== state.cases.activeCaseID
      ) {
        const body = { caseID: state.cases.cases[0].id };
        storeRTK.dispatch(setActiveCaseUpdate(body));
      }
      sendMessage({
        type: "notif",
        chan: STATE_CASE,
        body: event.body,
        success: true,
      });
      // call case transcript here.
      // if (event.body?.self?.state == 'ESTABLISHED') {
      //     setTimeout(() => {
      //         if (event && event.body && event.body.id) {
      //             //getCaseHistory(event.body.id);
      //         }
      //     }, 4000);
      // }
      if (existingCaseIndex == -1) {
        if (event.body?.self?.state === "RINGING") {
          getContactEventLoggingAPIHandler(event?.body?.id ?? "", "RINGING");
          // sendMessageToOffscreen( {
          //     type: 'notif',
          //     chan: BG_CASESRING_PLAY,
          //     body: {id: 'case'},
          //     success: true,
          // });
          // try {
          //   if (event.body?.id) {
          //     checkForAutoAnswer(event.body.id);
          //   }
          // } catch (e: any) {
          //   console.log("Error in checkForAutoAnswer " + e.message);
          // }
          if (event.body?.id) {
            caseAnswer(event.body.id);
          }
          // chrome.notifications.create(
          //     {
          //         type: 'basic',
          //         title: 'You have an incoming case',
          //         iconUrl: chrome.runtime.getURL('case.png'),
          //         message: 'Click to answer it'
          //     },
          //     function (notificationId) {
          //         chrome.notifications.onClicked.addListener(
          //             function (clickedNotificationId) {
          //                 if (clickedNotificationId != notificationId)
          //                     return;
          //                 if(event.body?.id) {
          //                     caseAnswer(event.body.id);
          //                 }
          //                 chrome.notifications.clear(
          //                     clickedNotificationId
          //                 );
          //             }
          //         );
          //     }
          // );
        }
        // else {
        //     sendMessageToOffscreen( {
        //         type: 'notif',
        //         chan: BG_CASESRING_PAUSE,
        //         body: {id: 'case'},
        //         success: true,
        //     });
        // }
      }
      // else {
      //     if (event.body.self?.state != 'RINGING') {
      //         if (caseSelfState != 'ESTABLISHED') {
      //             sendMessageToOffscreen( {
      //                 type: 'notif',
      //                 chan: BG_CASESRING_PAUSE,
      //                 body: {id: 'case'},
      //                 success: true,
      //             });
      //         }
      //     }
      // }
    }
  }
}

function checkForAutoAnswer(caseId: string) {
  const state = storeRTK.getState();
  if (
    state.agent?.aicConfig?.auto_answer_case_enabled === "true" &&
    state.agent?.aicConfig?.auto_answer_case_timeout &&
    state.agent?.aicConfig?.auto_answer_case_timeout * 1 >= 0
  ) {
    setTimeout(() => {
      const cs = storeRTK.getState();
      const agentEmail = cs.agent?.email;
      const cc = cs.cases?.cases?.find((c: IndividualCase) => c.id === caseId);
      if (cc && cc.id && cc.self?.state === "RINGING") {
        caseAnswer(caseId);
        autoAnswerCase(caseId);
        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          body: {
            type: API_CASE_AUTO_ANSWER,
            chan: API_CASE_AUTO_ANSWER,
            case_id: caseId,
            message:
              "Auto answering case after ring timeout with contactId = " +
              caseId +
              " by agent = " +
              agentEmail,
          },
        });
      } else {
        Emitter.emit(BG_TELEMETRY, {
          type: "notif",
          chan: BG_INFO,
          body: {
            type: API_CASE_AUTO_ANSWER,
            chan: API_CASE_AUTO_ANSWER,
            case_id: caseId,
            message:
              "No Auto answer!!, Case already answered or rejected, contactId = " +
              caseId +
              +" by agent = " +
              agentEmail,
          },
        });
      }
    }, state.agent.aicConfig.auto_answer_case_timeout * 1000);
  }
}

export function caseAnswer(caseId: string) {
  sendMessage({
    type: "notif",
    chan: API_TAB_CHANGE,
    body: { id: "case" },
    success: true,
  });
  storeRTK.dispatch(setCurrentSessionTab({ id: "case" }));
  storeRTK.dispatch(
    setActiveCaseUpdate({
      caseID: caseId,
    })
  );

  try {
    answerCase({
      participantId: "", // Assuming a default value of ''
      conversationId: caseId || "", // Provide a valid conversationId here
    });
  } catch (e: any) {
    console.log(
      "Error in answering the case through notification: " + e.message
    );
  }

  sendMessage({
    type: "notif",
    chan: API_ACTIVE_CASE_UPDATE,
    body: { caseID: caseId },
    success: true,
  });
}

export function updateActiveCaseTab(event: any) {
  if (event) {
    let existingCases = storeRTK.getState().cases.cases;
    let caseExists = existingCases.findIndex(
      (c: IndividualTask) => c.id === event
    );
    if (caseExists > -1) {
      storeRTK.dispatch(setActiveCaseUpdate(event));
    } else {
      // let state = storeRTK.getState();
      // sendMessage({
      //   type: "notif",
      //   chan: SESSION_STATE,
      //   body: state,
      //   success: true,
      // });
    }
  }
}

// Milo redirect to CRM
async function miloHandlerForCase(ev: any) {
  // calling contact Established API
  contactEstablished(ev.body?.contactId);
  const miloDeeplink = await getMiloDeeplinkForCase(ev.body?.contactId);
  if (miloDeeplink) {
    // put half sec timeout because milo can come immediately and bg state may not be updated till then.
    // setTimeout(() => {
    //   chrome.tabs.create({
    //     url: miloDeeplink,
    //     active: true,
    //   });
    // }, 0);
  }
}

async function contactEstablished(contactId: string | undefined) {
  if (contactId) {
    try {
      getTransferEndpoints(contactId);
    } catch (e: any) {
      console.log(
        "Error getting contact Established API during channel case",
        e.message
      );
    }
  } else {
    console.log("Error: error in setting transfer endpoints");
  }
}

export default function registerCaseHandlers() {
  Emitter.on(STATE_CASE, caseHandler);
  Emitter.on(API_ACTIVE_CASE_UPDATE, updateActiveCaseTab);
  Emitter.on(API_MILO_CASE_CHANNEL_TRANSACTION, miloHandlerForCase);
}
