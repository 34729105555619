import {
    CALLBACK_RESET,
    CALLBACK_SET,
    PLAYBACK_MEDIA_URL_SET,
    UPDATE_NORMAL_CALLBACK,
} from '../constants/action-types';

export function callbackReset(lastCallback = null) {
    return {
        type: CALLBACK_RESET,
        lastCallback,
    };
}

export function setCallbackState(callback:any) {
    return {
        type: CALLBACK_SET,
        callback,
    };
}

export function setMediaPlaybackUrl(playbackMediaURL:any) {
    return {
        type: PLAYBACK_MEDIA_URL_SET,
        playbackMediaURL,
    };
}
export function updateNormalCallback(callback:any) {
    return {
        type: UPDATE_NORMAL_CALLBACK,
        callback,
    };
}
