import { useEffect, useState } from "react";
import { View, Text, Flex, Heading } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import {
  CallHangupIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnMmuteIcon,
} from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_HANGUP,
  API_CALL_HOLD,
  API_CALL_MUTE,
  API_CALL_PLAY,
} from "../../../constants/constants";
import CallTranscript from "../CallTranscript";
import { RootState } from "../../../store/storeConfig";
import storeRTK from "../../../store/storeRTK";
import { setMonitoringCall } from "../../../actions/call";

export const LiveCall = () => {
  const [hold, setHold] = useState(true);
  const [callTime, setCallTime] = useState(0);
  let call = useSelector((state: RootState) => state.call);

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval> | null = null;
    if (call?.self?.state === "ESTABLISHED") {
      const startTime = call?.self.connectedTime;
      timerId = setInterval(() => {
        setCallTime(Date.now() - startTime);
      }, 1000);
    } else {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    }
    return () => {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    };
  }, [call?.self?.state]);

  const formattedTime = () => {
    const hours = Math.floor(callTime / 3600000)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((callTime % 3600000) / 60000)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((callTime % 60000) / 1000)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const muteCallHandler = () => {
    Emitter.emit(API_CALL_MUTE, {
      body: { mute: !call?.self?.muted },
    });
  };

  const pauseCallHandler = () => {
    setHold(!hold);
    Emitter.emit(API_CALL_HOLD, { body: { held: hold } });
  };

  const playCallHandler = () => {
    setHold(!hold);
    Emitter.emit(API_CALL_PLAY, { body: { held: hold } });
  };

  const hangupCallHandler = () => {
    Emitter.emit(API_CALL_HANGUP, { body: { conversationId: call.id } });
    storeRTK.dispatch(setMonitoringCall(false));
  };

  return (
    <View borderColor="gray-200" borderWidth="thin" backgroundColor="gray-100">
      <View
        paddingX="12px"
        borderStartWidth="thicker"
        borderStartColor="static-green-500"
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="3.8rem"
        >
          <Flex direction="column">
            <Text UNSAFE_className="call-inProgress-heading">
              {call?.customer?.displayName}
            </Text>
            <Text UNSAFE_className="call-inProgress-font">
              {call?.self?.state !== "ESTABLISHED" ? "Dialing" : "on call"}{" "}
              {formattedTime()}
            </Text>
          </Flex>
          <Flex direction={"row"}>
            {!call.isMonitoringCall && (
              <>
                <Flex direction={"column"} alignItems="center">
                  <button
                    type="button"
                    disabled={call?.self?.state !== "ESTABLISHED"}
                    className={
                      call?.self?.muted ? "iconButton active " : "iconButton"
                    }
                    onClick={muteCallHandler}
                  >
                    {call?.self?.muted ? <UnMmuteIcon /> : <MuteIcon />}
                  </button>
                  <Text UNSAFE_className="call-inProgress-font">Mute</Text>
                </Flex>
                <Flex direction={"column"} alignItems="center">
                  <button
                    type="button"
                    disabled={call?.self?.state !== "ESTABLISHED"}
                    className={"iconButton"}
                    onClick={hold ? playCallHandler : pauseCallHandler}
                  >
                    {hold ? <PauseIcon /> : <PlayIcon />}
                  </button>
                  <Text UNSAFE_className="call-inProgress-font">{hold ? "Hold" : "Resume"}</Text>
                </Flex>
              </>
            )}
            <Flex direction={"column"} alignItems="center">
              <button
                type="button"
                className={"call-end-Button"}
                onClick={hangupCallHandler}
              >
                <CallHangupIcon />
              </button>
              <Text UNSAFE_className="call-inProgress-font">End</Text>
            </Flex>
          </Flex>
        </Flex>
      </View>
    </View>
  );
};

const CallInProgress = () => {
  return (
    <>
      <View borderColor="gray-200" height={"100%"}>
        <View paddingX={"1rem"} paddingTop={"1rem"}>
          <LiveCall />
        </View>
        <View paddingX={"1rem"} paddingTop={"1rem"}>
          <CallTranscript />
        </View>
      </View>
    </>
  );
};

export default CallInProgress;
