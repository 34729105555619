import {
    API_CALL_ANSWER,
    API_CALL_DIAL,
    API_CALL_HANGUP,
    API_CALL_HOLD,
    API_CALL_PLAY,
    API_CALL_REJECT,
    API_GET_USER,
    API_CALL_TRANSFER,
    API_CALL_INITIATE_WARM_TRANSFER,
    API_CALL_COMPLETE_WARM_TRANSFER,
    API_CALL_SEND_DTMF,
    API_CALL_PATCH_ATTRIBUTE,
    API_AGENT_GROUP_STATUS,
    API_CALL_WRAPUP,
    API_CALLBACK_ANSWER,
    API_CALLBACK_REJECT,
    API_CALLBACK_HANGUP,
    API_CALLBACK_DIAL,
    API_CHAT_REJECT,
    API_CHAT_ANSWER,
    API_SENDCHATMESSAGE,
    API_SENDCHATTYPING,
    API_CHAT_TRANSFER,
    API_CHAT_PATCH_ATTRIBUTE,
    API_CASE_ANSWER,
    API_CASE_REJECT,
    API_CHANGE_AGENT_STATE,
    API_CALL_MUTE,
    API_QUEUE_JOIN_UPDATE,
    API_TEST_RING_PLAY,
    API_DOWNLOAD_ATTACHMENT,
    API_SEND_ATTACHMENT,
    API_CHAT_HISTORY,
    INTERNAL_API_CHAT_HISTORY,
    API_DOWNLOAD_LOGS,
} from '../constants/events';
import Emitter from "../emitter";

import {
  dial,
  getUser,
  hold,
  play,
  reject,
  changeAgentState,
  transfer,
  initiateWarmTransfer,
  completeWarmTransfer,
  sendDtmf,
  patchAttribute,
  agentGroupStatus,
  callWrapup,
  answerCallback,
  hangupCallback,
  rejectCallback,
  dialCallback,
  answerChat,
  rejectChat,
  sendChatMessage,
  sendChatTyping,
  chatPatchAttribute,
  chatTransfer,
  answerCase,
  rejectCase,
  changeSelectedQueues,
  downloadAttachmentAPI,
  sendAttachmentAPI,
  getChatHistory,
  hangup,
  answer,
} from "./session-api";
// import { LogCollector } from "../utils/logger";

// const logCollector = new LogCollector();

/**
 * Default API Handler
 */
// function defaultApiHandlerBroadcast<T>(apiFunc: (request: T) => any) {
//   return function (ev: any) {
//     if (ev.body) {
//       apiFunc(ev.body)
//         .then((result: any) => sendMessage(result))
//         .catch((error: any) => sendMessage(error));
//     }
//   };
// }

// function defaultApiHandler<T>(apiFunc: (request: T) => any) {
//   return function (ev: any, port: chrome.runtime.Port) {
//     if (ev.body) {
//       apiFunc(ev.body)
//         .then((result: any) => sendMessagePort(result, port))
//         .catch((error: any) => sendMessagePort(error, port));
//     }
//   };
// }

// function defaultApiHandlerGeneric<T>(
//   chan: string,
//   apiFunc: (request: T) => Promise<any>
// ) {
//   return function (ev: any, port: chrome.runtime.Port) {
//     if (ev.body) {
//       apiFunc(ev.body)
//         .then(
//           (result) =>
//             ({
//               type: "res",
//               chan,
//               body: result,
//               success: true,
//             } as any)
//         )
//         .catch(
//           (error) =>
//             ({
//               type: "res",
//               chan,
//               body: error,
//               success: false,
//             } as any)
//         )
//         .then((event) => sendMessagePort(event, port));
//     }
//   };
// }
Emitter.on(
  API_CALL_TRANSFER,
  transfer
);
export default function registerApiEvents() {
    // Emitter.on(API_CALL_DIAL, dial);
    // emitter.on(API_CHANGE_AGENT_STATE, defaultApiHandler(changeAgentState));
    // emitter.on(
    //     API_CALL_ANSWER, defaultApiHandlerGeneric(API_CALL_ANSWER, answer));
    // emitter.on(
    //     API_CALL_REJECT,
    //     defaultApiHandlerGeneric(API_CALL_REJECT, reject)
    // );
    // emitter.on(API_CALL_HOLD, defaultApiHandler(hold));
    // emitter.on(API_CALL_PLAY, defaultApiHandler(play));
    // emitter.on(API_CALL_HANGUP, () => {
    //     sendMessageToExtenal({
    //         type: 'notif',
    //         chan: API_CALL_HANGUP,
    //         body: {},
    //     });
    // });
    // emitter.on(API_CALL_MUTE, (ev) => {
    //     sendMessageToExtenal({
    //         type: 'notif',
    //         chan: API_CALL_MUTE,
    //         body: ev.body,
    //     })
    // });
    
    // emitter.on(API_CALL_HANGUP, defaultApiHandler(hangup));
    // emitter.on(API_GET_USER, defaultApiHandler(getUser));

    Emitter.on(
        API_CALL_TRANSFER,
        transfer
    );

    // emitter.on(
    //     API_CALL_INITIATE_WARM_TRANSFER,
    //     defaultApiHandlerGeneric(
    //         API_CALL_INITIATE_WARM_TRANSFER,
    //         initiateWarmTransfer
    //     )
    // );

    // emitter.on(
    //     API_CALL_COMPLETE_WARM_TRANSFER,
    //     defaultApiHandlerGeneric(
    //         API_CALL_COMPLETE_WARM_TRANSFER,
    //         completeWarmTransfer
    //     )
    // );

    // emitter.on(API_CALL_SEND_DTMF, defaultApiHandler(sendDtmf));
    Emitter.on(
        API_CALL_PATCH_ATTRIBUTE,
        patchAttribute
    );
    // emitter.on(API_AGENT_GROUP_STATUS, defaultApiHandler(agentGroupStatus));
    // emitter.on(API_CALL_WRAPUP, defaultApiHandlerBroadcast(callWrapup));

    // // callback handlers
    // emitter.on(
    //     API_CALLBACK_DIAL,
    //     defaultApiHandlerGeneric(API_CALLBACK_DIAL, dialCallback)
    // );
    // emitter.on(
    //     API_CALLBACK_ANSWER,
    //     defaultApiHandlerGeneric(API_CALLBACK_ANSWER, answerCallback)
    // );
    // emitter.on(
    //     API_CALLBACK_HANGUP,
    //     defaultApiHandlerGeneric(API_CALLBACK_HANGUP, hangupCallback)
    // );
    // emitter.on(
    //     API_CALLBACK_REJECT,
    //     defaultApiHandlerGeneric(API_CALLBACK_REJECT, rejectCallback)
    // );
    // emitter.on(
    //     API_CHAT_ANSWER,
    //     defaultApiHandlerGeneric(API_CHAT_ANSWER, answerChat)
    // );
    // emitter.on(
    //     API_CHAT_REJECT,
    //     defaultApiHandlerGeneric(API_CHAT_REJECT, rejectChat)
    // );
    // emitter.on(
    //     API_SENDCHATMESSAGE,
    //     defaultApiHandlerGeneric(API_SENDCHATMESSAGE, sendChatMessage)
    // );
    // emitter.on(
    //     API_SENDCHATTYPING,
    //     defaultApiHandlerGeneric(API_SENDCHATTYPING, sendChatTyping)
    // );
    // emitter.on(
    //     API_CHAT_TRANSFER,
    //     defaultApiHandlerGeneric(API_CHAT_TRANSFER, chatTransfer)
    // );
    // emitter.on(
    //     API_DOWNLOAD_ATTACHMENT,
    //     defaultApiHandlerGeneric(API_DOWNLOAD_ATTACHMENT, downloadAttachmentAPI)
    // );
    // emitter.on(
    //     API_SEND_ATTACHMENT,
    //     defaultApiHandlerGeneric(API_SEND_ATTACHMENT, sendAttachmentAPI)
    // );
    // emitter.on(
    //     API_CHAT_PATCH_ATTRIBUTE,
    //     defaultApiHandlerGeneric(API_CHAT_PATCH_ATTRIBUTE, chatPatchAttribute)
    // );

    // emitter.on(
    //     API_CASE_ANSWER,
    //     defaultApiHandlerGeneric(API_CASE_ANSWER, answerCase)
    // );
    // emitter.on(
    //     API_CASE_REJECT,
    //     defaultApiHandlerGeneric(API_CASE_REJECT, rejectCase)
    // );
    Emitter.on(API_QUEUE_JOIN_UPDATE, changeSelectedQueues)
    // // Play test ring
    // emitter.on(API_TEST_RING_PLAY, async (ev) => {
    //     sendMessageToOffscreen({
    //         type: 'req',
    //         chan: API_TEST_RING_PLAY,
    //         body: ev.body,
    //         target: MESSAGE_TARGETS.OFFSCREEN
    //     });
    // });
    // emitter.on(API_CHAT_HISTORY, defaultApiHandler(getChatHistory));
    // emitter.on(INTERNAL_API_CHAT_HISTORY, (ev) => {
    //     if(ev.body?.conversationId){
    //         getChatHistory({conversationId:ev.body.conversationId});
    //     } 
    // });
    
    // emitter.on(API_DOWNLOAD_LOGS, (ev) => {
    //         sendMessageToExtenal({
    //             type: 'notif',
    //             chan: API_DOWNLOAD_LOGS,
    //             body: {logs: logCollector.logListArray},
    //         })
    // });

}
