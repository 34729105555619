import { CONTENT_SCRIPT_NAME, WT_CONTENT_SCRIPT_NAME, AIC_CCP_CLIENT_SCRIPT, OFFSCREEN_DOCUMENT_PATH, MESSAGE_TARGETS } from '../constants/common';
import { BG_INFO, BG_TELEMETRY, STOP_SESSION } from '../constants/events';
import storeRTK from '../store/storeRTK';
// import { emitter, SessionEvent } from '../emitter';
// import { store } from '../store';

const activeSessions: { [key: number]: chrome.runtime.Port } = {};
const externalActiveSessions : {[key: number]: chrome.runtime.Port} = {};
let actualNumTabs = 0;

const permissionName = "microphone" as PermissionName;
/**
 * Open permission tab for offscreen document
 */
export function openPermissionTab() {
    // Ensure the base URL has a trailing slash
    // const baseURL = process.env.AIC_CCP_CLIENT_URL || "";
    // const normalizedBaseURL = baseURL.endsWith('/') ? baseURL : baseURL + '/';

    // Path to be appended to the base URL
    // const path = 'media-perm';
    chrome.tabs.create({
        url: chrome.runtime.getURL('media-perm.html'),
        active: true,
    });

    // Construct the full URL
    // const fullURL = normalizedBaseURL + '?media-perm=true';
    // chrome.tabs.create({
    //     url: fullURL,
    //     active: true,
    // })
    // .then((res) => console.log("Opening permission tab", res))
    // .catch((error) => console.log("Error in opening permission tab", error));
}
/**
 * Open permission tab for CCP client window
 */
export function openPermissionTabForCCP() {
    //Ensure the base URL has a trailing slash
    const baseURL = process.env.AIC_CCP_CLIENT_URL || "";
    const normalizedBaseURL = baseURL.endsWith('/') ? baseURL : baseURL + '/';

    //Path to be appended to the base URL
    const path = 'media-perm';

    //Construct the full URL
    const fullURL = normalizedBaseURL + '?media-perm=true';
    chrome.tabs.create({
        url: fullURL,
        active: true,
    })
    .then((res) => console.log("Opening permission tab", res))
    .catch((error) => console.log("Error in opening permission tab", error));
}
/**
 * Updates the initial tab count for the browser, during initialization
 */
export function updateInitialCountHandler() {
    navigator.permissions.query({ name: permissionName }).then(function (result) {
        if (result.state !== 'granted') {
           openPermissionTab();
        }
    });

    chrome.tabs.query({}, function (tabs) {
        actualNumTabs = tabs.length;
    });
}

/**
 * 
 * @param callback return true or false 
 * @param flag only for internal use to check again after some time if get error 
 */
export function checkMediaPermission(callback: any, flag?: boolean) {
    navigator.permissions.query({ name: permissionName }).then(function (result) {
        if (result.state === 'granted') {
            callback(true);
        } else {
            callback(false);
        }
    }).catch(e => {
        if (!flag) {
            setTimeout(() => checkMediaPermission(callback, true), 500);
        }
        else {
            callback(false);
        }
    })
}
/**
 * When a tab is created we only increment the count. Session handling is handled elsewhere.
 */
export function createTabHandler() {
    if (actualNumTabs === 0) {
        chrome.tabs.query({}, function (tabs) {
            actualNumTabs = tabs.length;
        });
    }
    
    actualNumTabs += 1;
}

/**
 * Handles the removal of tab sessions.
 * @param {Number} tabId Tab Id who's session has been removed
 */
export function removeTabHandler(
    tabId: number,
    removeInfo?: chrome.tabs.TabRemoveInfo
) {
    console.log("TAB changes: Remove tab handler called", tabId, "it is in active session? " + activeSessions.hasOwnProperty(tabId));
    if (activeSessions.hasOwnProperty(tabId)) {
        activeSessions[tabId].disconnect();
        delete activeSessions[tabId];
    }

    actualNumTabs -= 1;
    // Earlier actualNumTabs === 1
    if (Object.keys(activeSessions).length === 0) {
        setTimeout(
            () => {
                // emitter.emit(BG_TELEMETRY, {
                //     type: 'notif',
                //     chan: BG_INFO,
                //     body: {
                //         type: STOP_SESSION,
                //         chan: STOP_SESSION,
                //         message: 'Browser / all tabs are closed',
                //         reason: "Active number of tabs becoming 0, logging out the user ******"
                //     },
                // });
                // emitter.emit(STOP_SESSION, {
                //     type: 'req',
                //     chan: STOP_SESSION,
                //     body: 'Browser / all tabs are closed',
                // })
            },
            1000
        );
    }
}

/**
 * Replacing Tab Handlers
 */
export function replaceTabHandler(addedTabId: number, removedTabId: number) {
    // Only handle removal here, addition is handled elsewhere.
    console.log("TAB changes: Replace tab handler called ", removedTabId, "it is in active session? " + activeSessions.hasOwnProperty(removedTabId), "replacing tab " + removedTabId + "with "+ addedTabId);
    removeTabHandler(removedTabId, undefined);
}

// Script Message Handlers

/**
 * For the addition of background port connections to propagate the messages to those ports.
 * Handling of the session creation is done here.
 * @param {Port} port Port object to add to our session.
 */

export function contentScriptHandler(port: chrome.runtime.Port) {
    //console.log("TAB changes: Background connect called by port ", port, "it is in active session? " + activeSessions.hasOwnProperty(port.sender?.tab?.id as number));
    if (port.name === CONTENT_SCRIPT_NAME && port.sender?.tab?.id) {
        activeSessions[port.sender.tab.id] = port;
        port.onMessage.addListener(function (message: SessionEvent<any>) {
            // emitter.emitAsync(message.chan as any, message, port);
        });

        port.onDisconnect.addListener(function (portN) {
            //console.log("TAB changes: Port disconnected ", portN, "in active session before deleting ", JSON.stringify(activeSessions[portN?.sender?.tab?.id as number]));
            if (portN.sender?.tab?.id && portN.sender.tab.id in activeSessions && activeSessions[portN.sender.tab?.id].sender?.documentId === portN.sender?.documentId) {
                delete activeSessions[portN.sender.tab.id];
            }
        });
    }
}

/**
 * For addition of port of external sessions other than content script
 * @param {Port} port Port object to add our extenal session list
 */
export function externalConnectionHandler(port: chrome.runtime.Port) {
    if (port.name === AIC_CCP_CLIENT_SCRIPT && port.sender?.tab?.id) {
        chrome.storage.sync.get(['webrtcWindowId'], (result) => {
            const wid = result?.webrtcWindowId;
            if (port.sender?.tab && port.sender.tab.windowId === wid) {
                if (port?.sender?.tab?.id) {
                    externalActiveSessions[port.sender.tab.id] = port;
                }
                port.onMessage.addListener(function (message: SessionEvent<any>) {
                    // emitter.emitAsync(message.chan as any, message, port);
                });
        
                port.onDisconnect.addListener(function (portN) {
                    if (portN.sender?.tab && portN.sender.tab.id && portN.sender.tab.id in externalActiveSessions) {
                        delete externalActiveSessions[portN.sender.tab.id];
                    }
                });
            }  
        });
    }
}



/**
 * Handler for generic message coming through the `chrome.runtime.sendMessage` interface.
 * @param {Object} message
 * @param {*} sender
 * @param {*} sendResponse
 */
export function injectionScriptHandlers(
    message: SessionEvent<any>,
    sender: chrome.runtime.MessageSender,
    sendResponse: (response?: any) => void
) {
    // emitter.emit(message.chan as any, message, sender, sendResponse);
}

/**
 * Send the message body populated with store state.
 */
export function sendMessageWithState(
    messageEnvelope: SessionEvent<any>,
    exemptedTabId: number = NaN
) {
    messageEnvelope.body = ((state) => {
        return {
            agent: state.agent,
            call: state.call,
            session: state.session,
            history: state.history,
            chat: state.chat,
            cases: state.cases,
            webrtc: state.webrtc,
            callback: state.callback
        };
    })(storeRTK.getState());

    sendMessage(messageEnvelope, exemptedTabId);
}

/**
 * Sends a message to all current tab sessions.
 * @param {Store} store Current redux state store
 * @param {Object} messageEnvelope Message to pass
 * @param {Number} exemptedTabId Optional tab id to not send to.
 */
export function sendMessage(
    messageEnvelope: SessionEvent<any>,
    exemptedTabId: number = NaN
) {
    try{
        for (const port of Object.values(activeSessions)) {
            if (!port.sender?.tab?.id) continue;

            const tabId = port.sender?.tab?.id;
            try {
                    if (
                        tabId != exemptedTabId &&
                        activeSessions.hasOwnProperty(tabId)
                    ) {
                        activeSessions[tabId].postMessage(messageEnvelope);
                    } else {
                        chrome.tabs.sendMessage(tabId, messageEnvelope);
                    }
            } catch (error) {
                /* Ignore errors */
            }
        }
    } catch(error) {
        console.log("Error!!!! sendMessage Error");
    }
}

/**
 * Sends a message to all tabs through the `chrome.runtime.sendMessage` interface,
 * and avoids the exemptedTabId when sending.
 * @param {Object} messageEnvelope
 * @param {Number} exemptedTabId
 */
export function sendMessageTabs(
    messageEnvelope: SessionEvent<any>,
    exemptedTabId = NaN
) {
    for (const port of Object.values(activeSessions)) {
        if (!port.sender?.tab?.id) continue;

        const tabId = port.sender?.tab?.id;
        const tabName = port.name;

        if (tabId == exemptedTabId) continue;
        if(tabName === WT_CONTENT_SCRIPT_NAME) continue;
        try {
            chrome.tabs.sendMessage(tabId, messageEnvelope);
        } catch (error) {
            /* Ignore errors */
        }
    }
}

/**
 * Sends a message to a specific port.
 * @param {Object} messageEnvelope
 * @param {Port} port
 */
export function sendMessagePort(
    messageEnvelope: SessionEvent<any>,
    port: chrome.runtime.Port
) {
    try {
        port.postMessage(messageEnvelope);
    } catch (e) {
        console.log(e);
        // no-op
    }
}

/**
 * Sends a message to all current tab sessions.
 * @param {Object} messageEnvelope Message to pass
 */
export function sendMessageToExtenal(
    messageEnvelope: SessionEvent<any>
) {
    for (const port of Object.values(externalActiveSessions)) {
        if (!port.sender?.tab?.id) continue;
        const tabId = port.sender?.tab?.id;
        try {
            //console.log('Trying to send message to tabId', tabId);
                if (
                    externalActiveSessions.hasOwnProperty(tabId)
                ) {
                    externalActiveSessions[tabId].postMessage(messageEnvelope);
                } else {
                    console.log("tab not present in externalActiveSessions not sending message")
                }
        } catch (error) {
            console.log("Error during sending message to external window", error);
            /* Ignore errors */
        }
    }
}


export async function sendMessageToOffscreen(messageEnvelope: SessionEvent<any>) {
    // await setupOffscreenDocument(OFFSCREEN_DOCUMENT_PATH);
    // if(!messageEnvelope.target || messageEnvelope.target !== MESSAGE_TARGETS.OFFSCREEN) {
    //     messageEnvelope.target = MESSAGE_TARGETS.OFFSCREEN;
    // }
    // chrome.runtime.sendMessage(messageEnvelope);
}

let creating: any; // A global promise to avoid concurrency issues
export async function setupOffscreenDocument(path: any) {
  // Check all windows controlled by the service worker to see if one 
  // of them is the offscreen document with the given path
//   const offscreenUrl = chrome.runtime.getURL(path);
//   const existingContexts = await (chrome.runtime as any).getContexts({
//     contextTypes: ['OFFSCREEN_DOCUMENT'],
//     documentUrls: [offscreenUrl]
//   });

//   if (existingContexts.length > 0) {
//     return;
//   }

//   // create offscreen document
//   if (creating) {
//     await creating;
//   } else {
//     creating = chrome.offscreen.createDocument({
//       url: path,
//       reasons: [chrome.offscreen.Reason.WEB_RTC],
//       justification: 'reason for needing the document',
//     });
//     await creating;
//     creating = null;
//   }
}

export async function closeOffscreenDocument() {
    try {
        const offscreenUrl = chrome.runtime.getURL(OFFSCREEN_DOCUMENT_PATH);
        const existingContexts = await (chrome.runtime as any).getContexts({
            contextTypes: ['OFFSCREEN_DOCUMENT'],
            documentUrls: [offscreenUrl]
        });

        if (existingContexts.length > 0) {
            await chrome.offscreen.closeDocument();
        }
    } catch(error) {
        //console.log(`Error in closing offscreen document`, error);
    }
    return null;
}