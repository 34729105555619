import { createAsyncThunk } from "@reduxjs/toolkit";
import { changeAgentState } from "../api/session-api";
import {
  closeOffscreenDocument,
  sendMessage,
  sendMessageWithState,
} from "../chrome/tabs";
import {
  AGENT_AFTER_CALL_WORK_STATE_ID,
  CCP_STATES,
} from "../constants/common";
import {
  BG_INFO,
  BG_TELEMETRY,
  INTERNAL_SESSION_START,
  SESSION_ERROR,
  START_SESSION,
  STOP_SESSION,
} from "../constants/events";
// import { storeLogin, resetState } from '../reducers/global-actions';
// import { initiateLogin, initiateLogout, login, resetSessionState } from '../reducers/session';
import { AppDispatch, RootState } from "../store";
import { setCCPsessionState } from "../reducers/webrtc";
import { ccpWindow } from "../session/window";
import {
  initiateLogin,
  initiateLogout,
  login,
  resetSessionState,
} from "../actions/session";
import storeRTK from "../store/storeRTK";
import { setCCPState } from "../actions/webrtc";
import { resetState, storeLogin } from "../actions/common";
import Emitter from "../emitter";
import { AgentState } from "../types/agent.type";
import { API_AGENT_CHANGE, STATE_AGENT } from "../constants/constants";

export const loginSession = createAsyncThunk<
  void,
  RootState,
  { dispatch: AppDispatch; state: RootState }
>("session/loginSession", async (initialState, thunkApi) => {
  try {
    const oldState = storeRTK.getState();
    thunkApi.dispatch(
      login({
        loggedIn: true,
        loggedInAt:
          oldState.session.loggedIn === true
            ? oldState.session?.loggedInAt
            : undefined,
      })
    );
    thunkApi.dispatch(storeLogin(initialState));
    const state = storeRTK.getState();
    if (state?.agent?.id) {
      //retain old state if it is trying to relogin
      //If relogin due to service worker inactivity, old state from chrome store should be reset
      if (oldState.session.loggedIn) {
        const agentStateId = oldState.agent.state?.id;
        if (agentStateId && agentStateId !== AGENT_AFTER_CALL_WORK_STATE_ID) {
          changeAgentState({ agentState: agentStateId });
        } else {
          //if fresh login make agent available
          const agentStateId1 = state.agent?.states?.allowedStates?.filter(
            (s: AgentState) => s?.displayName === "Available"
          )[0].id;
          if (agentStateId1) {
            Emitter.emit(API_AGENT_CHANGE, { agentState: agentStateId1 });
            // changeAgentState({ agentState: agentStateId1 });
          }
        }
      } else {
        const agentStateId = state.agent?.states?.allowedStates?.filter(
          (s: AgentState) => s?.displayName === "Available"
        )[0].id;
        if (agentStateId) {
          // changeAgentState({ agentState: agentStateId });
          Emitter.emit(API_AGENT_CHANGE, { agentState: agentStateId });
        }
      }
    }

    // sendMessageWithState({
    //     type: 'notif',
    //     chan: START_SESSION,
    //     body: {},
    //     success: true,
    // });
    Emitter.emit(INTERNAL_SESSION_START);
    // emitter.emit(BG_TELEMETRY, {
    //     type: 'notif',
    //     chan: BG_INFO,
    //     success: true,
    //     context: {
    //         agent: true,
    //     },
    //     body: {
    //         type: START_SESSION,
    //         chan: START_SESSION,
    //         message: 'Agent login successful.',
    //     }
    // });
  } catch (error) {
    console.log("Agent login error in session/loginSession", error);
    // emitter.emit(BG_TELEMETRY, {
    //     type: 'notif',
    //     chan: BG_INFO,
    //     success: false,
    //     body: { type: 'session/loginSession', chan:SESSION_ERROR, message:"Agent login error in session/loginSession", reason: error},
    // });
  }
});

export const logoutSession = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>("session/logoutSession", async (reason, thunkApi) => {
  const state = storeRTK.getState();
  if (state?.agent?.id) {
    const agentStateId = state.agent?.states?.allowedStates?.filter(
      (s: any) => s?.displayName === "Offline"
    )[0].id;
    if (agentStateId) {
      changeAgentState({ agentState: agentStateId });
    }
  }
  // emitter.emit(BG_TELEMETRY, {
  //     type: 'notif',
  //     chan: BG_INFO,
  //     success: true,
  //     body: {
  //         type: "session/logoutSession",
  //         chan: STOP_SESSION,
  //         message: 'Agent logout successful.',
  //         reason,
  //     },
  //     context: {
  //         agent: true,
  //         call: true,
  //     },
  // });
  sendMessage({ type: "notif", chan: STOP_SESSION, success: true });

  // Update rtc state to closed after logout
  storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
  // await chrome.storage.sync.set({ agentState: null });
  // Reset Store state
  thunkApi.dispatch(resetState());

  // Close offscreen document
  await closeOffscreenDocument();
});

export const logoutFailure = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>("session/logoutFailure", (reason, thunkApi) => {
  thunkApi.dispatch(initiateLogout({ isLogoutProcessing: false }));
  // sendMessage({
  //     type: 'notif',
  //     chan: STOP_SESSION,
  //     success: false,
  //     body: {},
  // });
  // emitter.emit(BG_TELEMETRY, {
  //     type: 'notif',
  //     chan: BG_INFO,
  //     success: false,
  //     body: {
  //         type: "session/logoutFailure",
  //         chan: SESSION_ERROR,
  //         message: 'Agent logout failed.',
  //         reason: reason,
  //         wasClean: true
  //         },
  //     context: {
  //         agent: true,
  //         call: true,
  //     },
  // });

  // Reset Store state
  thunkApi.dispatch(resetState());
});
