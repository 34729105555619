import React, { useState } from "react";
import { Button, Flex, Grid, TextField, Text } from "@adobe/react-spectrum";
import { BackspaceIcon, CallIcon } from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_DIAL,
  CALL_CANNOT_DIAL,
  FETCH_ABORT_TIMEOUT,
  SHOW_NOTIFICATION,
} from "../../../constants/constants";
import { createPromiseForFetch } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";

const CallDialPad = () => {
  const [number, setNumber] = useState("");
  const [dialInProgress, setDialInProgress] = useState(false);
  const [dialError, setDialError] = useState("");

  let agent = useSelector((state: RootState) => state.agent);

  const renderDialer = (num: number) => {
    let displayNum: any;
    switch (num) {
      case 10:
        displayNum = "*";
        break;
      case 11:
        displayNum = "0";
        break;
      case 12:
        displayNum = "#";
        break;
      case 13:
        displayNum = "+";
        break;
      case 14:
        displayNum = "x";
        break;
      case 15:
        displayNum = <BackspaceIcon />;
        break;
      default:
        displayNum = num;
    }

    const handleBtnpress = (num: number) => {
      if (num === 15) {
        setNumber((prevNumber) => prevNumber.slice(0, -1));
      } else if (num === 14) {
        setNumber("");
      } else {
        setNumber((prevNumber) => prevNumber + displayNum);
      }
    };

    return (
      <Button
        variant="primary"
        key={num}
        onPress={() => handleBtnpress(num)}
        UNSAFE_className="dialer-btn"
      >
        {displayNum}
      </Button>
    );
  };

  const validateNumber = (number: string) => {
    const regex = new RegExp(/^(?=.{1,30}$)([+]*[0-9a-zA-Z]+[#()+ -]*)/);
    return regex.test(number);
  };

  const makeCall = () => {
    if (!dialInProgress) {
      const temp = number
        .replace(/ /g, "")
        .replace(/-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "");

      if (validateNumber(number)) {
        setDialInProgress(true);
        if (navigator.onLine) {
          try {
            Promise.any([
              createPromiseForFetch(
                agent.vpnCheckUrl.split(",")[0],
                FETCH_ABORT_TIMEOUT
              ),
              createPromiseForFetch(
                agent.vpnCheckUrl.split(",")[1],
                FETCH_ABORT_TIMEOUT
              ),
            ])
              .then(() => {
                Emitter.emit(API_CALL_DIAL, {
                  phoneNumber: temp,
                });
              })
              .catch(() => {
                setDialInProgress(false);
                Emitter.emit(SHOW_NOTIFICATION, {
                  message: CALL_CANNOT_DIAL,
                  title: "Error",
                  type: "warning",
                });
              });
          } catch (error) {
            setDialInProgress(false);
            Emitter.emit(SHOW_NOTIFICATION, {
              message: CALL_CANNOT_DIAL,
              title: "Error",
              type: "warning",
            });
          }
        } else {
          setDialInProgress(false);
          Emitter.emit(SHOW_NOTIFICATION, {
            message: CALL_CANNOT_DIAL,
            title: "Error",
            type: "warning",
          });
        }
      } else {
        setDialError("Invalid number");
      }
    }
  };

  return (
    <Flex alignItems={"center"} direction={"column"} width={"60%"}>
      <Flex justifyContent={"center"} direction={"column"} width={"40%"}>
        <TextField
          value={number}
          UNSAFE_className="dialer-textfield"
          isQuiet
          onChange={(e) => setNumber(e)}
          inputMode="tel"
          width={"100%"}
        />
        <Flex justifyContent={"center"}>
          <Text UNSAFE_className="call-code-text">
            Enter phone number with country code (e.g +12345678900)
          </Text>
        </Flex>
        <Flex justifyContent={"center"}>
          <Grid
            areas={["1 2 3", "4 5 6", "7 8 9", "* 0 #", "+ x backspace"]}
            columns={["1fr", "1fr", "1fr"]}
            rows={["1fr", "1fr", "1fr", "1fr", "ifr"]}
            alignItems="center"
            justifyItems="center"
            height="100%"
          >
            {Array.from({ length: 15 }, (_, i) => i + 1).map((num) =>
              renderDialer(num)
            )}
          </Grid>
        </Flex>
        <Button
          variant="accent"
          onPress={makeCall}
          aria-label="call-dial-button"
          marginTop={"12px"}
          UNSAFE_style={{ borderRadius: "24px" }}
        >
          <CallIcon fill="#FFFFFF" />
          <Text
            UNSAFE_style={{
              display: "contents",
              font: "normal normal normal 1rem Adobe Clean",
            }}
          >
            &nbsp; Call
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default CallDialPad;
