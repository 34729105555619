import { setHistoryState } from '../actions/history';
import { BG_HISTORY_CHANGE } from '../constants/constants';
import Emitter from '../emitter';
import storeRTK from '../store/storeRTK';
/**
 * Set Data to history
 * @param {*} messageEnvelope
 */
function setHistory(messageEnvelope:any) {
    if (messageEnvelope.body) {
        storeRTK.dispatch(setHistoryState(messageEnvelope.body));
    }
}

/**
 * Register Default Events for Agent
 */
export default function registerHistoryEvents() {
    Emitter.on(BG_HISTORY_CHANGE, setHistory);
}
