import "./style.css";

const SkeletonLoader = ({
  width = "100%",
  height = "20px",
  borderRadius = "4px",
  marginTop = "10px",
}) => {
  return (
    <div
      className="skeleton"
      style={{ width, height, borderRadius, marginTop }}
    ></div>
  );
};

export default SkeletonLoader;
