import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    Content,
    Heading,
    Divider,
    DialogTrigger,
    SearchField,
    ListBox,
    Item,
    ActionButton,
} from '@adobe/react-spectrum';
import { makeSearcher } from '../../../utils';
import {
    API_CALL_WRAPUP_CODE,
    API_CALL_WRAPUP,
    CALL_STATES,
} from '../../../constants/constants';
import { sendMessage } from '../../../services';
// import Emitter from '../../../emitter';
import { connect } from 'react-redux';
import './style.css';

/**
 * Mapping the Store State to Component Props
 * @param {*} state
 */
function mapStateToProps(state) {
    return { call: state.call };
}

export const WrapUpPopover = (props) => {
    const { call } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [codeList, setCodeList] = useState([]);
    const lastCall = useRef(null);

    const onSelectHandler = (selected) => {
        setTimeout(() => setIsDialogOpen(false), 500);
        sendMessage({
            body: {
                wrapupCode: [...selected][0],
                participantId: lastCall.current?.self?.id,
                conversationId: lastCall.current?.id,
            },
            chan: API_CALL_WRAPUP,
            type: 'req',
        });
        lastCall.current = null;
    };

    const getCodeList = () => {
        if (searchText === '') {
            return codeList || [];
        }
        const searcher = makeSearcher(
            searchText.toLocaleLowerCase().split(' ')
        );
        return codeList.filter((code) =>
            searcher(code.displayName.toLocaleLowerCase())
        );
    };

    const showCodeWrapPopup = (data) => {
        if (!isDialogOpen && lastCall.current?.id) {
            setCodeList(data.body?.wraupCodes);
            setIsDialogOpen(true);
        }
    };
    const hidePopUp = () => {
        setIsDialogOpen(false);
        lastCall.current = null;
    };
    useEffect(() => {
        if (
            lastCall.current === null &&
            call.id &&
            call.self?.state !== CALL_STATES.RELEASED
        ) {
            lastCall.current = call;
        }
    }, [call]);

    useEffect(() => {

        return () => {
            setIsDialogOpen(false);
            // Emitter.off(API_CALL_WRAPUP_CODE, showCodeWrapPopup);
            // Emitter.off(API_CALL_WRAPUP, hidePopUp);
        };
    }, []);

    return (
        <>
            {isDialogOpen && codeList?.length > 0 ? (
                <DialogTrigger
                    type="popover"
                    mobileType="tray"
                    defaultOpen={true}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    {() => (
                        <Dialog size={'S'} UNSAFE_className="custom-dialog">
                            <Heading level={4}>
                                <span className="NumPadDialogHeading">
                                    <span className="dialogHeading">
                                        Wrap-up Code
                                    </span>
                                </span>
                            </Heading>
                            <Divider marginBottom={'size-0'} />
                            <Content maxWidth={'100vw'} marginTop={'15px'}>
                                <SearchField
                                    area-label="searchCodeField"
                                    onClear={() => setSearchText('')}
                                    onChange={setSearchText}
                                    value={searchText}
                                    width={'98%'}
                                    marginBottom="15px"
                                    labe={null}
                                />
                                <ListBox
                                    aria-label="Choose frequency"
                                    selectionMode="single"
                                    onSelectionChange={(selected) => {
                                        onSelectHandler(selected);
                                    }}
                                    width={'98%'}
                                    height={'500px'}
                                >
                                    {getCodeList().map((code) => (
                                        <Item
                                            key={code.id}
                                            textValue="codeName"
                                        >
                                            {code.name}
                                        </Item>
                                    ))}
                                </ListBox>
                            </Content>
                        </Dialog>
                    )}
                </DialogTrigger>
            ) : (
                <> </>
            )}
        </>
    );
};
export default connect(mapStateToProps)(WrapUpPopover);
