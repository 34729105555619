import {
  Item,
  TabList,
  TabPanels,
  Tabs,
  View,
  Text,
  StatusLight,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import storeRTK from "../../../store/storeRTK";
import { setSelectedTab } from "../../../actions/common";
import "./index.css";
import { RootState } from "../../../store";

const TabSwitch = (props: any) => {
  const { tabs, history, isActiveCall } = props;
  let selectedTab = useSelector((state: RootState) => state.common.tab);

  const handleTabChange = (value: any) => {
    storeRTK.dispatch(setSelectedTab(value));
  };

  return (
    <View flexGrow={1} backgroundColor="static-white" height={'100%'}>
      <Tabs
        selectedKey={selectedTab}
        onSelectionChange={handleTabChange}
        height={'100%'}
      >
        <TabList UNSAFE_className="tab-item">
          {tabs.map((tab: any) => (
            <Item key={tab.name} textValue={tab.name}>
              <Text UNSAFE_className="tab-item-label">{tab.name}</Text>
              <Text
                UNSAFE_className="tab-item-active tab-item-list"
                position={"relative"}
              >
                {tab.name === "Call" && isActiveCall !=="" && (
                  <StatusLight
                    variant="negative"
                    UNSAFE_className="call-status-light"
                  />
                )}
              </Text>
              <Text marginStart={15}>
                {tab.name === "History" && (
                  <span className="tab-switch-count">{history.length}</span>
                )}
              </Text>
            </Item>
          ))}
        </TabList>
        <TabPanels UNSAFE_style={{ border: "0" }} height={"85%"}>
          {tabs.map((tab: any) => (
            <Item key={tab.name}>{tab.children}</Item>
          ))}
        </TabPanels>
      </Tabs>
    </View>
  );
};

export default TabSwitch;
