import { useState, useEffect, useRef, useId } from "react";
import {
  Button,
  Divider,
  Heading,
  TextArea,
  View,
  Text as SpectrumText,
  Image as SpectrumImage,
  Form,
  Flex,
  ActionGroup,
  Item,
  Key,
  DialogContainer,
  AlertDialog,
  ActionButton,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { askAi, postCaseRerouteData } from "../../../api/session-api";
import storeRTK from "../../../store/storeRTK";
import {
  setCaseSummaryIssue,
  setSuggestCaseIssue,
} from "../../../actions/case";
import QuestionMark from "../../../images/questionMark.svg";
import SkeletonLoader from "../../Loader/SkeletonLoader";
import { IndividualTask } from "../../../amazon-connect/models/task";
import {
  setHistoryCaseSuggestIssue,
  setHistoryCaseSummaryIssue,
} from "../../../actions/history";
import { RootState } from "../../../store/storeConfig";
import DislikeReasonDialog from "./DislikeReasonDialog";
import AiFeedBack from "./AiFeedBack";
import { RefreshIcon } from "../../common/Icons";

interface ICaseAiProps {
  title?: string;
  value: string;
  setValue: (value: string) => void;
  handleChange: any;
  type?: string;
  loader?: boolean;
  currentActiveCaseId: string;
  handleSuggestIssueReason?: (value: string) => void;
}

const caseAiEditor = (props: ICaseAiProps) => {
  const {
    title,
    value,
    setValue,
    handleChange,
    type,
    loader,
    currentActiveCaseId,
    handleSuggestIssueReason,
  } = props || {};

  const chatContainerRef = useRef<any>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [rerouteIssue, setRerouteIssue] = useState("");

  const { cases, history, common } = useSelector((state: RootState) => state);
  // If tab 1 selected then ( live case) data shown else if tab 2 (history) is selected case history data shown
  const currentCase = common.tab === 1 ? cases.cases : history.cases;

  const activeCase =
    currentCase?.length > 0 &&
    currentCase?.find(
      (indCase: IndividualTask) => indCase.id === currentActiveCaseId
    );

  // Scroll to the bottom of the ai chat container when new messages are added
  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef?.current?.scrollTo(
        0,
        chatContainerRef.current.scrollHeight
      );
    }
  }, [
    activeCase?.suggestCaseMessages?.length,
    activeCase?.summaryCaseMessages?.length,
  ]);

  // handle submit function to send the message to ask AI
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (type === "suggest") {
      if (value) {
        let uniqueUserResponseId = Math.random().toString(36).substring(7);
        storeRTK.dispatch(
          setSuggestCaseIssue(
            currentActiveCaseId,
            value,
            "",
            false,
            false,
            false,
            false,
            "",
            uniqueUserResponseId
          )
        );
        storeRTK.dispatch(
          setHistoryCaseSuggestIssue(currentActiveCaseId, value, "")
        );
        setValue("");
        askAi(value)
          .then((res) => {
            const { response } = res;
            let uniqueAiResponseId = Math.random().toString(36).substring(7);
            storeRTK.dispatch(
              setSuggestCaseIssue(
                currentActiveCaseId,
                "",
                response,
                false,
                false,
                false,
                false,
                "",
                uniqueAiResponseId
              )
            );
            storeRTK.dispatch(
              setHistoryCaseSuggestIssue(currentActiveCaseId, "", response)
            );
          })
          .catch((error) => {
            console.log(
              "Error while fetching suggest issue ask ai response",
              error
            );
          });
      }
    }
    if (type === "summary") {
      if (value) {
        storeRTK.dispatch(setCaseSummaryIssue(currentActiveCaseId, value, ""));
        storeRTK.dispatch(
          setHistoryCaseSummaryIssue(currentActiveCaseId, value, "")
        );
        setValue("");
        askAi(value)
          .then((res) => {
            const { response } = res;
            storeRTK.dispatch(
              setCaseSummaryIssue(currentActiveCaseId, "", response)
            );
            storeRTK.dispatch(
              setHistoryCaseSummaryIssue(currentActiveCaseId, "", response)
            );
          })
          .catch((error) => {
            console.log("Error while fetching summary ask ai response", error);
          });
      }
    }
  };

  const setAction = (key: Key, issueReason?: string) => {
    if (key === "action-solution") {
      setValue("Suggest Solution");
    } else {
      setIsConfirmDialogOpen(true);
      setRerouteIssue(issueReason as string);
    }
  };

  const handleConfirmReroute = () => {
    const getIssue = activeCase?.caseRerouteData?.ISSUEREASONTEXT.filter(
      (message: any) =>
        activeCase?.suggestCaseMessages.some(
          (list: any) => message.text === list.aiResponse
        )
    );
    const payload = {
      contactId: currentActiveCaseId,
      attributes: {
        ISSUEREASONCODE: getIssue[0].code,
        ISSUEREASONTEXT: getIssue[0].text,
      },
    };
    postCaseRerouteData(payload).then((res) => {
      console.log("Case rerouted successfully ****", res);
    });
  };

  const handleRegenrateResponse = () => {
    if (handleSuggestIssueReason) {
      handleSuggestIssueReason("regenerate");
    }
  };

  return (
    <Flex direction={"column"} height={"100%"}>
      <Flex height={"100%"} direction={"column"}>
        {((type === "suggest" && activeCase?.suggestCaseMessages > 0) ||
          (type === "summary" && activeCase?.summaryCaseMessages > 0)) && (
          <Heading level={4} marginX={14} UNSAFE_className="case-ai-summary">
            {title}
          </Heading>
        )}
        <div className="chat-ai-message chat-container" ref={chatContainerRef}>
          {loader && (
            <View padding={10}>
              <SkeletonLoader width="100%" height="60px" marginTop="10px" />
            </View>
          )}
          {activeCase && activeCase[`${type}CaseMessages`]?.length > 0
            ? activeCase[`${type}CaseMessages`].map(
                (message: any, index: number) => (
                  <>
                    <View
                      key={index}
                      UNSAFE_className={`chat-bubble ${
                        message.aiResponse ? "ai" : "user"
                      }`}
                      maxWidth={300}
                    >
                      {type === "suggest" ? (
                        <>
                          {message.showSuggestDialog ? (
                            <>
                              <View borderRadius={"xsmall"}>
                                <View padding={"12px"}>
                                  <View>
                                    <label>Old Issue Reason</label>:{" "}
                                    <SpectrumText
                                      UNSAFE_className="case-ai-chat"
                                      UNSAFE_style={{ fontWeight: "bold" }}
                                    >
                                      {
                                        activeCase.customer.attributes
                                          .ISSUEREASONTEXT
                                      }
                                    </SpectrumText>
                                  </View>
                                  <View marginTop={"5px"}>
                                    <label>New Issue Suggested</label>:{" "}
                                    <SpectrumText
                                      UNSAFE_className="case-ai-chat"
                                      UNSAFE_style={{ fontWeight: "bold" }}
                                    >
                                      {message.aiResponse}
                                    </SpectrumText>
                                  </View>
                                  <Divider
                                    size="S"
                                    marginTop={15}
                                    marginBottom={15}
                                  />
                                  <label>
                                    Would you like to reroute the case based on
                                    new issue reason suggested?
                                  </label>
                                  <ActionGroup
                                    marginTop={10}
                                    onAction={(key) =>
                                      setAction(key, message.aiResponse)
                                    }
                                  >
                                    <Item key="action-reroute">
                                      Reroute the case
                                    </Item>
                                  </ActionGroup>
                                </View>
                                <View paddingX={14}>
                                  <AiFeedBack
                                    activeCase={activeCase}
                                    message={message}
                                  />
                                </View>
                              </View>
                            </>
                          ) : (
                            <View padding={10}>
                              <View>
                                <View>
                                  {message.aiResponse && (
                                    <>
                                      <View maxWidth={300} borderRadius="small">
                                        <SpectrumText UNSAFE_className="case-ai-chat">
                                          {message.aiResponse}
                                        </SpectrumText>
                                      </View>
                                      <View marginTop={5}>
                                        <AiFeedBack
                                          activeCase={activeCase}
                                          message={message}
                                        />
                                      </View>
                                    </>
                                  )}
                                </View>
                              </View>
                              <View>
                                {message.userResponse && (
                                  <View maxWidth={300} borderRadius="small">
                                    <SpectrumText UNSAFE_className="case-ai-chat">
                                      {message.userResponse}
                                    </SpectrumText>
                                  </View>
                                )}
                              </View>
                            </View>
                          )}
                        </>
                      ) : (
                        type === "summary" && (
                          <View padding={10}>
                            <View>
                              {message.aiResponse && (
                                <View maxWidth={300} borderRadius="small">
                                  <SpectrumText UNSAFE_className="case-ai-chat">
                                    {message.aiResponse}
                                  </SpectrumText>
                                </View>
                              )}
                            </View>
                            <View>
                              {message.userResponse && (
                                <View maxWidth={300} borderRadius="small">
                                  <SpectrumText UNSAFE_className="case-ai-chat">
                                    {message.userResponse}
                                  </SpectrumText>
                                </View>
                              )}
                            </View>
                          </View>
                        )
                      )}
                    </View>
                    <View marginTop={20} marginBottom={20}>
                      {message.showSuggestDialog && (
                        <ActionButton
                          UNSAFE_style={{ cursor: "pointer", padding: "10px" }}
                          onPress={handleRegenrateResponse}
                        >
                          <RefreshIcon color={"#0000"} />
                          <SpectrumText marginX={3}>
                            Regenerate response
                          </SpectrumText>
                        </ActionButton>
                      )}
                    </View>
                    <View>
                      {message.isDislikeFeedbackOpen && (
                        <View>
                          <DislikeReasonDialog
                            activeCase={activeCase}
                            message={message}
                          />
                        </View>
                      )}
                    </View>
                  </>
                )
              )
            : !loader && (
                <Flex
                  direction="column"
                  justifyContent="center"
                  marginX={"1.8rem"}
                  alignSelf={"center"}
                  UNSAFE_className="case-ai-chat-info"
                >
                  <Heading UNSAFE_className="case-ai-heading">
                    <SpectrumImage
                      width={"2rem"}
                      height={"2rem"}
                      marginBottom={16}
                      src={QuestionMark}
                      alt="question icon"
                    />
                    <SpectrumText>Ask AI to assist you</SpectrumText>
                  </Heading>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    height="size-4000"
                    wrap="wrap"
                  >
                    <SpectrumText UNSAFE_className="case-ai-info">
                      Choose predefined options to help or you can ask AI for
                      queries like:
                    </SpectrumText>
                    <SpectrumText
                      marginTop={10}
                      UNSAFE_className="case-ai-info"
                    >
                      What are the pricing pans of adobe illustrator ?
                    </SpectrumText>
                    <SpectrumText UNSAFE_className="case-ai-info">
                      Is there any discount going on for this user?
                    </SpectrumText>
                    <SpectrumText UNSAFE_className="case-ai-info">
                      How many Gen AI credits are available for basic plan?
                    </SpectrumText>
                  </Flex>
                </Flex>
              )}
        </div>
      </Flex>
      <Flex direction={"column"}>
        {/* <Divider size="S" marginTop={5} /> */}
        <Form
          UNSAFE_className="ask-ai-chat-wrapper"
          validationBehavior="native"
          onSubmit={(e: any) => {
            handleSubmit(e);
          }}
          UNSAFE_style={{ padding: "15px" }}
        >
          <TextArea
            aria-label="Ask AI to assist you with the case"
            aria-labelledby="ask-ai"
            placeholder="Ask AI to assist you with the case ..."
            value={value}
            onChange={handleChange}
            height="56px"
            isDisabled
          />

          <button type="submit" className="ai-chat-btn" disabled>
            Send
          </button>
        </Form>
      </Flex>
      <DialogContainer onDismiss={() => setIsConfirmDialogOpen(false)}>
        {isConfirmDialogOpen && (
          <AlertDialog
            title="Confirm Reroute"
            variant="confirmation"
            primaryActionLabel="Confirm"
            secondaryActionLabel="Cancel"
            onPrimaryAction={handleConfirmReroute}
          >
            Are you sure you want to reroute the case with new issue reason{" "}
            {rerouteIssue}?
          </AlertDialog>
        )}
      </DialogContainer>
    </Flex>
  );
};

export default caseAiEditor;
