import { getAicServerUrl } from "../config/index.ts";
import store from "../store/index.ts";

/**
 * Checks if Place is updated and emits a Login event.
 * @param {Store} state Store state.
 * @param {Object} userInfo the decrypted cookie object.
 */
function attemptLogin(state: any, sessionInfo: any) {
  // emitter.emit(START_SESSION);
}

/**
 * TODO: Typed Cookie Value
 * @param {String} cookieValue
 */
function parseCookieValue(cookieValue: string): any {
  try {
    return JSON.parse(atob(cookieValue));
  } catch (error) {
    return null;
  }
}

/**
 * Okta Cookie Change handler and initiates login.
 */
export function oktaCookieHandler(changeInfo: any) {
  if (
    (process.env.LOGIN_REDIR_DOMAINS || "").indexOf(changeInfo.cookie.domain) >
      -1 &&
    changeInfo.cookie.name === process.env.LOGIN_COOKIE_NAME
  ) {
    switch (changeInfo.cause) {
      case "overwrite":
        //store.dispatch(updateCookie(true));
        break;
      case "explicit":
        {
          const state = store.getState();
          if (state.session.cookieWasUpdated) {
            //store.dispatch(updateCookie(false));
            // If logging in again from Okta, before the expiry period finishes
            // Check if userDetails are the same. If same ignore, if different relogin.

            if (!changeInfo.removed) {
              const sessionInfo = parseCookieValue(changeInfo.cookie.value);

              if (!state.session.loggedIn) {
                // User is not logged in
                //TODO: enable if condition commented because information not available
                if (sessionInfo && sessionInfo.status === "success") {
                  setTimeout(() => attemptLogin(state, sessionInfo), 2000);
                } else {
                  // store.dispatch(
                  //   loginFailure(
                  //     "Login failed, please check user configuration."
                  //   )
                  // );
                }
              }
            }
          } else if (!changeInfo.removed) {
            // Cookie was set for the first time.
            const sessionInfo = parseCookieValue(changeInfo.cookie.value);
            if (sessionInfo && sessionInfo.status === "success") {
              attemptLogin(state, sessionInfo);
            } else {
              // store.dispatch(
              //     loginFailure('Login failed, please check user configuration.')
              // );
            }
          } else {
            // store.dispatch(
            //     loginFailure('Login failed, please check user configuration.')
            // );
          }
        }
        break;
    }
  }
}

// CCP Cookie Handler

// export function connectCookieHandler(
//     changeInfo: any
// ) {
//     const state = store.getState();
//     let ccpDomain = '';
//     if (state && state.agent && state.agent.aicConfig && state.agent.aicConfig.ccp_url) {
//         try {
//             const ccpURL = new URL(state.agent.aicConfig.ccp_url);
//             ccpDomain = ccpURL.hostname;
//         } catch (error) {
//             console.error('Error parsing CCP URL:', error);
//         }
//     }
//     if (ccpDomain && changeInfo.cookie && changeInfo.cookie.domain &&
//         changeInfo.cookie.domain.includes(ccpDomain) &&
//         COOKIE_AWS.includes(changeInfo.cookie.name)) {
//         switch (changeInfo.cause) {
//             case 'expired_overwrite':
//                 // emitter.emit(STOP_SESSION, {
//                 //     type: 'req',
//                 //     chan: STOP_SESSION,
//                 //     body: 'Cookie get removed by WFM or CCP service. Please login again.',
//                 // });
//                 break;
//             default:
//                 // Handle other causes if needed
//                 break;
//         }
//     }
// }

export function loginHandler() {
  let SERVER_URL = getAicServerUrl();
  try {
    location.href = `${SERVER_URL}/v1/session/aws/login/web`;
  } catch (error) {
    console.error("Error logging in:", error);
  }
}


